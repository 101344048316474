// Import necessary libraries and components
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';
import { HeatmapChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, VisualMapComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';

// Register the required ECharts components
echarts.use([TitleComponent, TooltipComponent, VisualMapComponent, HeatmapChart, CanvasRenderer]);

const RiskHeatmap = ({ data }) => {
  const [option, setOption] = useState({});
  const theme = useTheme();

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) {
      // Handle the case where data is not available
      return;
    }

    // Transform the data to a suitable format for the heatmap
    const transformedData = data?.combinations?.map(item => {
      const likelihoodIndex = data?.x_axes.indexOf(item.likelihood);
      const impactIndex = data?.y_axes.indexOf(item.impact);
      return [likelihoodIndex, impactIndex, item.count, item.risk, item.risk_index]; // Including risk_index
    });

    setOption({
      title: {
        text: 'Risk Heatmap Visualization',
        left: 'center',
        top: 'top',
      },
      tooltip: {
        position: 'top',
        formatter: function (params) {
          const likelihood = data?.x_axes[params.value[0]];
          const impact = data?.y_axes[params.value[1]];
          const count = params.value[2];
          const risk = params.value[3];
          return `Likelihood: ${likelihood}<br/>Impact: ${impact}<br/>Count: ${count}<br/>Risk: ${risk}`;
        },
      },
      grid: {
        left: '3%',
        right: '10%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: data?.x_axes,
        name: 'Likelihood',
        splitArea: { show: true },
      },
      yAxis: {
        type: 'category',
        data: data?.y_axes,
        name: 'Impact',
        splitArea: { show: true },
      },
      visualMap: {
        min: 0,
        max: 4, // Set the max to 4 as risk_index ranges from 0 to 4
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '15%',
        textStyle: { color: theme.palette.text.primary },
        inRange: {
          // Map colors based on risk_index: 0 - veryLow, 4 - veryHigh
          color: [
            theme.palette.riskLevels.veryLow, // Index 0
            theme.palette.riskLevels.low,     // Index 1
            theme.palette.riskLevels.medium,  // Index 2
            theme.palette.riskLevels.high,    // Index 3
            theme.palette.riskLevels.veryHigh // Index 4
          ],
        },
      },
      series: [{
        name: 'Risk Level',
        type: 'heatmap',
        data: transformedData,
        label: {
          show: true,
          formatter: function (params) {
            return params.value[2]; // Displaying the count in the cell
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      }],
    });
  }, [data, theme]); // Re-run effect when data or theme changes

  // Check if data is empty and render placeholder or chart
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <ReactECharts option={option} />
    </div>
  );
};

export default RiskHeatmap;
