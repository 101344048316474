import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Drawer,
  Button,
  Box,
  Paper,
} from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import DetailedAsset from 'src/components/Asset/DetailedAssset';

// Define a mapping between model names and their corresponding components
const componentMapping = {
  informationsystem: DetailedAsset,
  supportingasset: DetailedAsset,
  primaryasset: DetailedAsset,
  organization: DetailedAsset,
  // Add more mappings as needed
};

const pluralize = (word) => {
  if (word?.toLowerCase().endsWith('s')) {
    return word; // already plural
  }
  if (word?.toLowerCase().endsWith('y')) {
    // Remove 'y' and add 'ies'
    return `${word?.slice(0, -1)}ies`;
  }
  return `${word}s`; // simple pluralization
};

const raciMapping = {
  'R': 'Responsible',
  'A': 'Accountable',
  'C': 'Consulted',
  'I': 'Informed',
};

const getRaciDescription = (raciData) => {
  if (!raciData) return '';
  const parsedRaciData = JSON.parse(raciData.replace(/'/g, '"'));
  const descriptions = parsedRaciData.map(raci => raciMapping[raci] || raci).join(' and ');
  return `I am ${descriptions.toLowerCase()}, for the activity `;
};

const ActivityDetails = ({ raciRow, roleinstance }) => (
  <Box p={2}>
    <Typography variant="h6" gutterBottom>{raciRow?.activity?.title}</Typography>
    <Typography variant="body1" paragraph>{raciRow?.activity?.description}</Typography>
    <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
      <Typography variant="body1">{getRaciDescription(raciRow?.raci_data) }{raciRow?.activity_title} </Typography>
    </Paper>
  </Box>
);

export default function Roles() {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activityDrawerOpen, setActivityDrawerOpen] = useState(false);
  const [selectedRoleInstance, setSelectedRoleInstance] = useState(null);
  const [raciRows, setRaciRows] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const { getComplianceList: getItems, complianceList: items = [] } = useRequestCompliance({ endpoint: 'users/myroles' });
  const { getComplianceList: getRaciRows, complianceList: raciItems = [] } = useRequestCompliance({ endpoint: 'raci-rows/by-role' });

  useEffect(() => {
    getItems();
  }, [getItems]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const highlightRoleId = queryParams.get('highlightRoleId');
    if (highlightRoleId) {
      setColumnFilters([{ id: 'id', value: parseInt(highlightRoleId, 10) }]);
    }
  }, [location.search]);

  useEffect(() => {
    if (selectedRoleInstance?.role_instance) {
      getRaciRows({ role_id: selectedRoleInstance?.role_instance?.role_definition });
    }
  }, [selectedRoleInstance, getRaciRows]);

  useEffect(() => {
    setRaciRows(raciItems.results);
  }, [raciItems]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const filteredData = columnFilters.length
          ? items.results.filter((item) => {
              return columnFilters.every((filter) => {
                const value = item[filter.id];
                if (typeof filter.value === 'string') {
                  return value.toString().toLowerCase().includes(filter.value.toLowerCase());
                }
                return value === filter.value;
              });
            })
          : items.results;
        setData(filteredData);
        setRowCount(filteredData.length);
        setIsError(false);
      } catch (error) {
        setIsError(true);
        console.error(error);
      } finally {
        setIsLoading(false);
        setIsRefetching(false);
      }
    };
    fetchData();
  }, [items, columnFilters]);

  const updateURLParams = (filters) => {
    const queryParams = new URLSearchParams(location.search);
    if (filters.length && filters[0]?.value !== undefined) {
      queryParams.set('highlightRoleId', filters[0].value);
    } else {
      queryParams.delete('highlightRoleId');
    }
    navigate({ search: queryParams.toString() });
  };

  const handleFiltersChange = (filters) => {
    setColumnFilters(filters);
    updateURLParams(filters);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
      },
      {
        accessorKey: 'title',
        header: 'Title',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'role_instance.object_title',
        header: 'Role Scope',
        Cell: ({ cell }) => (
          <Button variant="outlined" onClick={() => handleRoleInstanceClick(cell.row.original.role_instance)}>{cell.getValue()}</Button>
        ),
      },
      {
        accessorKey: 'activity',
        header: 'Activity',
        Cell: ({ cell }) => (
          <Button variant="outlined" onClick={() => handleActivityClick(cell.row.original)}>My activities</Button>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: handleFiltersChange,
    state: {
      columnFilters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },
  });

  const handleRoleInstanceClick = (roleInstance) => {
    setSelectedRoleInstance(roleInstance);
    setDrawerOpen(true);
  };

  const handleActivityClick = (roleInstance) => {
    setSelectedRoleInstance(roleInstance);
    setActivityDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedRoleInstance(null);
  };

  const handleActivityDrawerClose = () => {
    setActivityDrawerOpen(false);
  };

  const RenderComponent = componentMapping[selectedRoleInstance?.model_name] || null;

  return (
    <>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5" gutterBottom>
            My assigned roles
          </Typography>
        </Grid>
      </Grid>

      <MaterialReactTable
        columns={columns}
        data={data}
        onColumnFiltersChange={handleFiltersChange}
        state={{ columnFilters }}
      />

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            marginTop: "64px",
            width: '80%'
          }
        }}>
                  <Box p={2} sx={{ display: 'flex', width: "100%" }} >
        {selectedRoleInstance && RenderComponent && (
          <RenderComponent id={selectedRoleInstance.object_id} type={pluralize(selectedRoleInstance.model_name)} />
        )}
                </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={activityDrawerOpen}
        onClose={handleActivityDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            marginTop: "64px",
            width: '40%'
          }
        }}>
        <Box p={2}>
          <Typography variant="h6" gutterBottom>{selectedRoleInstance?.title}</Typography>
          <Typography variant="body1" gutterBottom>{selectedRoleInstance?.description}</Typography>
        </Box>
        {raciRows.map((raciRow) => (
          <ActivityDetails key={raciRow?.id} raciRow={raciRow} roleinstance={selectedRoleInstance} />
        ))}
      </Drawer>
    </>
  );
}
