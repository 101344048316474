import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from '@mui/material';
import Select from 'react-select';
import MRTNested from './MRTNested';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams } from 'react-router-dom';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DetailHalfPage from '../DetailHalfPage';

export default function DataList({ setStandard, refreshKey }) {
  const { getComplianceList: fetchStandards, complianceList: standardsList } = useRequestCompliance({ endpoint: 'standards' });
  const { getCompliance: getStandard, compliance: nestedStandard, loading: nestedLoading } = useRequestCompliance({
    endpoint: 'nestedstandards',
  });

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedControl, setSelectedControl] = useState(null);
  const [moreOpen, setMoreOpen] = useState(false);
  const [detailEndpoint, setDetailEndpoint] = useState('');
  const [menuState, setMenuState] = useState({ anchorEl: null, activeRowId: null });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: null,
    isSubControl: false,
    isStandard: false,
  });
  const navigate = useNavigate();
  const { id } = useParams();

  const { deleteResource: deleteStandard } = useRequestCompliance({ endpoint: 'standards', resourceLabel: 'standard' });
  const { deleteResource: deleteControl } = useRequestCompliance({ endpoint: 'controls', resourceLabel: 'control' });
  const { deleteResource: deleteSubControl } = useRequestCompliance({ endpoint: 'sub-controls', resourceLabel: 'sub-control' });

  // Automatically fetch standards when the component mounts
  useEffect(() => {
    fetchStandards();
  }, [fetchStandards, refreshKey]);

  useEffect(() => {
    if (standardsList.results && standardsList.results.length > 0 && !selectedItem) {
      const firstItem = standardsList.results[0];
      setSelectedItem(firstItem);
      setStandard(firstItem);
      getStandard(firstItem.id);
    }
  }, [standardsList, getStandard, setStandard, selectedItem]);

  const resultsArray = useMemo(() => Array.isArray(standardsList.results) ? standardsList.results : [], [standardsList.results]);

  useEffect(() => {
    if (id && resultsArray.length > 0) {
      const item = resultsArray.find(item => item.id === parseInt(id));
      if (item) {
        setSelectedItem(item);
        getStandard(item.id);
      }
    }
  }, [id, resultsArray, getStandard]);

  const handleMenuClose = () => setMenuState({ anchorEl: null, activeRowId: null });

  const handleEdit = (id, isSubControl) => {
    if (isSubControl) {
      navigate(`/compliance/standards/sub-controls/edit/${id}`);
    } else {
      navigate(`/compliance/standards/controls/edit/${id}`);
    }
    handleMenuClose();
  };

  const handleDelete = () => {
    if (deleteDialog.isStandard) {
      deleteStandard(deleteDialog.id);
    } else if (deleteDialog.isSubControl) {
      deleteSubControl(deleteDialog.id);
    } else {
      deleteControl(deleteDialog.id);
    }
    setDeleteDialog({ open: false, id: null, isSubControl: false, isStandard: false }); // Reset dialog state after deletion
    handleMenuClose();
  };

  const renderActionCell = ({ row }) => {
    const isSubControl = row.parentId !== undefined;

    return (
      <>
        <Dialog
          open={deleteDialog.open}
          onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this item? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog({ ...deleteDialog, open: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <IconButton onClick={(event) => setMenuState({ anchorEl: event.currentTarget, activeRowId: row.id })}>
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={menuState.anchorEl}
          open={Boolean(menuState.anchorEl) && menuState.activeRowId === row.id}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleEdit(row.original.id, isSubControl)}>Edit</MenuItem>
          <MenuItem onClick={() => setDeleteDialog({ open: true, id: row.original.id, isSubControl, isStandard: false })}>
            Delete
          </MenuItem>
          <MenuItem onClick={() => {
            setMoreOpen(true);
            setSelectedControl(row.original.id);
            setDetailEndpoint(isSubControl ? "compliance/sub-controls" : "compliance/controls");
            handleMenuClose();
          }}>
            Detail
          </MenuItem>
        </Menu>
      </>
    );
  };

  const renderTopToolbarCustomActions = () => (
    <>
      <Button color="primary" onClick={() => navigate(`/compliance/standards/standards/edit/${selectedItem?.id}`)} startIcon={<EditIcon />}>
        Edit Standard
      </Button>
      <Button
        color="error"
        onClick={() => setDeleteDialog({ open: true, id: selectedItem?.id, isStandard: true, isSubControl: false })}
        startIcon={<DeleteIcon />}
      >
        Delete Standard
      </Button>
    </>
  );

  if (nestedLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
      </Box>
    );
  }

  const selectOptions = resultsArray.map(item => ({
    value: item.id,
    label: item.title,
    image: item.image_url,
  }));

  const handleSelectChange = (selectedOption) => {
    const item = resultsArray.find(result => result.id === selectedOption.value);
    if (item) {
      setSelectedItem(item);
      getStandard(item.id);
      navigate(`/compliance/standards/${item.id}`);
    }
  };

  const formatOptionLabel = ({ label, image }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {image ? (
        <img src={image} alt={label} style={{ width: 24, height: 24, marginRight: 8 }} />
      ) : (
        <Avatar sx={{ width: 24, height: 24, marginRight: 8 }}>{label.charAt(0)}</Avatar>
      )}
      {label}
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
  };

  const defaultValue = selectOptions.find(option => option.value === selectedItem?.id) || selectOptions[0];

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {resultsArray.length > 0 ? (
        <>
          <Select
            options={selectOptions}
            onChange={handleSelectChange}
            value={defaultValue}
            isSearchable
            formatOptionLabel={formatOptionLabel}
            styles={customStyles}
          />

          <Box sx={{ p: 2 }}>
            <Typography variant="body2" gutterBottom>
              {selectedItem?.description}
            </Typography>
          </Box>

          {nestedStandard && (
            <MRTNested
              data={nestedStandard.controls}
              renderActionCell={renderActionCell}
              renderTopToolbarCustomActions={renderTopToolbarCustomActions}
            />
          )}
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
          <Typography variant="h6" color="textSecondary">
            No standards available.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Please add a new standard or refresh the page to see if any updates are available.
          </Typography>
        </Box>
      )}

      <DetailHalfPage
        open={moreOpen}
        setOpen={setMoreOpen}
        selectedData={selectedControl}
        endpoint={detailEndpoint}
      />
    </Box>
  );
}
