import React, { useEffect } from 'react';
import ProcessGuide from '../../../components/Risk/ProcessGuide';
import useRequestCompliance from '../../../hooks/useRequestCompliance';
import { IconButton, Typography, Box, Toolbar, Grid } from '@mui/material';
import { ArrowBackIos as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

export default function AssessmentPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    getCompliance: getAssessment,
    compliance: assessment,
  } = useRequestCompliance({ endpoint: 'complianceAssessment', resourceLabel: 'complianceAssessment' });


  // Fetch the assessment when the component mounts
  useEffect(() => {
    getAssessment(id);
  }, [id, getAssessment]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" height="100vh">
            <Toolbar>
              <IconButton onClick={() => navigate(-1)} edge="start" color="inherit" aria-label="back">
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" flex={1}>
                {assessment ? assessment.title : 'Compliance Assessment Process'}
              </Typography>
            </Toolbar>
            <Box display="flex" flex={1} pt={2}>
              <Box flex={1} pl={3}>
                  <ProcessGuide instance_id={id} process_instance_id={assessment?.process_instance_id} />

              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
