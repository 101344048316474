import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useRequestCompliance from '../../../hooks/useRequestCompliance';
import DetailHalfPage from '../../../components/DetailHalfPage';
import ButtonMenu from '../../../components/buttonMenu';
import { CardActionArea, Box, Button, Dialog, DialogTitle, DialogActions, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function OutlinedCard() {
  const { getComplianceList, complianceList, deleteResource } = useRequestCompliance({ endpoint: 'risk-assessment-methodologies', resourceLabel: 'Risk assessment methodologies' });
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [infoOpen, setInfoOpen] = useState(false);

  useEffect(() => {
    getComplianceList();
  }, [getComplianceList]);

  const [idToDelete, setIdToDelete] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleConfirmDelete = (id) => {
    setIdToDelete(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    setDeleteOpen(false);
    deleteResource(idToDelete);
    window.location.reload(true);
  };

  const handleClickOpen = (id) => {
    if (id) {
      navigate(`/risk/methodologies/edit/${id}`);
    } else {
      navigate('/risk/methodologies/add');
    }
  };

  const handleDetail = (id) => {
    navigate(`/risk/methodologies/detail/${id}`);
  };

  const deleteClick = (id) => {
    handleConfirmDelete(id);
  };

  const editClick = (id) => {
    handleClickOpen(id);
  };

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const [moreOpen, setMoreOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCompliances = complianceList.results?.filter((compliance) => {
    return (
      compliance.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      compliance.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const cardList = filteredCompliances?.map((compliance) => (
    <Grid item xs={6} sm={4} key={compliance.id}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          elevation: 3,
          padding: 2,
        }}
      >
        <CardActionArea onClick={() => navigate(`/risk/methodologies/detail/${compliance?.id}`)}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <Avatar
              src={compliance.image_url || '/static/images/default-logo.png'} // Use default-logo.png or any placeholder image of your choice
              alt="Logo"
              sx={{ width: 60, height: 60 }}
            />
            <Typography variant="h3" component="div" fontWeight="bold" mt={2}>
              {compliance.title}
            </Typography>
          </Box>
          <CardContent>
            <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>{compliance.description}</Typography>
            <Box display="flex" alignItems="center" sx={{ mb: 1.5, color: 'text.secondary' }}>
              <InfoIcon sx={{ marginRight: 1 }} />
              <Typography>{compliance.analysis_approach}</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions placement="top">
          <Grid container justifyContent="space-between">
            <Grid item>
              <ButtonMenu
                editClick={editClick}
                handleDetail={handleDetail}
                deleteClick={deleteClick}
                id={compliance.id}
                navigateTo={`/governance/processes/detail/${compliance.process}`}
                navigateLabel="Process"
                navigateIcon={<ManageHistoryRoundedIcon />}
              ></ButtonMenu>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <div>
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Are you sure you want to delete this methodology?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleDeleteClose}>No</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={infoOpen} onClose={handleInfoClose}>
        <DialogTitle>Risk Assessment Methodology</DialogTitle>
        <DialogContent>
          <Typography paragraph>A risk assessment methodology typically includes:</Typography>
          <Typography paragraph>(i) a risk assessment process ;</Typography>
          <Typography paragraph>
            (ii) an explicit risk model, defining key terms and assessable risk factors and the relationships among the factors;
          </Typography>
          <Typography paragraph>
            (iii) an assessment approach (e.g., quantitative, qualitative, or semi-qualitative), specifying the range of values those risk
            factors can assume during the risk assessment and how combinations of risk factors are identified/analyzed so that values of
            those factors can be functionally combined to evaluate risk; and
          </Typography>
          <Typography paragraph>
            (iv) an analysis approach (e.g., threat- oriented, asset/impact-oriented, or vulnerability-oriented), describing how
            combinations of risk factors are identified/analyzed to ensure adequate coverage of the problem space at a consistent level of
            detail.
          </Typography>
          <Typography paragraph>
            Risk assessment methodologies are defined by organizations and are a component of the risk management strategy developed during
            the risk framing step of the risk management process.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <DetailHalfPage open={moreOpen} setOpen={setMoreOpen} endpoint="risk-assessment-methodologies" />
      <Grid container direction="column" spacing={2} mb={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" gutterBottom display="inline">
              Risk assessment methodologies
            </Typography>
            <Tooltip title="What is a Risk assessment methodology?">
              <IconButton onClick={handleInfoOpen} size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => handleClickOpen(null)} startIcon={<AddIcon />}>
              Add methodology
            </Button>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item xs={12}>
            <TextField
              type="search"
              id="search"
              label="Search"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          {filteredCompliances && filteredCompliances.length > 0 ? (
            cardList
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color="text.secondary">
                No risk assessment methodologies available. Please add a new methodology.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
