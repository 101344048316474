import React, { useState, useRef, useEffect } from 'react' // Add useEffect
import Form from '@rjsf/mui'
import { Box } from '@mui/material'
import validator from '@rjsf/validator-ajv8'
import Select from 'react-select'
import { useTheme } from '@mui/material/styles'

const CustomSelectWidget = ({ id, options, value, onChange, isMulti = false }) => {
  const theme = useTheme()
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.mode === 'dark' ? '#222' : '#ccc',
      '&:hover': {
        borderColor: theme.palette.primary.main
      },
      boxShadow: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? theme.palette.primary.light
        : theme.palette.background.paper,
      color: state.isSelected
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    })
    // Other custom styles...
  }

  // Ensure options are structured correctly for react-select
  const structuredOptions = options.enumOptions.map(option => ({
    value: option.value,
    label: option.label
  }))

  // Map the form value back to the structure expected by react-select
  const structuredValue = isMulti
    ? structuredOptions.filter(option => value?.includes(option.value))
    : structuredOptions.find(option => option.value === value)

  const handleChange = (selectedOptions) => {
    // Correctly update the form value based on select change
    const newValue = isMulti
      ? selectedOptions.map(option => option.value)
      : selectedOptions?.value
    onChange(newValue)
  }
  return (
        <Select
            id={id}
            value={structuredValue} // Use structuredValue
            onChange={handleChange}
            options={structuredOptions} // Use structuredOptions
            isMulti={isMulti}
            styles={customStyles}
            menuPlacement="top" // Changed to 'auto' for automatic adjustment
            isClearable={true} // Ensure select is clearable
            isSearchable={true} // Ensure select is searchable
        />
  )
}

function AttackResourceForm ({ graphUpdate, threatData, schema, handleAddSubmit }) {
  const [formData, setFormData] = useState(threatData) // Initialize with threatData
  const formRef = useRef(null)

  useEffect(() => {
    setFormData(threatData)
  }, [threatData])

  useEffect(() => {
    if (graphUpdate !== 0 && formRef.current) {
          handleSubmit({ formData });

    }
  }, [graphUpdate])

  // Handle form data changes
  const handleChange = (e) => {
    setFormData(e.formData)
  }

  const handleSubmit = ({ formData }) => {
    if (handleAddSubmit) {
      handleAddSubmit(formData)
    }
  }

  const handleError = (errors) => {
    console.error('Form has errors:', errors)
    // Here you can handle errors, show notifications, etc.
  }
  const uiSchema = {
    items: {
      target: {
        'ui:widget': 'CustomSelect'
      },
      vuln_edges: {
        'ui:widget': 'CustomSelect'
      },
      description: {
        'ui:widget': 'textarea'
      }
    }
  }

  const widgets = {
    CustomSelect: props => <CustomSelectWidget {...props} isMulti={props.schema.type === 'array'} />
  }

  return (
        <Box sx={{ width: '100%' }}>
            <Form
        ref={formRef}
        schema={schema}
        formData={formData}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onError={handleError}
        widgets={widgets}
        validator={validator}
        noHtml5Validate
        uiSchema={uiSchema}
      >
        {/* Render nothing here to hide the default submit button */}
        <>
          {/* You can add custom buttons or elements here if needed */}
        </>
      </Form>
        </Box>
  )
}

export default AttackResourceForm
