import React, { useState, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Menu, MenuItem, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ObjectDetailTabs from './ObjectDetailTabs';
import KeyValueFilter from './KeyValueFilter'; // Import KeyValueFilter component

export default function DynamicTable({
  complianceList,
  handleDelete,
  handleEdit,
  currentTab,
  setParentSelectedRows = () => {},
  hideActionsColumn,
  singleSelect,
  handleResetPassword,
}) {
  const [currentRowId, setCurrentRowId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // State for the confirmation dialog
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  // Tag filtering state
  const [tagFilters, setTagFilters] = useState([]);
  const [filterOperators, setFilterOperators] = useState([]); // AND/OR between filters

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentRowId(id);
  };

  const handleDetail = () => {
    setDrawerOpen(true);
  };

  const handleRowSelect = (rowId) => {
    setParentSelectedRows((prevSelected) => {
      if (!Array.isArray(prevSelected)) {
        prevSelected = [];
      }
      if (singleSelect) {
        return [rowId];
      } else {
        if (prevSelected.includes(rowId)) {
          return prevSelected.filter((id) => id !== rowId);
        } else {
          return [...prevSelected, rowId];
        }
      }
    });
  };

  const handleConfirmDeleteOpen = () => {
    setConfirmDeleteOpen(true);
    handleClose();
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleDeleteConfirmed = () => {
    handleDelete(currentRowId);
    handleConfirmDeleteClose();
  };

  // Filtering logic for tag filters
  const filteredData = useMemo(() => {
    const activeFilters = tagFilters.filter(({ key, value }) => key && value);
    if (activeFilters.length === 0) {
      return complianceList; // No filters applied, return all data
    }

    return complianceList.filter((row) => {
      let result = null;

      activeFilters?.forEach(({ key, value }, index) => {
        if (!key || !value) return;

        const matches = row.tags.some((tag) => tag.key === key && tag.value.toLowerCase().includes(value.toLowerCase()));

        if (result === null) {
          result = matches;
        } else if (filterOperators[index - 1] === 'AND') {
          result = result && matches;
        } else if (filterOperators[index - 1] === 'OR') {
          result = result || matches;
        }
      });

      return result;
    });
  }, [complianceList, tagFilters, filterOperators]);

  const columns = useMemo(() => {
    const renderCellContent = (value) => {
      if (Array.isArray(value)) {
        return value.map((item, index) => (
          <div key={index}>{renderCellContent(item)}</div>
        ));
      } else if (typeof value === 'object' && value !== null) {
        return Object.entries(value).map(([key, val], index) => (
          <div key={index}>
            <strong>{key}:</strong> {renderCellContent(val)}
          </div>
        ));
      }
      return value;
    };

    // Define string columns based on the compliance list
    const stringColumns = Object.keys(complianceList[0] || {}).filter(
      (key) => typeof complianceList[0][key] === 'string'
    );

    // Create an array of columns with string columns first
    let columnsArray = stringColumns.map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.slice(1),
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              maxWidth: '300px',
            }}
          >
            {renderCellContent(cell.getValue())}
          </span>
        </Tooltip>
      ),
    }));

    // Add the Actions column if not hidden
    if (!hideActionsColumn) {
      columnsArray.push({
        accessorKey: 'actions',
        header: 'Actions',
        Cell: ({ row }) => (
          <IconButton onClick={(event) => handleClick(event, row.original.id)}>
            <MoreVertIcon />
          </IconButton>
        ),
      });
    }

    // Add the Tags column separately to ensure it is always the last column
    const tagsColumn = {
      accessorKey: 'tags',
      header: 'Tags',
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <Box>
          {cell.getValue()?.map((tag, index) => (
            <Tooltip key={index} title={`${tag.key}: ${tag.value}`} arrow>
              <span
                style={{
                  marginRight: '8px',
                  backgroundColor: '#f0f0f0',
                  padding: '4px',
                  borderRadius: '4px',
                }}
              >
                {tag.key}: {tag.value}
              </span>
            </Tooltip>
          ))}
        </Box>
      ),
    };

    // Ensure Tags column is last by appending it after other columns
    columnsArray.push(tagsColumn);

    return columnsArray;
  }, [complianceList, hideActionsColumn]);

  const data = useMemo(() => filteredData, [filteredData]);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{
          pagination: { pageSize: 10 },
          columnVisibility: { created_by: false, created_at: false, updated_at: false },
          columnOrder: ['mrt-row-select','actions','organization','strategy','title','description','type','role','role_type','principals','scope','tags'],
        }}
        enableRowSelection
        enableGrouping
        enableMultiSelect={!singleSelect}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* KeyValueFilter Component */}
            <KeyValueFilter
              tagFilters={tagFilters}
              filterOperators={filterOperators}
              uniqueTagKeys={data.reduce((acc, row) => {
                row.tags?.forEach((tag) => {
                  if (!acc.includes(tag.key)) acc.push(tag.key);
                });
                return acc;
              }, [])}
              handleTagFilterChange={(index, field, value) => {
                const newFilters = tagFilters.map((filter, i) =>
                  i === index ? { ...filter, [field]: value } : filter
                );
                setTagFilters(newFilters);
              }}
              handleOperatorChange={(index, value) => {
                const newOperators = [...filterOperators];
                newOperators[index] = value;
                setFilterOperators(newOperators);
              }}
              handleAddTagFilter={() => {
                setTagFilters([...tagFilters, { key: '', value: '' }]);
                setFilterOperators([...filterOperators, 'AND']);
              }}
              handleRemoveTagFilter={(index) => {
                const newFilters = tagFilters.filter((_, i) => i !== index);
                const newOperators = filterOperators.filter((_, i) => i !== index);
                setTagFilters(newFilters);
                setFilterOperators(newOperators);
              }}
            />
          </Box>
        )}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        elevation={1}
      >
        <MenuItem onClick={handleConfirmDeleteOpen}>Delete</MenuItem>
        <MenuItem onClick={() => handleEdit(currentRowId)}>Edit</MenuItem>
        <MenuItem onClick={handleDetail}>Detail</MenuItem>
        {handleResetPassword && (
          <MenuItem onClick={() => { handleResetPassword(currentRowId); handleClose(); }}>Reset Password</MenuItem>
        )}
      </Menu>
      <Dialog
        open={confirmDeleteOpen}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ObjectDetailTabs
        id={currentRowId}
        endpoint={currentTab ? currentTab.endpoint : null}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
    </>
  );
}
