import React, { useState, useEffect, useContext,useRef } from 'react';
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Box,
  Modal,
  TextField,
  CircularProgress,
  Button,
  Badge,
  List,
  ListItem
} from '@mui/material';
import PropTypes from 'prop-types';
import { AccountCircle, Notifications as NotificationsIcon } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from '../../contexts/AuthContextProvider';
import useRequestAuth from '../../hooks/useRequestAuth';
import logo from '../../static/images/eunoia1.png';
import { styled, useTheme } from '@mui/material/styles';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import Select, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const drawerWidth = 240;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '400px', // Fixed width
  [theme.breakpoints.up('sm')]: {
    width: '400px' // Fixed width for larger screens
  }
}));

const CustomSelect = ({ ...props }) => (
  <components.Control {...props}>
    <SearchIcon style={{ marginLeft: 8, color: 'rgba(0, 0, 0, 0.54)' }} />
    {props.children}
  </components.Control>
);

export function AppHeader({ mobileOpen, setMobileOpen }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { logout, logoutPending } = useRequestAuth();
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const { resetPassword } = useRequestAuth();
  const { searchResults, performSearch, loading } = useGlobalSearch();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const notificationOpen = Boolean(notificationAnchorEl);
  const [unreadCount, setUnreadCount] = useState(0);
  const { getComplianceList, complianceList: notificationList = [], actionResource } = useRequestCompliance({ endpoint: 'notifications' });

  const socketRef = useRef(null); // Use useRef to hold the WebSocket instance
  const reconnectAttemptsRef = useRef(0); // Track the number of reconnection attempts
  const MAX_RECONNECT_ATTEMPTS = 5; // Set the maximum number of reconnection attempts
  const RECONNECT_INTERVAL_INCREMENT = 1000; // Set the increment time in ms

  useEffect(() => {
    getComplianceList(); // Fetch notifications
  }, [getComplianceList]);

  useEffect(() => {
    if (notificationList.results.length > 0) {
      const sortedNotifications = notificationList.results.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setNotifications(sortedNotifications);
      setUnreadCount(sortedNotifications.filter(notification => !notification.read).length);
    }
  }, [notificationList]);

  const connectWebSocket = () => {
    const token = localStorage.getItem('authToken'); // Retrieve token from localStorage
    const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
    let host = window.location.hostname; // Dynamically get the hostname

    // Set port to 8000 if the host is localhost; otherwise, use the current port if present
    let port = window.location.port ? `:${window.location.port}` : '';
    if (host === 'localhost') {
      port = ':8000';
    }

    // Construct the WebSocket URL dynamically with secure protocols in production
    const wsPath = `${wsScheme}://${host}${port}/ws/notifications/`;

    console.log(wsPath);

    // Create a new WebSocket instance
    const socket = new WebSocket(wsPath);

    socketRef.current = socket; // Store the WebSocket reference

    // Handle the opening of the WebSocket connection
    socket.onopen = () => {
      console.log('Connected to WebSocket');
      socket.send(JSON.stringify({ token })); // Send token securely after establishing the connection
      reconnectAttemptsRef.current = 0; // Reset reconnect attempts on successful connection
    };

    // Handle incoming messages
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received message:', message);
      getComplianceList();
    };

    // Handle errors
    socket.onerror = (error) => {
      console.log('WebSocket error:', error);
      socket.close(); // Close the connection on error to prevent repeated issues
    };

    // Handle disconnections and attempt to reconnect
    socket.onclose = (event) => {
      console.log('WebSocket disconnected', event);
      if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
        handleReconnect(); // Trigger reconnection if the limit is not exceeded
      } else {
        console.log('Max reconnect attempts reached.');
        // Optionally, notify the user that the connection is lost and couldn't be restored
      }
    };
  };

  const handleReconnect = () => {
    reconnectAttemptsRef.current += 1; // Increment the reconnect attempt count
    const delay = RECONNECT_INTERVAL_INCREMENT * reconnectAttemptsRef.current; // Increase delay with each attempt

    console.log(`Reconnecting WebSocket in ${delay / 1000} seconds (attempt ${reconnectAttemptsRef.current})...`);

    setTimeout(() => {
      connectWebSocket(); // Attempt to reconnect
    }, delay);
  };

  useEffect(() => {
    connectWebSocket(); // Connect on component mount

    // Cleanup WebSocket on component unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []); // Empty dependency array to run once on component mount

  const isAbsoluteURL = (url) => {
    const regex = new RegExp('^(?:[a-z]+:)?//', 'i');
    return regex.test(url);
  };
  
  const handleNotificationMenuOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    setUnreadCount(0); // Reset unread count when menu is opened
  };

  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleNotificationClick = async (not) => {
    console.log(not);
    if (!not.read) {
      await actionResource(not.id, {}, 'mark_as_read/');
    }
    if (not.url) {
      if (isAbsoluteURL(not.url)) {
        window.open(not.url, '_blank'); // Open absolute URLs in a new tab
      } else {
        navigate(not.url); // Navigate to relative paths within the app
      }
      handleNotificationMenuClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword({ currentPassword, newPassword, reNewPassword });
  };

  const handleLogout = () => {
    logout();
  };

  const handlePasswordResetModalOpen = () => {
    setPasswordResetModalOpen(true);
  };

  const handlePasswordResetModalClose = () => {
    setPasswordResetModalOpen(false);
  };

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleReNewPasswordChange = (event) => {
    setReNewPassword(event.target.value);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
    setAnchorEl(null);
  };
  const handleOpenRoles= () => {
    navigate('/myroles');
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const passwordResetModal = (
    <Modal open={passwordResetModalOpen} onClose={handlePasswordResetModalClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2">
          Reset Password
        </Typography>
        <TextField
          id="current-password"
          type="password"
          label="Current Password"
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
          variant="outlined"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        />
        <TextField
          id="new-password"
          type="password"
          label="New Password"
          value={newPassword}
          onChange={handlePasswordChange}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          id="confirm-new-password"
          type="password"
          label="Confirm New Password"
          value={reNewPassword}
          onChange={handleReNewPasswordChange}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Reset Password
        </Button>
      </Box>
    </Modal>
  );

  const modal = (
    <Modal open={modalIsOpen} onClose={handleCloseModal}>
      <Box sx={modalStyle}>
        <Typography
          variant="h6"
          component="h2"
          sx={{
            mb: 3
          }}>
          Profile
        </Typography>
        <TextField
          id="username"
          variant="outlined"
          label="Username"
          value={user ? user.username : ''}
          disabled
          sx={{
            mb: 3,
            width: '100%'
          }}
        />
        <TextField
          id="email"
          variant="outlined"
          label="Email"
          value={user ? user.email : ''}
          disabled
          sx={{
            mb: 3,
            width: '100%'
          }}
        />
      </Box>
    </Modal>
  );

  const authLinks = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        aria-label="notifications"
        aria-controls="menu-notifications"
        aria-haspopup="true"
        onClick={handleNotificationMenuOpen}
        color="inherit"
        size="large">
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon style={{ color: theme.palette.background.paper }} />
        </Badge>
      </IconButton>
      <Menu
        id="menu-notifications"
        anchorEl={notificationAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={notificationOpen}
        onClose={handleNotificationMenuClose}
        PaperProps={{
          style: {
            width: 300,
            maxHeight: 400, // Fixed size for notifications menu
          },
        }}>
        <MenuItem component={Link} to="/notifications" sx={{ color: 'primary.main' }}>
          View All Notifications
        </MenuItem>
        {notifications.length === 0 ? (
          <MenuItem disabled>No new notifications</MenuItem>
        ) : (
          <List>
            {notifications.map((notification, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  mb: 1,
                  borderBottom: '1px solid #e0e0e0',
                  cursor: notification.url ? 'pointer' : 'default',
                  backgroundColor: notification.read ? 'white' : '#e3f2fd' // Blue-white for unread
                }}
                onClick={() => handleNotificationClick(notification)}
              >
                <Typography variant="body2">
                  <strong>{notification.message}</strong>
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {new Date(notification.timestamp).toLocaleString()}
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </Menu>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        size="large">
        <AccountCircle style={{ color: theme.palette.background.paper }} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={handleOpenModal}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleOpenRoles}>
          My roles
        </MenuItem>
        <MenuItem onClick={handlePasswordResetModalOpen}>
          Reset My Password
        </MenuItem>
        <MenuItem disabled={logoutPending} onClick={handleLogout}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {logoutPending === true ? (
              <CircularProgress
                size={20}
                sx={{
                  mr: 2
                }}
              />
            ) : null}
          </Box>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
  const handleSearchChange = (newValue) => {
    setInputValue(newValue);
    if (newValue.trim()) {
      performSearch(newValue.trim());
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const { value, type } = selectedOption;
      const path = generatePathForType(type, value);
      navigate(path);
      setInputValue('');
      setSelectedOption(null);
    }
  };

  const generatePathForType = (type, id) => {
    switch (type) {
      case 'standard':
        return `/compliance/standards/${id}`;
      case 'program':
        return `/compliance/programs/detail/${id}`;
      case 'informationsystem':
        return `/manage/assets/information_systems/detail/${id}`;
      case 'supportingasset':
        return `/manage/assets/supporting_assets/detail/${id}`;
      case 'primaryasset':
        return `/manage/assets/primary_assets/detail/${id}`;
      case 'myprocess':
        return `/governance/processes/detail/${id}`;
      case 'responsibilitymodel':
        return `/governance/responsibilities/explore/${id}`;
      case 'riskassessmentmethodology':
        return `/risk/methodologies/detail/${id}`;
      default:
        return '/';
    }
  };

  const groupedOptions = Object.values(searchResults.reduce((acc, result) => {
    const group = acc[result.type] || { label: result.type, options: [] };
    group.options.push({ label: result.title, value: result.id, type: result.type });
    acc[result.type] = group;
    return acc;
  }, {}));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
      width: '400px',
      display: 'flex',
      alignItems: 'center'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.text.primary
    }),
    input: (provided) => ({
      ...provided,
      color: theme.palette.text.primary
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? theme.palette.primary.main
        : state.isFocused
        ? theme.palette.action.hover
        : theme.palette.background.paper,
      color: state.isSelected
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary
    })
  };

  return (
    <AppBar
      position="fixed"
      color='header'
      xs={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` }
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: 'none' } }}>
          <MenuIcon style={{ color: theme.palette.background.paper }} />
        </IconButton>
        <img src={logo} alt="Eunoia Logo" style={{ height: '30px', marginRight: '10px' }} />
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <SearchWrapper>
            <Select
              inputValue={inputValue}
              value={selectedOption}
              options={groupedOptions}
              styles={customStyles}
              onInputChange={(e) => handleSearchChange(e)}
              onChange={handleSelectChange}
              isLoading={loading}
              placeholder="Search..."
              isClearable
              components={{ Control: CustomSelect }}
            />
          </SearchWrapper>
        </Box>
        {authLinks}
      </Toolbar>
      {modal}
      {passwordResetModal}
    </AppBar>
  );
}

AppHeader.propTypes = {
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func.isRequired
};

export default AppHeader;
