import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Paper, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import { useNavigate } from 'react-router-dom';

export default function NotificationsPage() {
  const { getComplianceList, complianceList: notifications = [], actionResource } = useRequestCompliance({ endpoint: 'notifications' });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      await getComplianceList();
      setLoading(false);
    };

    fetchNotifications();
  }, [getComplianceList]);

  const handleNotificationClick = async (notification) => {
    if (notification.url) {
      if (notification.url.startsWith('http://') || notification.url.startsWith('https://')) {
        window.location.href = notification.url;
      } else {
        navigate(notification.url);
      }
    }
    // Mark the notification as read
    await actionResource(notification.id, {}, 'mark_as_read/');
  };

  return (
    <Box m={3}>
      <Typography variant="h4" gutterBottom>
        Notifications
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper>
          <List>
            {notifications.results.map((notification, index) => (
              <ListItem
                key={index}
                alignItems="flex-start"
                button
                onClick={() => handleNotificationClick(notification)}
                sx={{
                  backgroundColor: notification.read ? 'white' : '#e3f2fd', // Blue-white shade for unread notifications
                  '&:hover': {
                    backgroundColor: 'grey.200',
                  },
                  cursor: notification.url ? 'pointer' : 'default',
                  borderBottom: '1px solid',
                  borderBottomColor: 'divider',
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="body1" color="primary">
                      {notification.message}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {new Date(notification.timestamp).toLocaleString()}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
}
