import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import DetailedAsset from 'src/components/Asset/DetailedAssset';

const DetailedAssetView = () => {
  const { id, type } = useParams(); // Get the id from the route parameters
  const [catId, setCatId] = useState()
  const { getCompliance, compliance: assetDetails } = useRequestCompliance({ endpoint: type }); // Adjust the endpoint as necessary
  const { getCompliance: getCatInstance, compliance: catInstance, resetCompliance } =
    useRequestCompliance({ endpoint: 'categorization_instances' })

  useEffect(() => {
    if (id) {
      getCompliance(id); // Fetch the asset details using the id
    }
  }, [id, getCompliance]);

  useEffect(() => {
    if (assetDetails) { assetDetails.security_requirements ? setCatId(assetDetails.security_requirements) : setCatId(assetDetails.security_compatibilities) }
  }, [assetDetails])

  useEffect(() => {
    if (catId) { getCatInstance(catId) }
  }, [catId])


  return (
    <DetailedAsset id={id} type={type} />
  );
};

export default DetailedAssetView;
