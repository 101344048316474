import React, { useEffect, useMemo, useState, useCallback } from 'react'
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  Panel,
  MarkerType
} from 'reactflow'
import Dagre from '@dagrejs/dagre'
import StorageIcon from '@mui/icons-material/Storage'
import CustomNode from '../General/EunoiaFlow/CustomNode'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import 'reactflow/dist/style.css'
import 'src/static/css/EunoiaFlow.css'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import FlagIcon from '@mui/icons-material/Flag'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import AttackResourceForm from 'src/components/Risk/AttackResourceForm'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import { Grid } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import GroupIcon from '@mui/icons-material/Group'
import PestControlIcon from '@mui/icons-material/PestControl'
import CloseIcon from '@mui/icons-material/Close'
import {  Dialog, IconButton, DialogContent, AppBar, Toolbar, Typography, Button } from '@mui/material'

const SupportingAssetNode = (props) => (
  <CustomNode {...props} icon={StorageIcon} iconColor="#F5F5F5" backgroundColor="#4a148c
  " />
)
const PrimaryAssetNode = (props) => (
  <CustomNode {...props} icon={DocumentScannerIcon} iconColor="#F5F5F5" backgroundColor="#e65100" />
)

const ISNode = (props) => (
  <CustomNode {...props} icon={DeviceHubIcon} iconColor="#F5F5F5" backgroundColor="#006064" />
)
const MissionNode = (props) => (
  <CustomNode {...props} icon={FlagIcon} iconColor="#F5F5F5" backgroundColor="#1b5e20" />
)

const OrganisationNode = (props) => (
  <CustomNode {...props} icon={CorporateFareIcon} iconColor="#F5F5F5" backgroundColor="#4a148c" />
)

const ThreatNode = (props) => (
  <CustomNode {...props} icon={WarningIcon} iconColor="#F5F5F5" backgroundColor="#b71c1c" />
)

const DefaultNode = (props) => (
  <CustomNode {...props} icon={HelpOutlineIcon} iconColor="#F5F5F5" backgroundColor="#9E9E9E" />
)

const StakeholderNode = (props) => (
  <CustomNode {...props} icon={GroupIcon} iconColor="#F5F5F5" backgroundColor="#607d8b" />
)

const VulnerabilityNode = (props) => (
  <CustomNode {...props} icon={PestControlIcon} iconColor="#FFF" backgroundColor="#FF9800" />
)

const dagreGraph = new Dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  if (!nodes.length) {
    return { nodes: [], edges: [] };
  }

  const nodeWidth = 172; // Default node width
  const nodeHeight = 36; // Default node height
  const ranksep = 100; // Vertical spacing between nodes
  const nodesep = 70; // Horizontal spacing between nodes

  // Initial Dagre graph configuration
  dagreGraph.setGraph({
    rankdir: direction,
    ranksep,
    nodesep,
    marginx: 20, // Margin around the graph on the X-axis
    marginy: 20, // Margin around the graph on the Y-axis
  });

  // Add nodes and edges to Dagre for layout
  nodes.forEach(node => dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight }));
  edges.forEach(edge => dagreGraph.setEdge(edge.source, edge.target));

  // Perform layout calculation
  Dagre.layout(dagreGraph);

  // Apply the calculated positions
  nodes.forEach(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
  });

  // Define vertical levels for each type of node
  const levels = {
    mission: 0,
    organization: 1,
    primaryasset: 2,
    supportingasset: 3,
    stakeholder: 4,
    information_system: 5,
    vulnerability: 5.3,
    threat: 7,
  };

  // Define vertical position offsets based on levels
  const levelHeight = 150; // Distance between each level

  // Position nodes by type on their respective levels
  Object.keys(levels).forEach((type) => {
    const levelNodes = nodes.filter(node => node.type === type);
    let currentX = 50; // Starting X position for the first node in each level
    let delta = 20;

    levelNodes.forEach(node => {
      node.position.x = currentX;
      node.position.y = (levels[type]) * levelHeight + delta; // Set Y position based on the node type level
      currentX += nodeWidth + nodesep; // Increment X position for the next node
      delta = -delta
    });
  });



  return { nodes, edges };
};


const EunoiaFlow = ({ handleSaveScenario, itemToEdit, endpoint, initialNodes, vulns, initialEdges, graphUpdate,strategic }) => {
  const { schema, getSchema } = useRequestCompliance({
    endpoint: 'threatevents',
    resourceLabel: 'threatevents schema'
  })
  const { getCompliance: getResource, compliance: resource, loading } = useRequestCompliance({ endpoint })
  const [modifiedSchema, setModifiedSchema] = useState({
    type: 'array',
    items: {
      type: 'object',
      properties: {}
    }
  })
  // State for controlling the full-screen dialog
  const [isFullScreen, setIsFullScreen] = useState(false)
  // Handler to toggle full-screen mode
  const toggleFullScreen = () => {
    setIsFullScreen(prev => !prev)
  }
  // Handler for node changes including drag events
  const [nodes, setNodes, onNodesChange] = useNodesState([])

  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [threatData, setThreatData] = useState([]) // State to store threat data

  useEffect(() => {
    if (initialNodes.length > 0) {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        initialNodes,
        initialEdges
      )
      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [initialNodes, initialEdges])

  useEffect(() => {
    if (resource?.attacksteps && resource.attacksteps.length > 0) {
      addThreatNodes(resource.attacksteps)
    }
  }, [resource])

  useEffect(() => {
    getSchema()
  }, [])
  useEffect(() => {
    const nonVulnNodes = initialNodes.filter(node => node.type !== 'vulnerability')
    const modifiedSchema = {
      ...schema,
      properties: {
        ...schema?.properties,
        target: {
          type: 'string',
          title: 'Targeted object',
          description: 'Select attacker targeted object',
          oneOf: [
            { enum: ['0'], title: 'None' }, // Explicitly allowing a 'None' option
            ...nonVulnNodes.map(node => ({
              enum: [node.id],
              title: node.data.label
            }))
          ]
        },
        // Conditionally add vuln_edges only if strategic is false
        ...(strategic ? {} : {
          vuln_edges: {
            type: 'array',
            title: 'vulnerabilities_instances',
            description: 'Select vulnerabilities the attacker can exploit',
            items: {
              oneOf: [
                ...(vulns
                  ? vulns.map(node => ({
                      const: node.vuln_edge,
                      title: node.title
                    }))
                  : [])
              ]
            },
            uniqueItems: true
          }
        })
        },
        required: schema?.required || []
        };
    const requiredFields = schema?.required || []

    // Filter out readOnly properties
    const filteredProperties = modifiedSchema && modifiedSchema?.properties
      ? Object.keys(modifiedSchema.properties)
        .filter(key => !modifiedSchema.properties[key].readOnly)
        .reduce((obj, key) => {
          obj[key] = modifiedSchema.properties[key]
          return obj
        }, {})
      : {}

    const itemSchema = {
      type: 'object',
      title: 'Attack step',
      properties: filteredProperties,
      required: requiredFields
    }

    const arraySchema = {
      type: 'array',
      title: 'List of Threats for Your Attack Scenario',
      description: 'This form allows you to document each step in your attack scenario. Each item in the list represents a distinct threat or attack step. Please add the steps in the order they occur, as the sequence will be considered from top to bottom. To build your attack scenario, add items detailing each specific threat or action involved in the attack.',
      items: itemSchema
    }
    setModifiedSchema(arraySchema)
  }, [schema, initialNodes, vulns])

  useEffect(() => {
    if (itemToEdit) {
      getResource(itemToEdit.id)
    } else {
      setNodes(initialNodes)
      setEdges(initialEdges)
      setThreatData([])
    }
  }, [itemToEdit])

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodes,
        edges,
        direction
      )

      setNodes([...layoutedNodes])
      setEdges([...layoutedEdges])
    },
    [nodes, edges] // Include nodes and edges in the dependency array
  )

  const addThreatNodes = (dataList) => {
    // Filter out empty objects
    const validDataList = Array.isArray(dataList) ? dataList.filter(data => Object.keys(data).length > 0) : []

    // Filter out existing threat nodes (and their edges will be automatically removed)
    const nonThreatNodes = nodes.filter(node => node.type !== 'threat')

    const newNodes = []
    const newEdges = []

    validDataList.forEach((data, index) => {
      const newThreatNodeId = `threat-${Date.now()}-${index}`
      if (!data.target) {
        data.target = '0'
      }
      const newNode = {
        id: newThreatNodeId,
        type: 'threat',
        data: { ...data, label: data.title }
      }

      newNodes.push(newNode)

      // Create edges between the new nodes
      if (index > 0) {
        const newEdgeId = `e${newNodes[index - 1].id}-${newThreatNodeId}`
        const newEdge = {
          id: newEdgeId,
          target: newThreatNodeId,
          source: newNodes[index - 1].id,
          sourceHandle: 'source-threat',
          targetHandle: 'target-threat',
          animated: true,
          label: 'Then',
          style: { stroke: 'red' }, // Set the edge color to red
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 50,
            height: 20,
            color: 'red'
          }
        }
        newEdges.push(newEdge)
      }
      // Check for target in data and create an edge from 'attack' handle to the target node
      if (data.target && data.target.trim() !== '') {
        const targetEdgeId = `e${newThreatNodeId}-to-${data.target}`
        const targetEdge = {
          id: targetEdgeId,
          source: newThreatNodeId,
          sourceHandle: 'source-attack', // Assuming 'attack' is a valid source handle in your node
          targetHandle: 'target-attack',
          target: data.target,
          animated: true,
          label: 'Attack',
          style: { stroke: 'red' }, // Set the edge color to red
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 50,
            height: 20,
            color: 'red'
          }
        }
        newEdges.push(targetEdge)
      }

      // Create edges for vulnerabilities related to this threat
      if (data.vuln_edges && data.vuln_edges.length > 0) {
        data.vuln_edges.forEach(vulnId => {
          newEdges.push({
            id: `e${newThreatNodeId}-vuln-${vulnId}`,
            source: newThreatNodeId,
            target: vulnId,
            animated: true,
            label: 'Exploits',
            style: { stroke: 'orange' }, // Different color to distinguish
            markerEnd: { type: MarkerType.ArrowClosed, color: 'blue' }
          })
        })
      }
    })
    // Apply layout algorithm to the new nodes and edges
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      [...nonThreatNodes, ...newNodes],
      newEdges
    )
    // Now update the state once after layout
    setNodes(layoutedNodes)
    setEdges((eds) => [...eds, ...layoutedEdges])

    // Update threatData state
    setThreatData(validDataList)
  }

  // Memoize nodeTypes to avoid re-creation on every render
  const nodeTypes = useMemo(() => ({
    supportingasset: SupportingAssetNode,
    primaryasset: PrimaryAssetNode,
    information_system: ISNode,
    mission: MissionNode,
    organization: OrganisationNode,
    threat: ThreatNode,
    stakeholder: StakeholderNode,
    vulnerability: VulnerabilityNode,
    undefined: DefaultNode
  }), [])

  const handleSubmitScenario = (data) => {
    const updatedThreatData = data.map(threat => {
      const targetNode = nodes.find(node => node.id === threat.target)

      // Initialize an array to hold references or IDs of related vulnerabilities
      let relatedVulns = []

      // If there are vulnerability edges, find the corresponding vulnerabilities
      if (threat.vuln_edges && threat.vuln_edges.length > 0) {
        relatedVulns = threat.vuln_edges.map(vulnId => {
          // Assuming vulnId is sufficient to find the vulnerability node
          // Adjust as necessary if more info is needed or the ID format differs
          const vulnNode = nodes.find(node => node.id === vulnId)
          return vulnNode ? vulnNode.data.id : null // Or any other identifier you need
        }).filter(vuln => vuln !== null) // Filter out any unresolved vulnerabilities
      }

      // Include related vulnerabilities in the returned object for each threat
      return {
        ...threat,
        object_id: targetNode ? targetNode.data.object_id : undefined, // Use undefined or appropriate fallback
        model_id: targetNode ? targetNode.data.model_id : undefined, // Use undefined or appropriate fallback
        vulnerability_instances: relatedVulns // Include the list of related vulnerabilities
      }
    })

    // Proceed with adding threat nodes and handling the scenario save
    addThreatNodes(updatedThreatData)
    handleSaveScenario(updatedThreatData)
  }

  return (
    <Grid>
      <div style={{ width: '100%', height: '500px', position: 'relative' }}>
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          fitView
          minZoom={0.1}
          maxZoom={10}
        >
          <Background />
          <Controls />
          <MiniMap />
          <Panel position="top-right">
            <button onClick={() => toggleFullScreen()}>Full screen</button>
          </Panel>
        </ReactFlow>
      </div>

      <Grid>
        <AttackResourceForm
          key={itemToEdit?.id}
          graphUpdate={graphUpdate}
          threatData={threatData}
          initialNodes={initialNodes}
          schema={modifiedSchema}
          handleAddSubmit={handleSubmitScenario}
        />
      </Grid>

      {/* Full-Screen Dialog */}
      <Dialog
        open={isFullScreen}
        onClose={toggleFullScreen}
        maxWidth="lg" // Set maximum width
        fullWidth // Make it responsive to width
        PaperProps={{
          style: {
            height: '95%', // 90% height of the screen
            backgroundColor: '#ffffff', // Custom background color
            borderRadius: '8px', // Rounded corners
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark non-transparent background
          },
        }}
      >        <AppBar sx={{ position: 'relative' }} >
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={toggleFullScreen} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              Full-Screen Graph
            </Typography>
            <Button autoFocus color="inherit" onClick={toggleFullScreen}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView
            minZoom={0.1}
            maxZoom={10}
          >
            <Background />
            <Controls />
            <MiniMap />
          </ReactFlow>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default EunoiaFlow