import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Box,
  Tooltip
} from '@mui/material';
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm';
import ThreatSource from 'src/components/Risk/Forms/ThreatSource';
import ThreatSourceChar from 'src/components/Risk/Forms/ThreatSourceChar';
import ThreatEvent from 'src/components/Risk/Forms/ThreatEvent';
import ThreatEventSources from './Forms/ThreatEventSources';
import ThreatEventRelevance from './Forms/ThreatEventRelevance';
import Vulnerability from './Forms/Vulnerability';
import Predisposing from './Forms/Predisposing';
import VulnerabilityThreatEvents from './Forms/VulnerabilityThreatEvents';
import PredisposingThreatEvents from './Forms/PredisposingThreatEvents';
import ThreatLikelihood from './Forms/ThreatLikelihood';
import Impact from './Forms/Impact';
import ImpactThreatEvents from './Forms/ImpactThreatEvent';
import Risk from './Forms/Risk';
import ImpactLevel from './Forms/ImpactLevel';
import RiskCommunication from './Forms/RiskCommunication';
import GenericForm from './Forms/GenericForm';
import ProcessNavigation from '../General/ProcessNavigation';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import { Done, DoneAll } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const formComponents = {
  default: MultiStepResourceForm,
  associate_threat_sources: ThreatSource,
  get_threat_source_instances: ThreatSourceChar,
  identify_threat_events: ThreatEvent,
  associate_threat_events_to_sources: ThreatEventSources,
  evaluate_threat_events_relevance: ThreatEventRelevance,
  identify_vulnerabilities: Vulnerability,
  identify_predisposing: Predisposing,
  associate_vulnerabilities_to_threat_events: VulnerabilityThreatEvents,
  associate_predisposing_to_threat_events: PredisposingThreatEvents,
  determine_likelihood: ThreatLikelihood,
  identify_impact: Impact,
  determine_impact: ImpactThreatEvents,
  assess_impact: ImpactLevel,
  determine_risk: Risk,
  communicate_risk: RiskCommunication,
  genericform: GenericForm
};

const ResourceForm = ({ model, resourceName, instance_id, actions, organization }) => {
  if (resourceName === null) {
    return null;
  }
  const SpecificForm = formComponents[resourceName] || formComponents.default;
  return (
    <SpecificForm
      model={model}
      resourceName={resourceName}
      instance_id={instance_id}
      actions={actions}
      organization={organization}
    />
  );
};

const MultiProcess = ({ instance_id, organization, process_instance_id }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [completedSteps, setCompletedSteps] = useState({});
  const [completedTasks, setCompletedTasks] = useState({});
  const [data, setData] = useState();
  const theme = useTheme(); // Access theme

  const { addCompliance } = useRequestCompliance({
    endpoint: 'task-completion/complete_task',
    resourceLabel: 'Task completion',
  });
  const { getCompliance: getPI, compliance: processInstance } = useRequestCompliance({
    endpoint: 'process-instances',
    resourceLabel: 'Task',
  });

  // Handle completing a task
  const handleTaskComplete = (task) => {
    const payload = {
      instance_id: instance_id,
      model_id: data?.model?.id,
      task_id: task?.id,
    };

    addCompliance(payload, (responseData) => {
      setCompletedTasks((prev) => {
        const updatedCompletedTasks = { ...prev, [task.id]: true };

        // Check if all tasks in the parent step are completed
        const parentStep = processInstance?.step_instances.find(stepInstance =>
          stepInstance.task_instances.some(taskInstance => taskInstance.task.id === task.id)
        );

        if (parentStep) {
          const allTasksCompleted = parentStep.task_instances.every(taskInstance =>
            updatedCompletedTasks[taskInstance.task.id]
          );

          if (allTasksCompleted) {
            setCompletedSteps((prevSteps) => ({
              ...prevSteps,
              [parentStep.step.id]: true,
            }));
          }
        }

        return updatedCompletedTasks;
      });
    });
  };

  // Initialize completed tasks and steps from processInstance
  useEffect(() => {
    if (processInstance) {
      // Initialize completed tasks and steps from processInstance
      const initialCompletedTasks = {};
      const initialCompletedSteps = {};

      processInstance.step_instances.forEach(stepInstance => {
        if (stepInstance.completed) {
          initialCompletedSteps[stepInstance.step.id] = true;
        }
        stepInstance.task_instances.forEach(taskInstance => {
          if (taskInstance.completed) {
            initialCompletedTasks[taskInstance.task.id] = true;
          }
        });
      });

      setCompletedSteps(initialCompletedSteps);
      setCompletedTasks(initialCompletedTasks);
      setData(processInstance.process);
    }
  }, [processInstance]);

  useEffect(() => {
    if (processInstance) {
      setCompletedTasks((prev) => ({ ...prev, [processInstance.task]: processInstance.completed }));
    }
  }, [processInstance]);

  useEffect(() => {
    if (process_instance_id) {
      getPI(process_instance_id);
    }
  }, [getPI, process_instance_id]);

  useEffect(() => {
    setData(processInstance?.process);
  }, [processInstance]);



  const handleItemSelect = (itemType, item) => {
    setSelectedItem({ type: itemType, data: item });
  };


  if (!data || !data.steps) return null;

  const getSelectedItemTitle = () => {
    if (!selectedItem) return '';
    return selectedItem.type === 'step'
      ? selectedItem.data.step.title
      : selectedItem.data.task.title;
  };

  const getSelectedItemDescription = () => {
    if (!selectedItem) return '';
    return selectedItem.type === 'step'
      ? selectedItem.data.step.description
      : selectedItem.data.task.description;
  };

  const getSelectedItemApiResource = () => {
    if (!selectedItem) return null;
    return selectedItem.type === 'task'
      ? selectedItem.data.task.api_resource
      : null;
  };

  const getSelectedItemActions = () => {
    if (!selectedItem) return [];
    return selectedItem.type === 'task'
      ? selectedItem.data.task.actions
      : [];
  };


  return (
    <Box display="flex" >
      {/* Process Navigation Side Menu */}
      <ProcessNavigation
        step_instances={processInstance?.step_instances}
        onItemSelect={handleItemSelect}
        completedSteps={completedSteps}  // Pass completed steps
        completedTasks={completedTasks}  // Pass completed tasks
      />
      {/* Main Content Area */}
      <Box flex={1} p={3} width='100px'>
        {/* Top Section with Complete Task Button */}
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4">{getSelectedItemTitle()}</Typography>

          {selectedItem?.type === 'task' && (
            <Tooltip
              title={
                completedTasks[selectedItem.data.task.id]
                  ? 'This task has been completed'
                  : 'Mark this task as completed'
              }
            >
              <span>
              <Button
                  variant="outlined" // Use "outlined" or "text" variant instead of "contained"
                  startIcon={completedTasks[selectedItem.data.task.id] ? <DoneAll /> : <Done />}
                  onClick={() => handleTaskComplete(selectedItem.data.task)}
                  sx={{
                    color: completedTasks[selectedItem.data.task.id]
                      ? theme.palette.success.main // Use theme's success color for text
                      : theme.palette.primary.main, // Use theme's primary color for text
                    borderColor: completedTasks[selectedItem.data.task.id]
                      ? theme.palette.success.main // Use success color for border
                      : theme.palette.primary.main, // Use primary color for border
                    '&:hover': {
                      borderColor: completedTasks[selectedItem.data.task.id]
                        ? theme.palette.success.dark // Use dark success color on hover
                        : theme.palette.primary.dark, // Use dark primary color on hover
                    },
                  }}
                >
                  {completedTasks[selectedItem.data.task.id] ? 'Task Completed' : 'Complete Task'}
                </Button>
              </span>
            </Tooltip>
          )}
                  {/* Complete Step Button */}
        {selectedItem?.type === 'step' && (
                  <Tooltip
                    title={
                      completedSteps[selectedItem.data.step.id]
                        ? 'This step has been completed'
                        : 'Once all tasks are completed, this step will be marked as completed automatically'
                      }
                  >
                    <span>
                      <Button
                        variant="outlined" // Use "outlined" variant similar to the task button
                        startIcon={completedSteps[selectedItem.data.step.id] ? <DoneAll /> : <Done />}
                        sx={{
                          color: completedSteps[selectedItem.data.step.id]
                            ? theme.palette.success.main // Success color for completed steps
                            : theme.palette.primary.main, // Primary color for incomplete steps
                          borderColor: completedSteps[selectedItem.data.step.id]
                            ? theme.palette.success.main // Success color for the border
                            : theme.palette.primary.main, // Primary color for the border
                          '&:hover': {
                            borderColor: completedSteps[selectedItem.data.step.id]
                              ? theme.palette.success.dark // Darker success color on hover
                              : theme.palette.primary.dark, // Darker primary color on hover
                          },
                          opacity: completedSteps[selectedItem.data.step.id] ? 0.5 : 1, // Lower opacity for completed steps
                          cursor: 'not-allowed', // Not-allowed cursor to indicate non-clickability
                          pointerEvents: 'none' // Disable click events but keep the visual style
                        }}
                      >
                        {completedSteps[selectedItem.data.step.id] ? 'Step Completed' : 'Complete Step'}
                      </Button>
                    </span>

                  </Tooltip>
                )}
        </Box>

        {/* Description */}
        <Typography variant="body1" paragraph>
          {getSelectedItemDescription()}
        </Typography>

        {/* Resource Form */}
        {selectedItem && getSelectedItemApiResource() && (
          <ResourceForm
            model={data?.model}
            resourceName={getSelectedItemApiResource()}
            instance_id={instance_id}
            actions={getSelectedItemActions()}
            organization={organization}
          />
        )}


      </Box>
    </Box>
  );
};

export default MultiProcess;
