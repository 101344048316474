import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useRequestCompliance from '../../../hooks/useRequestCompliance';
import ButtonMenu from '../../../components/buttonMenu';
import { CardActionArea, Box, Button, Dialog, DialogTitle, DialogActions, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import AssessmentIcon from '@mui/icons-material/Assessment';

export default function OutlinedCard() {
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { getComplianceList, complianceList, deleteResource } = useRequestCompliance({ endpoint: 'risk-assessment-instances', resourceLabel: 'Risk assessment instances' });
  const navigate = useNavigate();
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    getComplianceList();
  }, [getComplianceList]);

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleConfirmDelete = (id) => {
    setIdToDelete(id);
    setDeleteOpen(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClickOpen = (id) => {
    if (id) {
      navigate(`/risk/assessments/edit/${id}`);
    } else {
      navigate('/risk/assessments/add');
    }
  };

  const editClick = (id) => {
    handleClickOpen(id);
  };

  const filteredCompliances = complianceList.results?.filter((compliance) => {
    return (
      compliance.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      compliance.description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleDelete = () => {
    setDeleteOpen(false);
    deleteResource(idToDelete);
  };

  const cardList = filteredCompliances.map((item) => (
    <Grid item xs={6} sm={4} key={item.id}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          elevation: 3,
          padding: 2,
        }}
      >
        <CardActionArea onClick={() => navigate(`/risk/assessments/process/${item.id}`)}>
          <CardContent>
            {/* Main Data */}
            <Typography variant="h3" component="div" fontWeight="bold" mt={2}>
              {item.title}
            </Typography>
            <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
              {item.description}
            </Typography>

            {/* Metadata */}
            <Box mt={2} p={1} bgcolor="background.paper" border={1} borderRadius={2} borderColor="divider">
              <Typography component="span" fontWeight="bold" sx={{ color: 'text.secondary' }}>
                Methodology:
              </Typography>
              <Typography component="span" sx={{ color: 'text.secondary' }}>
                {item.methodology}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 1, display: 'block', color: 'text.secondary' }}>
                <Typography component="span" fontWeight="bold">
                  Studied Object:
                </Typography>{' '}
                {item.scope_object || 'Not Provided'}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <ButtonMenu
            editClick={() => editClick(item.id)}
            deleteClick={() => handleConfirmDelete(item.id)}
            navigateTo={`/risk/assessments/process/${item.id}`}
            navigateLabel="Assess"
            navigateIcon={<AssessmentIcon />}
            id={item.id}
            handleMonitor={() => {
              navigate(`/risk/assessments/monitor/${item.id}`);
            }}
            // Additional properties, adapt as required
          />
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <div>
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Are you sure you want to delete this assessment instance?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleDeleteClose}>No</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!selectedData} onClose={() => setSelectedData(null)}>
        <DialogTitle>{selectedData?.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Description Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Description:
              </Typography>
              <Typography paragraph>{selectedData?.description}</Typography>
              <Divider />
            </Grid>
            {/* Purpose Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Purpose:
              </Typography>
              <Typography paragraph>{selectedData?.purpose}</Typography>
              <Divider />
            </Grid>
            {/* Assumptions Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Assumptions:
              </Typography>
              <Typography paragraph>{selectedData?.assumptions}</Typography>
              <Divider />
            </Grid>
            {/* Methodology Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Methodology:
              </Typography>
              <Typography paragraph>{selectedData?.methodology}</Typography>
              <Divider />
            </Grid>
            {/* Scope Object Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Scope Object:
              </Typography>
              <Typography paragraph>{selectedData?.scope_object || 'Not Provided'}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedData(null)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid container direction="column" spacing={2} mb={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Typography variant="h5" gutterBottom>
            Risk Assessment Instances
          </Typography>
          <Button variant="outlined" onClick={() => handleClickOpen(null)} startIcon={<AddIcon />}>
            Add risk assessment
          </Button>
        </Grid>

        <Grid item container justifyContent="center">
          <Grid item xs={12}>
            <TextField
              type="search"
              id="search"
              label="Search"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          {filteredCompliances.length > 0 ? (
            cardList
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color="text.secondary">
                No risk assessment instances available. Please add a new assessment.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
