import React, { useState } from 'react';
import { Box, TextField, MenuItem, IconButton, Button, Chip, ToggleButtonGroup, ToggleButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';

const KeyValueFilter = ({
  tagFilters,
  filterOperators,
  uniqueTagKeys,
  handleTagFilterChange,
  handleOperatorChange,
  handleAddTagFilter,
  handleRemoveTagFilter,
}) => {
  const [editingIndex, setEditingIndex] = useState(null); // Index of the currently edited filter

  const handleEditTagFilter = (index) => {
    setEditingIndex(index);
  };

  const handleSaveEdit = () => {
    setEditingIndex(null);
  };

  const handleAddAndEditTagFilter = () => {
    handleAddTagFilter(); // Add the new tag filter
    setEditingIndex(tagFilters.length); // Immediately set the new filter as editable
  };

  return (
    <Box sx={{ mb: 2 }}>
      {/* Add filter button, styled like Edit Standard */}
      <Button
        variant="text"
        startIcon={<FilterListIcon />}
        onClick={handleAddAndEditTagFilter} // Use the combined function to add and edit
        sx={{ mr: 2 }}
      >
        Add TAG Filter
      </Button>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {tagFilters.map((filter, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Show text fields for editing or a Chip for displaying the key-value */}
            {editingIndex === index ? (
              <>
                {/* Dropdown to select key */}
                <TextField
                  select
                  label="Key"
                  value={filter.key || ''}  // Ensure it is not undefined
                  onChange={(e) => handleTagFilterChange(index, 'key', e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{ mr: 2, minWidth: '120px' }}
                >
                  {uniqueTagKeys.map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Input to enter value */}
                <TextField
                  label="Value"
                  value={filter.value || ''}  // Ensure it is not undefined
                  onChange={(e) => handleTagFilterChange(index, 'value', e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{ mr: 2, minWidth: '120px' }}
                />

                {/* Save with + IconButton */}
                <IconButton
                  onClick={handleSaveEdit}
                  color="primary"
                  size="small"
                  sx={{ mr: 2 }}
                >
                  <AddIcon />
                </IconButton>

                {/* Delete button */}
                <IconButton onClick={() => handleRemoveTagFilter(index)} color="error" size="small">
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <>
                {/* Show filter as a Chip when not in edit mode */}
                <Chip
                  label={`${filter.key}: ${filter.value}`}
                  sx={{ mr: 2 }}
                  onDelete={() => handleRemoveTagFilter(index)} // Enable deletion via Chip
                  deleteIcon={<DeleteIcon />}
                />

                {/* Edit button */}
                <IconButton
                  onClick={() => handleEditTagFilter(index)}
                  color="primary"
                  size="small"
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </>
            )}

            {/* AND/OR toggle button between filters */}
            {index < tagFilters.length - 1 && (
              <ToggleButtonGroup
                value={filterOperators[index]}
                exclusive
                onChange={(_, value) => handleOperatorChange(index, value)}
                aria-label="filter operator"
                size="small"
                sx={{ ml: 2 }}
              >
                <ToggleButton value="AND">AND</ToggleButton>
                <ToggleButton value="OR">OR</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default KeyValueFilter;
