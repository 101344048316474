import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useRequestCompliance from '../../../hooks/useRequestCompliance';
import { CardActionArea, Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ButtonMenu from 'src/components/buttonMenu';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Box, TextField } from '@mui/material';

export default function OutlinedCard() {
  const { getComplianceList, complianceList, deleteResource } = useRequestCompliance({ endpoint: 'compliance/programs', resourceLabel: 'compliance' });
  const [list, setList] = React.useState(null);
  const navigate = useNavigate();
  const handleDelete = () => {
    setDeleteOpen(false);
    deleteResource(idToDelete);
  };
  const [selectedData, setSelectedData] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const filteredCompliances = complianceList.results?.filter((compliance) => {
    return (
      compliance.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      compliance.description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  useEffect(() => {
    getComplianceList();
  }, [getComplianceList]);

  useEffect(() => {
    setList(complianceList);
  }, [complianceList]);

  const [idToDelete, setIdToDelete] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleConfirmDelete = (id) => {
    setIdToDelete(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const editClick = (id) => {
    handleClickOpen(id);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClickOpen = (id) => {
    if (id) {
      navigate(`/compliance/programs/edit/${id}`);
    } else {
      navigate('/compliance/programs/add');
    }
  };

  const cardList = filteredCompliances.map((item) => (
    <Grid item xs={6} sm={4} key={item.id}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          elevation: 3,
          padding: 2,
        }}
      >
        <CardActionArea onClick={() => setSelectedData(item)}>
          <CardContent>
            {/* Main Data */}
            <Typography variant="h3" component="div" fontWeight="bold" mt={2}>
              {item.title}
            </Typography>
            <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
              {item.description}
            </Typography>

            {/* Metadata */}
            <Box mt={2} p={1} bgcolor="background.paper" border={1} borderRadius={2} borderColor="divider">
              <Typography component="span" fontWeight="bold" sx={{ color: 'text.secondary' }}>
                Standards:
              </Typography>
              {/* Display each standard title on a new line */}
              {item.standards_titles.map((title, index) => (
                <Typography key={index} component="div" sx={{ color: 'text.secondary' }}>
                  - {title}
                </Typography>
              ))}
              <Typography variant="subtitle2" sx={{ mt: 1, display: 'block', color: 'text.secondary' }}>
                <Typography component="span" fontWeight="bold">
                  Start date:
                </Typography>{' '}
                {item.start_date || 'Not Provided'}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 1, display: 'block', color: 'text.secondary' }}>
                <Typography component="span" fontWeight="bold">
                  End date:
                </Typography>{' '}
                {item.end_date || 'Not Provided'}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <ButtonMenu
            editClick={() => editClick(item.id)}
            deleteClick={() => handleConfirmDelete(item.id)}
            navigateTo={`/compliance/programs/detail/${item.id}`}
            navigateLabel="Detail"
            navigateIcon={<AssessmentIcon />}
            id={item.id}
            // Additional properties, adapt as required
          />
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <div>
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Are you sure you want to delete this assessment instance?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleDeleteClose}>No</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!selectedData} onClose={() => setSelectedData(null)}>
        <DialogTitle>{selectedData?.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Description Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Description:
              </Typography>
              <Typography paragraph>{selectedData?.description}</Typography>
              <Divider />
            </Grid>
            {/* Purpose Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Standards:
              </Typography>
              {/* List each standard */}
              {selectedData?.standards_titles.map((title, index) => (
                <Typography key={index} paragraph>
                  - {title}
                </Typography>
              ))}
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedData(null)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" gutterBottom>
          Compliance programs
        </Typography>
        <Button variant="outlined" onClick={() => handleClickOpen(null)} startIcon={<AddIcon />}>
          Add program
        </Button>
      </Grid>

      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item sm={12}>
          <TextField
            type="search"
            id="search"
            label="Search"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {filteredCompliances.length > 0 ? (
          cardList
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" align="center" color="text.secondary">
              No compliance programs available. Please add a new program.
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
