import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}))

export default function CustomizedMenus (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDelete = () => {
    props.deleteClick(props.id)
    setAnchorEl(null)
  }
  const handleEdit = () => {
    props.editClick(props.id)
    setAnchorEl(null)
  }
  const handleDetail = () => {
    props.handleDetail(props.id)
    setAnchorEl(null)
  }
  const handleCustomNavigation = () => {
    if (props.navigateTo) {
      navigate(props.navigateTo)
    }
    setAnchorEl(null)
  }


  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
      >
        Options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
                        {props.rescan && (
        <MenuItem onClick={props.rescan} disableRipple> {/* Add the Monitor menu item */}
          <RestartAltIcon style={{ marginRight: '8px' }} />
          Rescan
        </MenuItem>)}
                {props.handleMonitor && (
        <MenuItem onClick={props.handleMonitor} disableRipple> {/* Add the Monitor menu item */}
          <VisibilityIcon style={{ marginRight: '8px' }} />
          Monitor
        </MenuItem>
                )}
                {props.handleDetail && (
        <MenuItem onClick={handleDetail} disableRipple> {/* Add the Monitor menu item */}
          <VisibilityIcon style={{ marginRight: '8px' }} />
          Detail
        </MenuItem>
                )}
                                {props.handleReport && (
        <MenuItem onClick={props.handleReport} disableRipple> {/* Add the Monitor menu item */}
          <SummarizeIcon style={{ marginRight: '8px' }} />
          Request report
        </MenuItem>
                )}
        <MenuItem onClick={handleEdit} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        
        {/* Custom Navigation Option */}
        {props.navigateTo && (
          <MenuItem onClick={handleCustomNavigation} disableRipple>
            {props.navigateIcon ? React.cloneElement(props.navigateIcon, { style: { marginRight: '8px' } }) : null}
            {props.navigateLabel || 'Navigate'}
          </MenuItem>
        )}
        <MenuItem onClick={handleDelete} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem>
      </StyledMenu>
    </div>
  )
}
