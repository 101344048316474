import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import * as echarts from 'echarts/core';
import {
  GraphChart,
  HeatmapChart,
} from 'echarts/charts';
import {
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  GridComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactECharts from 'echarts-for-react';
import Select from 'react-select';
import {
  Box,
  Avatar,
  Tabs,
  Tab,
} from '@mui/material';

// Register the required ECharts components
echarts.use([
  GraphChart,
  HeatmapChart,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  GridComponent,
  CanvasRenderer,
]);

const StandardsGraph = ({ standards, controls, subControls }) => {
  const theme = useTheme();
  const [graphOption, setGraphOption] = useState({});
  const [heatmapOption, setHeatmapOption] = useState({});
  const [selectedStandards, setSelectedStandards] = useState(
    standards ? standards.map((s) => s.id) : []
  ); // Initially select all standards if available
  const [tabValue, setTabValue] = useState(0); // State to control the active tab

  // Function to truncate long titles
  const truncateTitle = (title, length = 20) => {
    return title && title.length > length ? title.slice(0, length) + '...' : title;
  };

  useEffect(() => {
    if (!standards || !controls || !subControls) {
      return;
    }

    const filteredStandards = standards.filter((s) => selectedStandards.includes(s.id));

    // Map controls and sub-controls for quick access
    const controlsMap = new Map(controls.map((control) => [control.id, control]));
    const subControlsMap = new Map(subControls.map((subControl) => [subControl.id, subControl]));

    // Prepare the set of all unique sub-controls for each standard
    const standardSubControlsMap = new Map();

    filteredStandards.forEach((standard) => {
      const subControlIds = new Set();

      // For each control in the standard, collect its sub-controls
      standard.controls.forEach((controlId) => {
        const control = controlsMap.get(controlId);
        if (control && control.subControls) {
          control.subControls.forEach((subControlId) => {
            subControlIds.add(subControlId); // Ensures uniqueness
          });
        }
      });

      standardSubControlsMap.set(standard.id, subControlIds);
    });

    // Compute common sub-controls between standards
    const mappings = [];
    const standardIds = filteredStandards.map((s) => s.id);

    for (let i = 0; i < standardIds.length; i++) {
      for (let j = i + 1; j < standardIds.length; j++) {
        const s1Id = standardIds[i];
        const s2Id = standardIds[j];
        const s1 = standards.find((s) => s.id === s1Id);
        const s2 = standards.find((s) => s.id === s2Id);

        const s1SubControls = standardSubControlsMap.get(s1Id);
        const s2SubControls = standardSubControlsMap.get(s2Id);

        // Compute intersection of sub-control IDs
        const commonSubControls = new Set(
          [...s1SubControls].filter((id) => s2SubControls.has(id))
        );
        const commonSubControlsCount = commonSubControls.size;

        mappings.push({
          firstStandard: s1.title,
          secondStandard: s2.title,
          commonSubControlsCount,
        });
      }
    }

    // Prepare data for the graph visualization
    // Prepare nodes and links for the graph
    const linkedControlIds = new Set(filteredStandards.flatMap((s) => s.controls));

    // Filter controls based on the linked control ids
    const filteredControls = controls.filter((c) => linkedControlIds.has(c.id));

    // Get the ids of the sub-controls that are linked to the filtered controls
    const linkedSubControlIds = new Set(filteredControls.flatMap((c) => c.subControls));

    // Filter sub-controls based on the linked sub-control ids
    const filteredSubControls = subControls.filter((sc) => linkedSubControlIds.has(sc.id));

    // Handle case when no standards are selected for the graph
    if (filteredStandards.length === 0) {
      setGraphOption({
        title: {
          text: 'No standards selected',
          left: 'center',
          top: 'middle',
          textStyle: {
            fontSize: 20,
            fontWeight: 'normal',
          },
        },
      });
    } else {
      const nodes = [
        ...filteredStandards.map((s) => ({
          id: `standard_${s.id}`,
          name: truncateTitle(s.title),
          fullName: s.title,
          description: s.description,
          category: 0,
          symbolSize: 50,
        })),
        ...filteredControls.map((c) => ({
          id: `control_${c.id}`,
          name: truncateTitle(c.title),
          fullName: c.title,
          description: c.description,
          category: 1,
          symbolSize: 40,
        })),
        ...filteredSubControls.map((sc) => ({
          id: `subControl_${sc.id}`,
          name: truncateTitle(sc.title),
          fullName: sc.title,
          description: sc.description,
          category: 2,
          symbolSize: 30,
        })),
      ];

      const links = [
        ...filteredStandards.flatMap((s) =>
          s.controls.map((cId) => ({
            source: `standard_${s.id}`,
            target: `control_${cId}`,
          }))
        ),
        ...filteredControls.flatMap((c) =>
          c.subControls.map((scId) => ({
            source: `control_${c.id}`,
            target: `subControl_${scId}`,
          }))
        ),
      ];

      // Configure the chart options for the graph
      setGraphOption({
        title: {
          text: 'Standards, Controls, and SubControls Mapping',
          top: 'bottom',
          left: 'right',
        },
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'node') {
              return `<div style="max-width: 300px; white-space: normal;"><b>${params.data.fullName}</b><br>${params.data.description}</div>`;
            }
            if (params.dataType === 'edge') {
              return `${params.data.source} > ${params.data.target}`;
            }
          },
          extraCssText: 'max-width: 300px; white-space: normal;', // Ensure the tooltip does not exceed the div
        },
        legend: {
          data: ['Standard', 'Control', 'SubControl'],
          left: 'left',
        },
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'force',
            data: nodes,
            links: links,
            categories: [
              { name: 'Standard' },
              { name: 'Control' },
              { name: 'SubControl' },
            ],
            roam: true,
            label: {
              position: 'right',
              formatter: '{b}',
              show: true,
            },
            lineStyle: {
              color: 'source',
              curveness: 0.3,
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 10,
              },
            },
            force: {
              repulsion: 1000,
              edgeLength: [100, 200],
            },
            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [4, 10],
            focusNodeAdjacency: true, // Highlight adjacent nodes and edges on hover
          },
        ],
      });
    }

    // Prepare data for the heatmap visualization
    const data = [];
    const xAxisData = filteredStandards.map((s) => s.title);
    const yAxisData = filteredStandards.map((s) => s.title);

    for (let i = 0; i < filteredStandards.length; i++) {
      for (let j = 0; j < filteredStandards.length; j++) {
        const s1Id = filteredStandards[i].id;
        const s2Id = filteredStandards[j].id;

        const s1SubControls = standardSubControlsMap.get(s1Id);
        const s2SubControls = standardSubControlsMap.get(s2Id);

        // Compute intersection of sub-control IDs
        const commonSubControls = new Set(
          [...s1SubControls].filter((id) => s2SubControls.has(id))
        );
        const commonSubControlsCount = commonSubControls.size;

        data.push([j, i, commonSubControlsCount]);
      }
    }

    // Handle case when no standards are selected for the heatmap
    if (filteredStandards.length === 0 || data.length === 0) {
      setHeatmapOption({
        title: {
          text: 'No standards selected',
          left: 'center',
          top: 'middle',
          textStyle: {
            fontSize: 20,
            fontWeight: 'normal',
          },
        },
      });
    } else {
      const maxDataValue = Math.max(...data.map((item) => item[2]));
      // Ensure maxDataValue is valid
      const validMaxDataValue = isFinite(maxDataValue) && maxDataValue > 0 ? maxDataValue : 1;

      // Configure the chart options for the heatmap
      setHeatmapOption({
        tooltip: {
          position: 'top',
          formatter: function (params) {
            const xIndex = params.data[0];
            const yIndex = params.data[1];
            const value = params.data[2];
            return `${yAxisData[yIndex]} & ${xAxisData[xIndex]}: ${value} common sub-controls`;
          },
        },
        grid: {
          height: '70%',
          top: '10%',
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: 'category',
          data: yAxisData,
          axisLabel: {
            interval: 0,
          },
          splitArea: {
            show: true,
          },
        },
        visualMap: {
          min: 0,
          max: validMaxDataValue,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '5%',
          inRange: {
            color: ['#e0ffff', '#006edd'], // Customize color range
          },
        },
        series: [
          {
            name: 'Common Sub-Controls',
            type: 'heatmap',
            data: data,
            label: {
              show: true,
            },
            emphasis: {
              itemStyle: {
                borderColor: '#333',
                borderWidth: 1,
              },
            },
          },
        ],
      });
    }
  }, [standards, controls, subControls, selectedStandards, theme]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedStandards(selectedOptions ? selectedOptions.map((option) => option.value) : []);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const selectOptions = standards
    ? standards.map((item) => ({
        value: item.id,
        label: item.title,
        image: item.image_url,
      }))
    : [];

  const formatOptionLabel = ({ label, image }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {image ? (
        <img src={image} alt={label} style={{ width: 24, height: 24, marginRight: 2 }} />
      ) : (
        <Avatar sx={{ width: 24, height: 24, marginRight: 2 }}>{label.charAt(0)}</Avatar>
      )}
      {label}
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
  };

  if (!standards || !controls || !subControls) {
    return <div>No data available</div>;
  }

  return (
    <div style={{ height: '85vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mb: 2, width: '100%' }}>
        <Select
          options={selectOptions}
          onChange={handleSelectChange}
          isMulti
          value={selectOptions.filter((option) => selectedStandards.includes(option.value))}
          formatOptionLabel={formatOptionLabel}
          styles={customStyles}
        />
      </Box>

      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Graph" />
        <Tab label="Heatmap" />
      </Tabs>

      <Box sx={{ flex: 1, border: '1px solid #ccc', borderRadius: '8px', p: 1 }}>
        {tabValue === 0 && (
          <ReactECharts
            option={graphOption}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '100%', width: '100%' }}
          />
        )}
        {tabValue === 1 && (
          <ReactECharts
            option={heatmapOption}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '100%', width: '100%' }}
          />
        )}
      </Box>
    </div>
  );
};

export default StandardsGraph;
