import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Link,
  useTheme,
  Tabs,
  Tab,
  Chip,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { useParams } from 'react-router-dom';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import DynamicTable from 'src/components/General/DynamicTable';
import PropTypes from 'prop-types';

// TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Accessibility props
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const IntegrationDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  
  // Fetch integration details
  const { getCompliance, compliance: integration, error } = useRequestCompliance({ endpoint: 'integrations' });
  
  // Fetch primary and supporting assets
  const { getComplianceList: getPrimaryAssets, complianceList: primaryAssetsData } = useRequestCompliance({ endpoint: 'primary_assets' });
  const { getComplianceList: getSupportingAssets, complianceList: supportingAssetsData } = useRequestCompliance({ endpoint: 'supporting_assets' });

  const [primaryAssets, setPrimaryAssets] = useState([]);
  const [supportingAssets, setSupportingAssets] = useState([]);
  
  useEffect(() => {
    if (id) {
      getCompliance(id);
    }
  }, [id, getCompliance]);

  useEffect(() => {
    // Fetch supporting assets if IDs are available
    if (integration?.supporting_assets?.length > 0) {
      getSupportingAssets({ integration: integration.id });
    }
  }, [integration?.supporting_assets, getSupportingAssets, integration?.id]);

  useEffect(() => {
    // Fetch primary assets if IDs are available
    if (integration?.primary_assets?.length > 0) {
      getPrimaryAssets({ integration: integration.id });
    }
  }, [integration?.primary_assets, getPrimaryAssets, integration?.id]);

  useEffect(() => {
    if (primaryAssetsData?.results) {
      setPrimaryAssets(primaryAssetsData.results);
    }
  }, [primaryAssetsData]);

  useEffect(() => {
    if (supportingAssetsData?.results) {
      setSupportingAssets(supportingAssetsData.results);
    }
  }, [supportingAssetsData]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (error) {
    return <Typography variant="body1" color="error">Error loading integration details: {error.message}</Typography>;
  }

  if (!integration) {
    return <CircularProgress />;
  }

  const getStatusChipColor = (status) => {
    switch (status) {
      case 'active':
        return theme.palette.success.main;
      case 'failed':
        return theme.palette.error.main;
      default:
        return theme.palette.text.primary;
    }
  };

  const renderBasicInformation = () => (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Provider:</Typography>
          <Typography variant="body1">{integration.provider}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Status:</Typography>
          <Box display="flex" alignItems="center">
            {integration.status === 'syncing' ? (
              <CircularProgress size={12} sx={{ mr: 1 }} />
            ) : (
              <Chip
                icon={<div style={{ borderRadius: '50%', width: '16px', height: '16px', backgroundColor: getStatusChipColor(integration.status) }} />}
                label={integration.status}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Information System:</Typography>
          <Typography variant="body1">{integration.information_system?.title}</Typography>
        </Grid>
        {integration.github_organization && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>GitHub Organization:</Typography>
              <Typography variant="body1">{integration.github_organization}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Branch:</Typography>
              <Typography variant="body1">{integration.branch}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Tag:</Typography>
              <Typography variant="body1">{integration.tag}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Commit:</Typography>
              <Typography variant="body1">{integration.commit}</Typography>
            </Grid>
          </>
        )}
        {integration.kubernetes_cluster_url && (
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Kubernetes Cluster URL:</Typography>
            <Link href={integration.kubernetes_cluster_url} target="_blank" rel="noopener">{integration.kubernetes_cluster_url}</Link>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  // Define columns for Primary Assets and Supporting Assets
  const assetColumns = [
    { accessorKey: 'title', header: 'Title' },
    { accessorKey: 'description', header: 'Description' },
    { accessorKey: 'created_by', header: 'Created By' },
  ];

  const renderSupportingAssets = () => (
    <Box>
      <Typography variant="h6">Supporting Assets</Typography>
      {supportingAssets?.length > 0 ? (
        <DynamicTable
          complianceList={supportingAssets}
          currentTab={{ name: 'Supporting Assets', endpoint: 'supporting_assets' }}
          hideActionsColumn={true}
          columns={assetColumns}
        />
      ) : (
        <Typography variant="body1">No supporting assets available.</Typography>
      )}
    </Box>
  );

  const renderPrimaryAssets = () => (
    <Box>
      <Typography variant="h6">Primary Assets</Typography>
      {primaryAssets?.length > 0 ? (
        <DynamicTable
          complianceList={primaryAssets}
          currentTab={{ name: 'Primary Assets', endpoint: 'primary_assets' }}
          hideActionsColumn={true}
          columns={assetColumns}
        />
      ) : (
        <Typography variant="body1">No primary assets available.</Typography>
      )}
    </Box>
  );

  const renderLogs = () => {
    const getLogColor = (level) => {
      switch (level) {
        case 'info':
          return theme.palette.success.main;
        case 'warning':
          return theme.palette.warning.main;
        case 'error':
          return theme.palette.error.main;
        default:
          return theme.palette.text.primary;
      }
    };

    return (
      <Box sx={{ backgroundColor: 'black', color: 'white', borderRadius: 2, overflow: 'auto' }}>
        <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Logs</Typography>
        {integration.logs?.length > 0 ? (
          <List sx={{ overflow: 'auto', flexGrow: 1 }}>
            {integration.logs.map((log, index) => (
              <ListItem key={index} sx={{ py: 0.5 }}>
                <ListItemText
                  primary={<Typography sx={{ color: getLogColor(log.level), fontFamily: 'monospace' }}>{`${log.timestamp} - ${log.level.toUpperCase()}`}</Typography>}
                  secondary={<Typography sx={{ color: 'white', fontFamily: 'monospace' }}>{log.message}</Typography>}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" sx={{ color: 'white' }}>No logs available.</Typography>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="Integration Details Tabs"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          minWidth: '150px',
          maxWidth: '200px',
          height: '100%',
        }}
      >
        <Tab label="Basic Information" {...a11yProps(0)} />
        <Tab label="Supporting Assets" {...a11yProps(1)} />
        <Tab label="Primary Assets" {...a11yProps(2)} />
        <Tab label="Logs" {...a11yProps(3)} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        {renderBasicInformation()}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {renderSupportingAssets()}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        {renderPrimaryAssets()}
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        {renderLogs()}
      </TabPanel>
    </Box>
  );
};

export default IntegrationDetails;
