import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const BreadcrumbsNav = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: 2 }}>
      <Link component={RouterLink} to="/">
        Home
      </Link>
      {pathnames.map((value, index) => {
        const first = index === 0;
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isSpecialPath = ['add', 'detail', 'edit','monitor','models',"Supporting_assets",'process'].some(keyword => value.includes(keyword));

        if (first || last || isSpecialPath) {
          return (
            <Typography color="text.primary" key={to}>
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </Typography>
          );
        } else {
          return (
            <Link component={RouterLink} to={to} key={to}>
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;
