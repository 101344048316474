import React from 'react';
import CreatableSelect from 'react-select/creatable';

const CreatableSingleSelectWidget = ({ value, onChange, schema }) => {
  // Use suggested values for providing options, if available
  const selectOptions = schema.suggested_values
    ? schema.suggested_values.map((item) => ({ value: item, label: item }))
    : [];

  // Function to handle selection changes
  const handleChange = (selectedOption) => {
    if (selectedOption) {
      onChange(selectedOption.value);
    } else {
      onChange(''); // Set to empty if no option is selected
    }
  };

  return (
    <CreatableSelect
      isClearable
      value={value ? { value: value, label: value } : null}
      options={selectOptions}
      onChange={handleChange}
      placeholder={schema.description}
      createOptionPosition="first"
      menuPortalTarget={document.body}  // Make the dropdown render at the root level to avoid z-index and overflow issues
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),  // Set a very high z-index for the dropdown menu
      }}
    />
  );
};

export default CreatableSingleSelectWidget;
