import React, { useEffect, useState, useCallback } from 'react';
import {
  Typography, TextField, Button, Box, Grid, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stepper, Step, StepLabel, Alert, MenuItem, Paper
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import Editor from '@monaco-editor/react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MRTcustomizedOriginal from 'src/components/BaseLayout/MRTcustomizedOriginal';
import CustomFieldTemplate from 'src/components/BaseLayout/CustomFieldTemplate';

const animatedComponents = makeAnimated();

const assessmentParameterOptions = [
  { value: 'characteristics', label: 'Characteristics' },
  { value: 'relevance', label: 'Relevance' },
  { value: 'occurrence_likelihood', label: 'Occurrence Likelihood' },
  { value: 'initiation_likelihood', label: 'Initiation Likelihood' },
  { value: 'resulting_likelihood', label: 'Resulting Likelihood' },
  { value: 'overall_likelihood', label: 'Overall Likelihood' },
  { value: 'likelihood', label: 'Likelihood' },
  { value: 'overall_impact', label: 'Overall Impact' },
  { value: 'impact', label: 'Impact' },
  { value: 'risk', label: 'Risk' },
  { value: 'level', label: 'Level' },
  { value: 'type', label: 'Type' },
  { value: 'pervasiveness', label: 'Pervasiveness' },
  { value: 'threat_level', label: 'Threat Level' },
  { value: 'residual_overall_likelihood', label: 'Residual Overall Likelihood' },
  { value: 'residual_likelihood', label: 'Residual Likelihood' },
  { value: 'residual_impact', label: 'Residual Impact' },
  { value: 'residual_overall_impact', label: 'Residual Overall Impact' },
  { value: 'residual_risk', label: 'Residual Risk' },
  { value: 'residual_level', label: 'Residual Level' },
  { value: 'residual_relevance', label: 'Residual Relevance' },
  { value: 'residual_occurrence_likelihood', label: 'Residual Occurrence Likelihood' },
  { value: 'residual_resulting_likelihood', label: 'Residual Resulting Likelihood' },
  { value: 'residual_pervasiveness', label: 'Residual Pervasiveness' },
  { value: 'residual_characteristics', label: 'Residual Characteristics' },
  { value: 'residual_threat_level', label: 'Residual Threat Level' },
  { value: 'residual_overall_threat_level', label: 'Residual Overall Threat Level' },
  { value: 'roles', label: 'Roles' },
  { value: 'findings', label: 'Findings' },
  { value: 'compliance_status', label: 'Compliance Status' },
  { value: 'status', label: 'Risk status' },
  { value: 'security_requirements_detail', label: 'Security requirements' },
];

const MultiProcess = ({ id }) => {
  const { addCompliance: addModel, getCompliance: getProcess, updateCompliance: updateProcess, compliance: processData } = useRequestCompliance({ endpoint: 'processes', resourceLabel: 'process' });

  const [process, setProcess] = useState({ title: '', description: '', steps: [], model: null });
  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editType, setEditType] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    order: '',
    warning: '',
    info: '',
    alert: '',
    content_type_1: null,
    content_type_2: null,
    filter1: '',
    filter2: '',
    assessment_parameters: [],
    method_name: '',
    dependencies: []
  });
  const [errors, setErrors] = useState({});
  const { getComplianceList, complianceList: choices } = useRequestCompliance({ endpoint:'content-types' });

  useEffect(() => {
    if (id) {
      getProcess(id);
    }
  }, [id, getProcess]);
  useEffect(() => {
    getComplianceList()
  }, []);
  useEffect(() => {
    if (processData) {
      const formattedData = convertToFormData(processData);
      setProcess(formattedData);
    }
  }, [processData]);

  const handleProcessChange = (e) => {
    const { name, value } = e.target;
    setProcess((prev) => ({ ...prev, [name]: value }));
  };

  const handleModelChange = useCallback((value) => {
    setProcess((prev) => {
      if (prev.model?.value !== value) {
        return { ...prev, model: value };
      }
      return prev;
    });
  }, []);

  const handleAddStep = () => {
    const newStep = {  title: '', description: '', order: '', dependencies: [], tasks: [] };
    setProcess((prev) => ({ ...prev, steps: [...prev.steps, newStep] }));
    setActiveTab(process.steps.length); // Set the new tab as active
  };

  const handleAddTask = (stepId) => {
    const newTask = {  title: '', description: '', order: '', dependencies: [], actions: [] };
    setProcess((prev) => ({
      ...prev,
      steps: prev.steps.map((step) =>
        step.id === stepId ? { ...step, tasks: [...step.tasks, newTask] } : step
      )
    }));
  };

  const handleAddAction = (stepId, taskId) => {
    const newAction = {
      title: '',
      description: '',
      order: '',
      warning: '',
      info: '',
      alert: '',
      content_type_1: null,
      content_type_2: null,
      filter1: '',
      filter2: '',
      assessment_parameters: [],
      method_name: ''
    };
    setProcess((prev) => ({
      ...prev,
      steps: prev.steps.map((step) =>
        step.id === stepId
          ? {
              ...step,
              tasks: step.tasks.map((task) =>
                task.id === taskId ? { ...task, actions: [...task.actions, newAction] } : task
              )
            }
          : step
      )
    }));
  };

  const handleRemoveStep = (stepId) => {
    setProcess((prev) => ({ ...prev, steps: prev.steps.filter((step) => step.id !== stepId) }));
  };

  const handleRemoveTask = (stepId, taskId) => {
    setProcess((prev) => ({
      ...prev,
      steps: prev.steps.map((step) =>
        step.id === stepId ? { ...step, tasks: step.tasks.filter((task) => task.id !== taskId) } : step
      )
    }));
  };

  const handleRemoveAction = (stepId, taskId, actionId) => {
    setProcess((prev) => ({
      ...prev,
      steps: prev.steps.map((step) =>
        step.id === stepId
          ? {
              ...step,
              tasks: step.tasks.map((task) =>
                task.id === taskId ? { ...task, actions: task.actions.filter((action) => action.id !== actionId) } : task
              )
            }
          : step
      )
    }));
  };

    const openDialog = (type, step, task, action) => {
      setEditType(type);
      setCurrentStep(step);
      setCurrentTask(task);
      setCurrentAction(action);
    
      if (type === 'step') {
        setFormData({
          id: step.id, // Include step ID here
          title: step.title,
          description: step.description,
          order: step.order,
          dependencies: step.dependencies
        });
      } else if (type === 'task') {
        setFormData({
          id: task.id, // Include task ID here
          title: task.title,
          description: task.description,
          order: task.order,
          dependencies: task.dependencies
        });
      } else if (type === 'action') {
        setFormData({
          id: action.id, // Include action ID here
          title: action.title,
          description: action.description,
          order: action.order,
          warning: action.warning,
          info: action.info,
          alert: action.alert,
          content_type_1: action.content_type_1 || null,
          content_type_2: action.content_type_2 || null,
          filter1: action.filter1,
          filter2: action.filter2,
          assessment_parameters: action.assessment_parameters,
          method_name: action.method_name
        });
      }
    
      setDialogOpen(true);
    };
  

  const handleDialogClose = () => {
    setDialogOpen(false);
    setFormData({
      title: '',
      description: '',
      order: '',
      warning: '',
      info: '',
      alert: '',
      content_type_1: null,
      content_type_2: null,
      filter1: '',
      filter2: '',
      assessment_parameters: [],
      method_name: '',
      dependencies: []
    });
  };

  const handleDialogSave = () => {
    if (editType === 'step') {
      setProcess((prev) => ({
        ...prev,
        steps: prev.steps.map((step) =>
          step.id === currentStep.id ? { ...step, ...formData, id: step.id || undefined } : step
        )
      }));
    } else if (editType === 'task') {
      setProcess((prev) => ({
        ...prev,
        steps: prev.steps.map((step) =>
          step.id === currentStep.id
            ? {
                ...step,
                tasks: step.tasks.map((task) =>
                  task.id === currentTask.id ? { ...task, ...formData, id: task.id || undefined } : task
                )
              }
            : step
        )
      }));
    } else if (editType === 'action') {
      setProcess((prev) => ({
        ...prev,
        steps: prev.steps.map((step) =>
          step.id === currentStep.id
            ? {
                ...step,
                tasks: step.tasks.map((task) =>
                  task.id === currentTask.id
                    ? {
                        ...task,
                        actions: task.actions.map((action) =>
                          action.id === currentAction.id
                            ? { ...action, ...formData, id: action.id || undefined }
                            : action
                        )
                      }
                    : task
                )
              }
            : step
        )
      }));
    }
  
    handleDialogClose();
  };
  

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange1 = useCallback(( value) => {
    setFormData((prev) => ({ ...prev, content_type_1: value }));
  }, []);
  const handleSelectChange2 = useCallback(( value) => {
    setFormData((prev) => ({ ...prev, content_type_2: value }));
  }, []);

  const handleDependenciesChange = (selectedOptions) => {
    const newValue = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData((prev) => ({ ...prev, dependencies: newValue }));
  };

// Function to handle changes in assessment parameters
const handleAssessmentParametersChange = (selectedOptions) => {
  // Map only the values from selected options
  const parameterValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
  setFormData((prev) => ({ ...prev, assessment_parameters: parameterValues }));
};

  const handleNext = () => {
    const errors = validateProcess();
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    const errors = validateProcess();
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      const jsonData = convertToJSONData(process);
      if (id) {
        updateProcess(id, jsonData);
      } else {
        addModel(jsonData);
      }
    }
  };

  const validateProcess = () => {
    const errors = {};
    if (activeStep === 0) {
      if (!process.title) {
        errors.title = 'Process title is required';
      }
      if (!process.description) {
        errors.description = 'Process description is required';
      }
      if (!process.model) {
        errors.model = 'Process model is required';
      }
    } else if (activeStep === 1) {
      if (process.steps.length === 0) {
        errors.steps = 'At least one step is required';
      }
      process.steps.forEach((step, stepIndex) => {
        if (!step.title) {
          errors[`step-${stepIndex}-title`] = 'Step title is required';
        }
        if (step.tasks.length === 0) {
          errors[`step-${stepIndex}-tasks`] = 'At least one task is required in each step';
        }
        step.tasks.forEach((task, taskIndex) => {
          if (!task.title) {
            errors[`task-${stepIndex}-${taskIndex}-title`] = 'Task title is required';
          }
          if (task.actions.length === 0) {
            errors[`task-${stepIndex}-${taskIndex}-actions`] = 'At least one action is required in each task';
          }
          task.actions.forEach((action, actionIndex) => {
            if (!action.title) {
              errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-title`] = 'Action title is required';
            }
            if (!action.content_type_1) {
              errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-content_type_1`] = 'Content Type 1 is required';
            }
            if (!action.method_name) {
              errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-method_name`] = 'Method name is required';
            }
          });
        });
      });
    }
    return errors;
  };

  const getStepOptions = () => {
    return process.steps.map(step => ({ value: step.title, label: step.title }));
  };

  const getTaskOptions = () => {
    return process.steps.flatMap(step =>
      step.tasks.map(task => ({ value: task.title, label: task.title }))
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleJSONChange = (value) => {
    try {
      const updatedProcess = JSON.parse(value);
      const formattedData = convertToFormData(updatedProcess);
      setProcess(formattedData);
    } catch (error) {
      console.error('Invalid JSON format:', error);
    }
  };

  const convertToFormData = (data) => {
    const steps = data.steps.map(step => ({
      ...step,
      tasks: step.tasks.map(task => ({
        ...task,
        actions: task.actions.map(action => ({
          ...action,
          content_type_1: action.content_type_1?.id || null,
          content_type_2: action.content_type_2?.id || null
        }))
      }))
    }));
    return { ...data, steps, model: data.model?.id || null };
  };

  const convertToJSONData = (data) => {
    const steps = data.steps.map(step => ({
      ...step,
      id: step.id || undefined, // Don't send the ID if it doesn't exist
      tasks: step.tasks.map(task => ({
        ...task,
        id: task.id || undefined, // Don't send the ID if it doesn't exist
        actions: task.actions.map(action => ({
          ...action,
          id: action.id || undefined, // Don't send the ID if it doesn't exist
          content_type_1: action.content_type_1,
          content_type_2: action.content_type_2
        }))
      }))
    }));
  
    return {
      ...data,
      steps,
      model: data.model,
    };
  };
  
  return (
    <Box sx={{ width: '100%', minHeight: '80vh', display: 'flex', flexDirection: 'column', spacing: 'space-between' }}>
      <Box sx={{ mb: 2 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key="Process Info">
            <StepLabel>Process Information</StepLabel>
          </Step>
          <Step key="Steps and Tasks">
            <StepLabel>Steps, Tasks, and Actions</StepLabel>
          </Step>
          <Step key="Review JSON">
            <StepLabel>Review JSON</StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Paper sx={{ p: 2, mb: 2 }}>

      {activeStep === 0 && (
        <Box>
          {errors.title && <Alert severity="error">{errors.title}</Alert>}
          {errors.description && <Alert severity="error">{errors.description}</Alert>}
          {errors.model && <Alert severity="error">{errors.model}</Alert>}
          <CustomFieldTemplate
            label="Process Title"
            required
            description="Enter the title of the process"
            errors={errors.title ? <Alert severity="error">{errors.title}</Alert> : null}
          >
            <TextField
              fullWidth
              name="title"
              value={process.title}
              onChange={handleProcessChange}
              required
              error={!!errors.title}
              helperText={errors.title}
            />
          </CustomFieldTemplate>
          <CustomFieldTemplate
            label="Process Description"
            required
            description="Enter a detailed description of the process"
            errors={errors.description ? <Alert severity="error">{errors.description}</Alert> : null}
          >
            <TextField
              fullWidth
              name="description"
              value={process.description}
              onChange={handleProcessChange}
              multiline
              rows={4}
              required
              error={!!errors.description}
              helperText={errors.description}
            />
          </CustomFieldTemplate>
          <CustomFieldTemplate
            label="Model"
            required
            description="Select the model for the process"
            errors={errors.model ? <Alert severity="error">{errors.model}</Alert> : null}
          >
            <MRTcustomizedOriginal
              results={choices.results}
              initialSelectedIds={process?.model}
              enableRowSelection
              enableMultiRowSelection={false}
              onSelectedIdsChange={handleModelChange}
              hideActionsColumn={true}
            />
          </CustomFieldTemplate>
        </Box>
      )}
      {activeStep === 1 && (
        <Box sx={{ width: '100%', display: 'flex', mt: 2 }}>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ borderRight: 1, borderColor: 'divider', minWidth: '200px' }}
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            {process.steps.map((step, index) => (
              <Tab key={index} label={step.title || `Step ${index + 1}`} sx={{ minHeight: 48, height: 48 }} />
            ))}
            <Tab icon={<Add />} onClick={handleAddStep} sx={{ minHeight: 48, height: 48 }} />
          </Tabs>
          {process.steps.map((step, stepIndex) => (
            <Box key={step.id} sx={{ flexGrow: 1, display: activeTab === stepIndex ? 'block' : 'none', padding: 2 }}>
              {errors[`step-${stepIndex}-title`] && <Alert severity="error">{errors[`step-${stepIndex}-title`]}</Alert>}
              {errors[`step-${stepIndex}-tasks`] && <Alert severity="error">{errors[`step-${stepIndex}-tasks`]}</Alert>}
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="h6">
                    Step: {step.title}
                    <IconButton onClick={() => handleRemoveStep(step.id)}>
                      <Delete />
                    </IconButton>
                    {errors[`step-${stepIndex}`] && <Alert severity="error" sx={{ display: 'inline', ml: 1 }}>{errors[`step-${stepIndex}`]}</Alert>}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Button variant="outlined" onClick={() => openDialog('step', step)}>
                    Edit Step
                  </Button>
                </Grid>
              </Grid>
              <List>
                {step.tasks.map((task, taskIndex) => (
                  <React.Fragment key={task.id}>
                    {errors[`task-${stepIndex}-${taskIndex}-title`] && <Alert severity="error">{errors[`task-${stepIndex}-${taskIndex}-title`]}</Alert>}
                    {errors[`task-${stepIndex}-${taskIndex}-actions`] && <Alert severity="error">{errors[`task-${stepIndex}-${taskIndex}-actions`]}</Alert>}
                    <ListItem alignItems="flex-start" sx={{ padding: 0 }}>
                      <ListItemText 
                        primary={<Typography variant="subtitle1">{task.title}</Typography>} 
                        secondary={<Typography variant="body2">{task.description}</Typography>} 
                      />
                      <IconButton onClick={() => handleRemoveTask(step.id, task.id)}>
                        <Delete />
                      </IconButton>
                      <Button onClick={() => openDialog('task', step, task)}>Edit Task</Button>
                    </ListItem>
                    <List sx={{ paddingLeft: 2 }}>
                      {task.actions.map((action, actionIndex) => (
                        <React.Fragment key={action.id}>
                          {errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-title`] && <Alert severity="error">{errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-title`]}</Alert>}
                          {errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-content_type_1`] && <Alert severity="error">{errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-content_type_1`]}</Alert>}
                          {errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-method_name`] && <Alert severity="error">{errors[`action-${stepIndex}-${taskIndex}-${actionIndex}-method_name`]}</Alert>}
                          <ListItem sx={{ padding: 0 }}>
                            <ListItemText 
                              primary={<Typography variant="body1">{action.title}</Typography>} 
                              secondary={<Typography variant="body2">{action.description}</Typography>} 
                            />
                            <IconButton onClick={() => handleRemoveAction(step.id, task.id, action.id)}>
                              <Delete />
                            </IconButton>
                            <Button onClick={() => openDialog('action', step, task, action)}>
                              Edit Action
                            </Button>
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </List>
                    <Button onClick={() => handleAddAction(step.id, task.id)}>Add Action</Button>
                    <Divider />
                  </React.Fragment>
                ))}
                <Button onClick={() => handleAddTask(step.id)}>Add Task</Button>
              </List>
            </Box>
          ))}
        </Box>
      )}
      {activeStep === 2 && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Review JSON
          </Typography>
          <Editor
            height="calc(60vh)"
            defaultLanguage="json"
            value={JSON.stringify(convertToJSONData(process), null, 2)}
            onChange={handleJSONChange}
          />
        </Box>
      )}
      </Paper>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: 'background.paper',
          p: 2,
          zIndex: 1000,
          borderTop: 1,
          borderColor: 'grey.400'
        }}
      >
        <Box sx={{ display: 'flex', mr: 2 }}>
          <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 2 }}>
            Back
          </Button>
          {activeStep === 1 || activeStep === 0? (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Finish
            </Button>
          )}
        </Box>
      </Box>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{editType === 'step' ? 'Edit Step' : editType === 'task' ? 'Edit Task' : 'Edit Action'}</DialogTitle>
        <DialogContent>
          <CustomFieldTemplate
            label="Title"
            required
            description="Enter the title"
            errors={formData.title === '' ? <Alert severity="error">Title is required</Alert> : null}
          >
            <TextField
              fullWidth
              name="title"
              value={formData.title}
              onChange={handleFormChange}
              required
            />
          </CustomFieldTemplate>
          <CustomFieldTemplate
            label="Description"
            required
            description="Enter a detailed description"
            errors={formData.description === '' ? <Alert severity="error">Description is required</Alert> : null}
          >
            <TextField
              fullWidth
              name="description"
              value={formData.description}
              onChange={handleFormChange}
              multiline
              rows={4}
              required
            />
          </CustomFieldTemplate>
          <CustomFieldTemplate
            label="Order"
            description="Enter the order number"
          >
            <TextField
              fullWidth
              name="order"
              type="number"
              value={formData.order}
              onChange={handleFormChange}
            />
          </CustomFieldTemplate>
          {(editType === 'step' || editType === 'task') && (
            <CustomFieldTemplate
              label="Dependencies"
              description="Select dependencies"
            >
              <Select
                isMulti
                components={animatedComponents}
                options={editType === 'step' ? getStepOptions() : getTaskOptions()}
                value={editType === 'step'
                  ? getStepOptions().filter(option => formData.dependencies.includes(option.value))
                  : getTaskOptions().filter(option => formData.dependencies.includes(option.value))
                }
                onChange={handleDependenciesChange}
                placeholder="Select dependencies..."
              />
            </CustomFieldTemplate>
          )}
          {editType === 'action' && (
            <>
              <CustomFieldTemplate
                label="Warning"
                description="Enter warning information"
              >
                <TextField
                  fullWidth
                  name="warning"
                  value={formData.warning}
                  onChange={handleFormChange}
                />
              </CustomFieldTemplate>
              <CustomFieldTemplate
                label="Info"
                description="Enter additional information"
              >
                <TextField
                  fullWidth
                  name="info"
                  value={formData.info}
                  onChange={handleFormChange}
                />
              </CustomFieldTemplate>
              <CustomFieldTemplate
                label="Alert"
                description="Enter alert information"
              >
                <TextField
                  fullWidth
                  name="alert"
                  value={formData.alert}
                  onChange={handleFormChange}
                />
              </CustomFieldTemplate>
              <CustomFieldTemplate
                label="Filter 1"
                description="Enter the first filter"
              >
                <TextField
                  fullWidth
                  name="filter1"
                  value={formData.filter1}
                  onChange={handleFormChange}
                />
              </CustomFieldTemplate>
              <CustomFieldTemplate
                label="Filter 2"
                description="Enter the second filter"
              >
                <TextField
                  fullWidth
                  name="filter2"
                  value={formData.filter2}
                  onChange={handleFormChange}
                />
              </CustomFieldTemplate>
              <CustomFieldTemplate
                label="Assessment Parameters"
                description="Select assessment parameters"
              >
                <Select
                  isMulti
                  components={animatedComponents}
                  options={assessmentParameterOptions}
                  // Set the value as an array of objects matching only the selected values
                  value={assessmentParameterOptions.filter(option => formData.assessment_parameters.includes(option.value))}
                  onChange={handleAssessmentParametersChange}
                  placeholder="Select assessment parameters..."
                />
              </CustomFieldTemplate>
              <CustomFieldTemplate
                label="Method Name"
                description="Select the method name"
              >
                <TextField
                  fullWidth
                  name="method_name"
                  select
                  value={formData.method_name}
                  onChange={handleFormChange}
                >
                  {[
                    'identify',
                    'determine',
                    'assess',
                    'control',
                    'develop',
                    'view',
                    'edit'
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </CustomFieldTemplate>
              <CustomFieldTemplate
                label="Content Type 1"
                description="Select the first content type"
              >
                <MRTcustomizedOriginal
                  results={choices.results}
                  initialSelectedIds={currentAction?.content_type_1}
                  enableRowSelection
                  enableMultiRowSelection={false}
                  onSelectedIdsChange={handleSelectChange1}
                  hideActionsColumn={true}
                />
              </CustomFieldTemplate>
              <CustomFieldTemplate
                label="Content Type 2"
                description="Select the second content type"
              >
                <MRTcustomizedOriginal
                  results={choices.results}
                  initialSelectedIds={currentAction?.content_type_2}
                  enableRowSelection
                  enableMultiRowSelection={false}
                  onSelectedIdsChange={handleSelectChange2}
                  hideActionsColumn={true}
                />
              </CustomFieldTemplate>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MultiProcess;
