import React, {useState} from 'react'
import { Typography, Box, Alert } from '@mui/material'
import Identify from '../Actions/Identify'
import Assess from '../Actions/Assess'
import Develop from '../Actions/Develop'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'
import View from '../Actions/View'
import Control from '../Actions/Control'
import Edit from '../Actions/Edit'

const formComponents = {
  identify: Identify,
  assess: Assess,
  control: Control,
  develop: Develop,
  determine: MultiStepResourceForm,
  edit: Edit,
  view: View
}

function GenericForm ({ model, instance_id, actions, organization }) {
  const sortedActions = actions.sort((a, b) => a.order - b.order)
  const [refreshKey, setRefreshKey] = useState(0)
  const updateParentState = () => {
    setRefreshKey(prevKey => prevKey + 1)
  }

  return (
    <Box>
      {sortedActions.map((action, index) => {
        const SpecificForm = formComponents[action.method_name]
        const actionTitle = `${index + 1}. ${action.title}`

        return (
          <Box key={`Box-${action.method_name}-${action?.content_type_1?.id}-${action?.content_type_2?.id}-${action?.assessment_parameters}-${action?.filter1}-${action?.filter2}`} mb={4}> {/* Add margin-bottom */}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{actionTitle}</Typography> {/* Style for title */}
            <Typography variant="body1" mb={2}>{action.description}</Typography> {/* Add margin-bottom for description */}
            {SpecificForm ? (
              <SpecificForm
                key={`${action.method_name}-${action?.content_type_1?.id}-${action?.content_type_2?.id}-${action?.assessment_parameters}-${action?.filter1}-${action?.filter2}`}
                resourceName={action?.content_type_1?.model}
                instance_id={instance_id}
                model1={action?.content_type_1}
                model2={action?.content_type_2}
                assessments={action?.assessment_parameters}
                filter1={action?.filter1}
                filter2={action?.filter2}
                updateParentState={updateParentState}
                refreshKey={refreshKey}
                model={model?.model}
                filters={{ organization: organization }} /* filters used for multistepform when a multiselect list call is used */
              />
            ) : (
              <Box mt={2}> {/* Add margin-top if no form */}
                {/* Placeholder for other action details */}
              </Box>
            )}
            {action?.info && action.info.trim() !== '' && (
              <Alert severity="info" sx={{ mt: 2, mb: 2 }}>{action.info}</Alert>
            )}
            {action?.warning && action.warning.trim() !== '' && (
              <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>{action.warning}</Alert>
            )}
            {action?.alert && action.alert.trim() !== '' && (
              <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{action.alert}</Alert>
            )}

          </Box>
        )
      })}
    </Box>
  )
}

export default GenericForm
