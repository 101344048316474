import React, { useState, useEffect } from 'react';
import DataList from 'src/components/General/DataList';
import StatCard from '../../../components/StatCard';
import { Grid, Box } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import useRequestCompliance from '../../../hooks/useRequestCompliance';
import Typography from '@mui/material/Typography';
import GlobalNavMenu from '../../../components/General/GlobalNavMenu';
import { downloadJSON, downloadCSV } from '../../../components/General/Utils';
import { useNavigate } from 'react-router-dom';

export default function StandardView() {
  const { getComplianceList: getKpi, complianceList: kpi } = useRequestCompliance({ endpoint: 'kpi/standard' });
  const { getComplianceList: fetchStandards, complianceList: standardsList } = useRequestCompliance({ endpoint: 'nestedstandards' });

  const [refreshKey, setRefreshKey] = useState(0);
  const [standard, setStandard] = useState(null);
  const [triggeredAction, setTriggeredAction] = useState(null); // For tracking which download action is triggered
  const navigate = useNavigate();

  useEffect(() => {
    getKpi(); // Fetch KPI when component mounts
  }, [getKpi]);

  // Fetch standards only when needed (e.g., for CSV or JSON download)
  const fetchStandardsForDownload = (type) => {
    setTriggeredAction(type); // Save the action type (CSV or JSON)
    fetchStandards(); // Trigger the fetch action
  };

  useEffect(() => {
    // Once standardsList is updated, check if it should trigger CSV or JSON download
    if (standardsList.results && standardsList.results.length > 0) {
      if (triggeredAction === 'csv') {
        downloadCSV(standardsList.results, 'All_Standards.csv');
      } else if (triggeredAction === 'json') {
        downloadJSON(standardsList.results, 'All_Standards.json');
      }
      setTriggeredAction(null); // Reset the action state after download
    }
  }, [standardsList, triggeredAction]);

  const getActionList = (standard, fileName, standards) => [
    {
      name: 'Add',
      icon: 'AddIcon',
      items: [
        {
          name: 'JSON Standard',
          action: () => navigate('/compliance/standards/nestedstandards/json'),
        },
        {
          name: 'SubControl',
          action: () => navigate('/compliance/standards/sub-controls/add'),
        },
        {
          name: 'Control',
          action: () => navigate('/compliance/standards/controls/add'),
        },
        {
          name: 'Standard',
          action: () => navigate('/compliance/standards/standards/add'),
        },
      ],
    },
    {
      name: 'Eunoia Explorer',
      icon: 'ExploreIcon',
      action: () => navigate('/compliance/standards/explore'),
    },
    {
      name: 'Refresh',
      icon: 'RefreshIcon',
      action: () => setRefreshKey((prevKey) => prevKey + 1),
    },
    {
      name: 'Download',
      icon: 'DownloadIcon',
      items: [
        {
          name: 'Download JSON',
          action: () => downloadJSON(standard, `${fileName}.json`),
        },
        {
          name: 'Download CSV',
          action: () => downloadCSV(standard, `${fileName}.csv`),
        },
        {
          name: 'Download all JSON',
          action: () => fetchStandardsForDownload('json'), // Fetch and download JSON only when clicked
        },
        {
          name: 'Download all CSV',
          action: () => fetchStandardsForDownload('csv'), // Fetch and download CSV only when clicked
        },
      ],
    },
    {
      name: 'Learn More',
      icon: 'ReadMoreIcon',
    },
  ];

  const actionList = getActionList(standard, standard ? standard.title : false, standardsList);

  // Make sure that values passed to StatCard are always numbers
  const totalStandards = Number(kpi?.results?.total_standards) || 0;
  const standardsUsedInAssessments = Number(kpi?.results?.standards_used_in_assessment) || 0;

  return (
    <>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid>
          <Typography variant="h5" gutterBottom>
            Compliance Standards
          </Typography>
        </Grid>
      </Grid>

      <GlobalNavMenu actionList={actionList}>
        <Box
          m={0}
          pt={3}
          sx={{
            flexGrow: 1,
            mb: (theme) => theme.spacing(2),
          }}
        >
          <Grid container spacing={2}>
            <StatCard
              title="Total Standards"
              value={totalStandards} 
              loading={false}
              icon={<AssignmentIcon fontSize="small" />}
            />
            <StatCard
              title="Standards Used in Assessments"
              value={standardsUsedInAssessments}  
              loading={false}
              icon={<DataUsageIcon fontSize="small" />}
            />
          </Grid>
        </Box>


        <DataList
          setStandard={setStandard}
          refreshKey={refreshKey}
        />
      </GlobalNavMenu>
    </>
  );
}
