import React, { useState, useEffect, useRef, useMemo } from 'react';
import Form from '@rjsf/mui';
import { Stepper, Step, StepLabel, StepButton, Button, Box } from '@mui/material';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import validator from '@rjsf/validator-ajv8';
import DynamicSelectWidget from 'src/components/General/DynamicSelectWidget';
import CustomFileWidget from '../General/CustomFileWidget';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import CustomFieldTemplate from '../BaseLayout/CustomFieldTemplate';
import CustomObjectFieldTemplate from '../BaseLayout/CustomObjectFieldTemplate';
import CreatableSingleSelectWidget from '../General/CreatableSingleSelectWidget';

// Flatten roles from the compliance data
function flattenRoles(complianceData) {
  const flattenedData = { ...complianceData };
  if (flattenedData.roles) {
    Object.keys(flattenedData.roles).forEach((roleKey) => {
      flattenedData[roleKey] = flattenedData.roles[roleKey]?.id;
    });
    delete flattenedData.roles; // Remove the original roles object
  }
  return flattenedData;
}

// Helper function to convert base64 to a file object
const base64ToFile = (dataurl, filename) => {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
// Function to modify schema types to include null if x-nullable is true
const adjustSchemaForNullable = (schema) => {
  if (!schema || !schema.properties) {
    return schema;
  }

  Object.keys(schema.properties).forEach((key) => {
    const prop = schema.properties[key];

    // Check if x-nullable is true and adjust the type accordingly
    if (prop['x-nullable'] === true) {
      if (Array.isArray(prop.type)) {
        // Ensure null is included in the type array
        if (!prop.type.includes('null')) {
          prop.type.push('null');
        }
      } else {
        // Convert type to an array including null if it's not already an array
        prop.type = [prop.type, 'null'];
      }
    }

    // Recursively handle nested objects and arrays
    if (prop.type === 'object' && prop.properties) {
      adjustSchemaForNullable(prop);
    } else if (prop.type === 'array' && prop.items && prop.items.properties) {
      adjustSchemaForNullable({ properties: prop.items.properties });
    }
  });

  return schema;
};

// Function to generate uiSchema based on the provided schema and instance_id
const generateUiSchema = (schema, instance_id = null, filters = {}) => {
  if (!schema || !schema.properties) {
    return {}; // Return an empty object if schema or schema.properties is not available
  }

  const uiSchema = {};
  Object.keys(schema.properties).forEach((key) => {
    schema = adjustSchemaForNullable(schema);
    const prop = schema.properties[key];
    if (prop.title === 'Password') {
      // Handle fields with title 'Password'
      uiSchema[key] = {
        id: `id_${key}`,
        'ui:widget': 'password', // Use password widget to render a password input
        'ui:options': {
          label: true, // Show label for password fields
          inputType: 'password', // Set the input type as password for masking input
          maxLength: prop.maxLength, // Set maxLength explicitly if provided
          ...(prop.minLength && { minLength: prop.minLength }), // Conditionally add minLength if available
        },
        'ui:inputProps': {
          maxLength: prop.maxLength, // Enforce maxLength directly on the input
        },
      };
    }
    else if (prop.format === 'email') {
      // Handle format 'email'
      uiSchema[key] = {
        id: `id_${key}`,
        'ui:widget': 'email', // Use email widget to render email input
        'ui:options': {
          label: true, // Show label if needed
          inputType: 'email', // Explicitly set the input type as email
          maxLength: prop.maxLength, // Set maxLength explicitly if provided
          ...(prop.minLength && { minLength: prop.minLength }), // Conditionally add minLength if available
        },
        'ui:inputProps': {
          maxLength: prop.maxLength, // Enforce maxLength directly on the input
        },
      };
    }
    // Use the custom single creatable widget for 'data_source' if it's a string type
    if (key === 'data_source' && prop.type === 'string') {
      uiSchema[key] = {
        id: `id_${key}`,
        'ui:widget': 'creatableSingleSelect',  // Use the custom widget here
        'ui:options': {
          label: true, // Show label if needed
        },
      };
    }
    else if (((Array.isArray(prop.type) && prop.type.includes('string')) || prop.type === 'string') && !prop.enum && prop.format!== 'date' && prop.format!== 'date-time' && prop.format!== 'image' ) {
      uiSchema[key] = {
        id: `id_${key}`, // Ensure unique ID
        'ui:widget': 'textarea',
        'ui:options': {
          label: false, // Hide the label for text areas
          maxLength: prop.maxLength, // Set maxLength explicitly
          ...(prop.minLength && { minLength: prop.minLength }), // Conditionally add minLength if available
        },
        'ui:inputProps': {
          maxLength: prop.maxLength, // Enforce maxLength directly on the textarea
        },
      };
    } else if (prop.format === 'image') {
      uiSchema[key] = {
        id: `id_${key}`,
        'ui:widget': 'file',
        'ui:options': {
          accept: '.png',
        },
      };
    } else if (prop.endpoint) {
      uiSchema[key] = {
        id: `id_${key}`,
        'ui:widget': 'dynamicSelect',
        'ui:options': {
          endpoint: prop.endpoint,
          filters: filters,
          ...(instance_id && { instance_id }), // Conditionally add instance_id to options
        },
      };
    } else if (prop.type === 'array' && prop.items && prop.items.endpoint) {
      uiSchema[key] = {
        id: `id_${key}`,
        items: {
          'ui:widget': 'dynamicSelect',
          'ui:options': {
            endpoint: prop.items.endpoint,
            filters: filters,
            ...(instance_id && { instance_id }), // Conditionally add instance_id to options for array items
          },
        },
      };
    } else if (prop.type === 'array') {
      uiSchema[key] = {
        id: `id_${key}`,
        'ui:options': {
          label: false,
          description: false,
        },
        items: generateUiSchema({ properties: prop.items.properties }, instance_id), // Recursively generate uiSchema for array items
      };
    } else if (prop.type === 'object') {
      uiSchema[key] = {
        id: `id_${key}`,
        'ui:options': {
          label: false,
          description: false,
        },
      };
    }
  });
  return uiSchema;
};
const customValidate = (formData, errors) => {
  // The custom value for data_source should be allowed even if it's not in the enum list
  if (formData.data_source && errors.data_source) {
    errors.data_source.__errors = errors.data_source.__errors.filter(
      (error) => !error.includes("must be equal to one of the allowed values")
    );
  }
  return errors;
};
function MultiStepResourceForm({ resourceName, id, instance_id, setRefreshKey, handleAddSubmit, partialUpdateProperties, onSubmitSuccess,filters }) {
  const { schema, getSchema, getSchemaId } = useRequestCompliance({
    endpoint: resourceName,
    resourceLabel: resourceName?.charAt(0).toUpperCase() + resourceName?.slice(1),
  });
  const { addComplianceWithFile, addCompliance, updateCompliance, getCompliance, compliance, updateComplianceWithFile } = useRequestCompliance({
    endpoint: resourceName,
    resourceLabel: resourceName?.charAt(0).toUpperCase() + resourceName?.slice(1),
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const formRef = useRef(null); // Reference to the Form component
  useEffect(() => {
  }, [formData]);
    const widgets = useMemo(
    () => ({
      dynamicSelect: DynamicSelectWidget,
      file: CustomFileWidget,
      creatableSingleSelect: CreatableSingleSelectWidget,  // Add your new custom widget
    }),
    []
  );
  const [validationErrors, setValidationErrors] = useState([]);
  const validateForm = () => {
    if (formRef.current) {
      const { errors } = formRef.current.validate(formRef.current.state.formData);
      setValidationErrors(errors); // Update the validation errors state
      return errors.length === 0;
    }
    return false;
  };


  const saveFormData = (callback) => {
    if (formRef.current) {
      const currentFormData = formRef.current.state.formData;
      setFormData((prevData) => {
        const updatedData = {
          ...prevData,
          ...currentFormData,
        };
        callback(updatedData); // trigger the callback after updating the data
        return updatedData;
      });
    } else {
      callback(); // If for some reason formRef is not accessible, still trigger the callback
    }
  };

  const uiSchema = useMemo(() => generateUiSchema(schema, instance_id,filters), [schema, instance_id,filters]);
  const filteredProperties = useMemo(() => {
    if (!schema || typeof schema.properties !== 'object') return {};

    const allProperties = Object.keys(schema.properties)
      .filter((key) => !(schema.properties[key].readOnly === true))
      .reduce((obj, key) => {
        obj[key] = schema.properties[key];
        return obj;
      }, {});

    if (partialUpdateProperties?.length > 0) {
      return Object.keys(allProperties)
        .filter((key) => {
          if (partialUpdateProperties.includes('roles') && allProperties[key].tag === 'roles') {
            return true;
          }
          return partialUpdateProperties.includes(key);
        })
        .reduce((obj, key) => {
          obj[key] = allProperties[key];
          return obj;
        }, {});
    }

    return allProperties;
  }, [schema, partialUpdateProperties]);

  useEffect(() => {
    if (instance_id) {
      if (compliance?.content_type) {
        getSchemaId(compliance.content_type);
      }
      else {
        getSchema();
      }
    } else {
      getSchema();
    }

  }, [instance_id, compliance?.content_type, getSchema, getSchemaId]);

  useEffect(() => {
    setCurrentStep(0);

    if (id) {
      getCompliance(id);
    } else if (instance_id) {
      getCompliance(instance_id);
    }
  }, [id, instance_id, getCompliance]);

  useEffect(() => {
    if ((id || instance_id) && compliance) {
      const flattenedData = flattenRoles(compliance);

      setFormData((prevData) => ({
        ...prevData,
        ...flattenedData,
      }));
    }
  }, [id, compliance, instance_id, partialUpdateProperties]);

  if (!schema || typeof schema.properties !== 'object') {
    return <div>Loading schema...</div>;
  }

  const steps = [];

  if ((filteredProperties.title && filteredProperties.description) || (filteredProperties.username && filteredProperties.first_name && filteredProperties.last_name && filteredProperties.email)) {
    const step = {
      title: 'Basics',
      properties: {},
    };
    if (filteredProperties.title && filteredProperties.description) {
      step.properties.title = filteredProperties.title;
      step.properties.description = filteredProperties.description;
    }
    if (filteredProperties.username && filteredProperties.first_name && filteredProperties.last_name) {
      step.properties.username = filteredProperties.username;
      step.properties.first_name = filteredProperties.first_name;
      step.properties.last_name = filteredProperties.last_name;
      step.properties.email = filteredProperties.email;
    }
    steps.push(step);
  }
  if (filteredProperties.compliance_assessment_process && filteredProperties.compliance_categorization_models) {
    const step = {
      title: 'Compliance management',
      properties: {},
    };
    if (filteredProperties.compliance_assessment_process && filteredProperties.compliance_categorization_models) {
      step.properties.compliance_assessment_process = filteredProperties.compliance_assessment_process;
      step.properties.compliance_categorization_models = filteredProperties.compliance_categorization_models;
    }

    steps.push(step);
  }
  if (filteredProperties.primary_asset_categorization_model && filteredProperties.supporting_asset_categorization_model && filteredProperties.information_system_categorization_model) {
    const step = {
      title: 'Asset management',
      properties: {},
    };
    if (filteredProperties.primary_asset_categorization_model && filteredProperties.supporting_asset_categorization_model && filteredProperties.information_system_categorization_model) {
      step.properties.primary_asset_categorization_model = filteredProperties.primary_asset_categorization_model;
      step.properties.supporting_asset_categorization_model = filteredProperties.supporting_asset_categorization_model;
      step.properties.information_system_categorization_model = filteredProperties.information_system_categorization_model;
    }

    steps.push(step);
  }
  if (filteredProperties.risk_management_methodology && filteredProperties.taxonomy) {
    const step = {
      title: 'Risk management',
      properties: {},
    };
    if (filteredProperties.risk_management_methodology && filteredProperties.taxonomy) {
      step.properties.taxonomy = filteredProperties.taxonomy;
      step.properties.risk_management_methodology = filteredProperties.risk_management_methodology;
    }

    steps.push(step);
  }
  steps.push(
    ...Object.keys(filteredProperties)
      .filter(
        (key) =>
          ![
            'title',
            'description',
            'username',
            'taxonomy',
            'risk_management_methodology',
            'first_name',
            'last_name',
            'email',
            'compliance_categorization_models',
            'compliance_assessment_process',
            'primary_asset_categorization_model',
            'supporting_asset_categorization_model',
            'information_system_categorization_model',
          ].includes(key)
      )
      .map((key) => ({
        title: key.charAt(0).toUpperCase() + key.slice(1),
        properties: {
          [key]: filteredProperties[key],
        },
      }))
  );

  const handleError = (errors) => {
    setValidationErrors(errors);
    // Optionally, you can show a toast or a dialog here to inform the user about the validation errors
  };

  const handleNext = () => {
    const isValid = validateForm();
    if (isValid) {
      saveFormData(() => {
        setCurrentStep((prevStep) => prevStep + 1);
      });
    }
  };

  const handleStepClick = (index) => {
    if (validateForm()) {
      saveFormData(() => {
        setCurrentStep(index);
      });
    }
  };

  const handleFinalSubmit = (data) => {
    const formData = new FormData();
    let filePresent = false;
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'string' && value.startsWith('data:image')) {
        const file = base64ToFile(value, 'uploaded_image.png');
        formData.append(key, file);
        filePresent = true;
      } else if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}`, element);
        });
      } else{

        formData.append(key, value);
      }
    });

    if (filePresent) {
      if (id) {
        updateComplianceWithFile(id, formData, () => {
          onSubmitSuccess && onSubmitSuccess();
        });
      } else if (instance_id) {
        updateComplianceWithFile(instance_id, formData, () => {
          onSubmitSuccess && onSubmitSuccess();
        });
      } else {
        addComplianceWithFile(formData, () => {
          onSubmitSuccess && onSubmitSuccess();
        });
      }
    } else {
      if (id) {
        updateCompliance(id, data, () => {
          onSubmitSuccess && onSubmitSuccess();
        });
      } else if (instance_id) {
        updateCompliance(instance_id, data, () => {
          onSubmitSuccess && onSubmitSuccess();
        });
      } else {
        addCompliance(data, () => {
          onSubmitSuccess && onSubmitSuccess();
        });
      }
    }
    if (setRefreshKey) {
      setRefreshKey((prevKey) => prevKey + 1);
    }
  };

  const handleSubmit = ({ formData: stepData }) => {
    const finalData = {
      ...formData,
      ...stepData,
    };

    const dataToSend = Object.keys(filteredProperties).reduce((obj, key) => {
      if (finalData.hasOwnProperty(key)) {
        if (key === 'roles' && Array.isArray(finalData.roles)) {
          obj[key] = finalData.roles.map((role) => role.id || role);
        } else {
          obj[key] = finalData[key];
        }
      }
      return obj;
    }, {});

    if (currentStep < steps.length - 1) {
      setFormData(dataToSend);
      setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
    } else {
      if (handleAddSubmit) {
        handleAddSubmit(dataToSend);
      } else {
        handleFinalSubmit(dataToSend);
      }
    }
  };

  if (steps.length === 0 || !steps[currentStep]) {
    return <div>No steps available or current step is invalid!</div>;
  }

  if (!steps[currentStep].properties) {
    return <div>The current step does not have properties defined!</div>;
  }
  return (
    <Box sx={{ width: '100%', my: 4,  overflowX: 'hidden' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stepper activeStep={currentStep} alternativeLabel nonLinear>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton onClick={() => handleStepClick(index)}>
                  <StepLabel>{step.title}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          {validationErrors.length > 0 && (
            <Stack sx={{ width: '100%' }} spacing={2}>
              {validationErrors.map((error, index) => (
                <Alert key={index} severity="error">
                  {error.stack}
                </Alert>
              ))}
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} sx={{ pb: 8 }}>
          <Form
            ref={formRef}
            onError={handleError}
            schema={{
              type: 'object',
              properties: steps[currentStep].properties,
              required: schema.required?.filter((req) => Object.keys(steps[currentStep].properties).includes(req)),
            }}
            onChange={(e) => {
              setFormData(e.formData); // Ensure this updates the state
            }}
            formData={formData}
            validator={validator}
            onSubmit={handleSubmit}
            widgets={widgets}
            uiSchema={uiSchema}
            noHtml5Validate
            validate={customValidate}
            templates={{ FieldTemplate: CustomFieldTemplate, ObjectFieldTemplate: CustomObjectFieldTemplate }}
          >
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                backgroundColor: 'background.paper',
                p: 2,
                zIndex: 1000,
                borderTop: 1,
                borderColor: 'grey.400',
              }}
            >
              <Box sx={{ display: 'flex', mr: 2 }}>
                <Button variant="outlined" color="primary" disabled={currentStep === 0} onClick={() => { setValidationErrors([]); setCurrentStep(currentStep - 1); }} sx={{ mr: 2 }}>
                  Back
                </Button>
                {currentStep < steps.length - 1 && (
                  <Button variant="contained" color="primary" onClick={handleNext} sx={{ mr: 2 }}>
                    Next
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button variant="contained" color="primary" type="submit">
                    Finish
                  </Button>
                )}
              </Box>
            </Box>
          </Form>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MultiStepResourceForm;
