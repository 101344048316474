import React, { useRef, useState, useEffect,useCallback } from 'react';
import {
  Box,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import Editor from '@monaco-editor/react';
import useRequestCompliance from 'src/hooks/useRequestCompliance';

const example = {
  title: 'Global Cybersecurity Framework',
  description: 'A global set of cybersecurity standards for international cooperation.',
  environment: 'All',
  created_at: '2023-06-15T14:20:46.123456Z',
  type: 'Technical',
  compliances: [],
  controls: [
    {
      title: 'Data Protection (D1)',
      description: 'Implement measures to protect sensitive data from unauthorized access.',
      category: 'Data Protection (D)',
      created_at: '2023-06-15T14:30:46.123456Z',
      subControls: [
        {
          title: 'Data Encryption',
          description: 'Encrypt sensitive data at rest and in transit.',
          type: 'MANUAL',
          outcomes: [
            {
              name: 'encryption description',
              description: 'Description of the encryption protocols used.',
              request: 'Describe the encryption protocols you are using for data protection.',
              type: 'Text',
              listOfChoices: []
            },
            {
              name: 'encryption evidence',
              description: 'Evidence that data encryption protocols are implemented.',
              request: 'Provide documentation that shows implemented encryption protocols.',
              type: 'File upload',
              listOfChoices: []
            }
          ]
        }
      ],
      outcomes: []
    },
    {
      title: 'Network Security (N1)',
      description: 'Secure network infrastructure against vulnerabilities.',
      category: 'Network Security (N)',
      created_at: '2023-06-15T14:30:46.123456Z',
      subControls: [
        {
          title: 'Firewall Management',
          description: 'Maintain and regularly update firewall settings.',
          type: 'MANUAL',
          outcomes: [
            {
              name: 'firewall management description',
              description: 'Description of how firewalls are managed.',
              request: 'Describe your firewall management process.',
              type: 'Text',
              listOfChoices: []
            },
            {
              name: 'firewall management evidence',
              description: 'Evidence of firewall management protocols.',
              request: 'Provide documentation showing firewall management protocols.',
              type: 'File upload',
              listOfChoices: []
            }
          ]
        }
      ],
      outcomes: []
    },
    {
      title: 'User Authentication (U1)',
      description: 'Ensure strong user authentication protocols.',
      category: 'User Authentication (U)',
      created_at: '2023-06-15T14:30:46.123456Z',
      subControls: [
        {
          title: 'Multi-Factor Authentication',
          description: 'Implement multi-factor authentication for sensitive systems.',
          type: 'MANUAL',
          outcomes: [
            {
              name: 'MFA description',
              description: 'Description of the multi-factor authentication process.',
              request: 'Describe the multi-factor authentication process.',
              type: 'Text',
              listOfChoices: []
            },
            {
              name: 'MFA evidence',
              description: 'Evidence that multi-factor authentication is implemented.',
              request: 'Provide documentation demonstrating MFA implementation.',
              type: 'File upload',
              listOfChoices: []
            }
          ]
        }
      ],
      outcomes: []
    }
  ]
}

const example_process = {
  title: 'Risk Assessment Guide',
  description: 'Special Publication 800-30 Guide for Conducting Risk Assessments',
  steps: [
    {
      title: 'Prepare for Risk Assessment',
      description: 'Step 1 tasks for preparing risk assessment.',
      dependencies: [],
      tasks: [
        {
          title: 'IDENTIFY PURPOSE',
          description: 'Identify the purpose of the risk assessment in terms of the information that the assessment is intended to produce and the decisions the assessment is intended to support.',
          dependencies: []
        },
        {
          title: 'IDENTIFY SCOPE',
          description: 'Identify the scope of the risk assessment in terms of organizational applicability, time frame supported, and architectural/technology considerations.',
          dependencies: ['IDENTIFY PURPOSE']
        },
        {
          title: 'IDENTIFY ASSUMPTIONS AND CONSTRAINTS',
          description: 'Identify the specific assumptions and constraints under which the risk assessment is conducted.',
          dependencies: ['IDENTIFY SCOPE']
        },
        {
          title: 'IDENTIFY INFORMATION SOURCES',
          description: 'Identify the sources of descriptive, threat, vulnerability, and impact information to be used in the risk assessment.',
          dependencies: ['IDENTIFY ASSUMPTIONS AND CONSTRAINTS']
        },
        {
          title: 'IDENTIFY RISK MODEL AND ANALYTIC APPROACH',
          description: 'Identify the risk model and analytic approach to be used in the risk assessment.',
          dependencies: ['IDENTIFY INFORMATION SOURCES']
        }
      ]
    },
    {
      title: 'Conduct Risk Assessment',
      description: 'Step 2 tasks for conducting risk assessment.',
      dependencies: ['Prepare for Risk Assessment'],
      tasks: [
        {
          title: 'IDENTIFY THREAT SOURCES',
          description: 'Identify and characterize threat sources of concern, including capability, intent, and targeting characteristics for adversarial threats and range of effects for non-adversarial threats.',
          dependencies: []
        },
        {
          title: 'IDENTIFY THREAT EVENTS',
          description: 'Identify potential threat events, relevance of the events, and the threat sources that could initiate the events.',
          dependencies: ['IDENTIFY THREAT SOURCES']
        },
        {
          title: 'IDENTIFY VULNERABILITIES AND PREDISPOSING CONDITIONS',
          description: 'Identify vulnerabilities and predisposing conditions that affect the likelihood that threat events of concern result in adverse impacts.',
          dependencies: ['IDENTIFY THREAT EVENTS']
        },
        {
          title: 'DETERMINE LIKELIHOOD',
          description: 'Determine the likelihood that threat events of concern result in adverse impacts, considering the characteristics of the threat sources, vulnerabilities, and organizational susceptibility.',
          dependencies: ['IDENTIFY VULNERABILITIES AND PREDISPOSING CONDITIONS']
        },
        {
          title: 'DETERMINE IMPACT',
          description: 'Determine the adverse impacts from threat events of concern, considering the characteristics of the threat sources, vulnerabilities, and organizational susceptibility.',
          dependencies: ['DETERMINE LIKELIHOOD']
        },
        {
          title: 'DETERMINE RISK',
          description: 'Determine the risk to the organization from threat events of concern considering the impact and likelihood of the events occurring.',
          dependencies: ['DETERMINE IMPACT']
        }
      ]
    },
    {
      title: 'Communicate and Share Risk Assessment Results',
      description: 'Step 3 tasks for communicating and sharing risk assessment results.',
      dependencies: ['Conduct Risk Assessment'],
      tasks: [
        {
          title: 'COMMUNICATE RISK ASSESSMENT RESULTS',
          description: 'Communicate risk assessment results to organizational decision makers to support risk responses.',
          dependencies: []
        },
        {
          title: 'SHARE RISK-RELATED INFORMATION',
          description: 'Share risk-related information produced during the risk assessment with appropriate organizational personnel.',
          dependencies: ['COMMUNICATE RISK ASSESSMENT RESULTS']
        }
      ]
    },
    {
      title: 'Maintain Risk Assessment',
      description: 'Step 4 tasks for maintaining risk assessment.',
      dependencies: ['Communicate and Share Risk Assessment Results'],
      tasks: [
        {
          title: 'MONITOR RISK FACTORS',
          description: 'Conduct ongoing monitoring of the risk factors that contribute to changes in risk to organizational operations and assets, individuals, other organizations, or the Nation.',
          dependencies: []
        },
        {
          title: 'UPDATE RISK ASSESSMENT',
          description: 'Update existing risk assessment using the results from ongoing monitoring of risk factors.',
          dependencies: ['MONITOR RISK FACTORS']
        }
      ]
    }
  ]
}

function JsonEditor({ endpoint }) {
  const editorRef = useRef(null);
  const monacoRef = useRef(null);
  const { addCompliance, nestedError } = useRequestCompliance({ endpoint, resourceLabel: endpoint });
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    monacoRef.current = monaco;
  };

  const defaultValue = JSON.stringify(endpoint === 'nestedstandards' ? example : example_process, null, 2);

  const handleSubmit = () => {
    const editorValue = editorRef.current.getValue();
    let jsonData = null;
    try {
      jsonData = JSON.parse(editorValue);
      setErrorMessage('');
      addCompliance(jsonData);
    } catch (error) {
      setErrorMessage('Invalid JSON format');
      setOpen(true);
    }
  };


  const flattenErrors = useCallback((data, path = '', jsonString) => {
    let errors = [];

    if (typeof data === 'object' && data !== null) {
      Object.keys(data).forEach(key => {
        const newPath = path ? `${path}.${key}` : key;

        if (Array.isArray(data[key])) {
          data[key].forEach((error, index) => {
            if (typeof error === 'object' && error !== null) {
              errors = errors.concat(flattenErrors(error, `${newPath}[${index}]`, jsonString));
            } else {
              const errorLine = 0;
              errors.push({ path: `${newPath}[${index}]`, message: error, line: errorLine });
            }
          });
        } else if (typeof data[key] === 'object') {
          errors = errors.concat(flattenErrors(data[key], newPath, jsonString));
        } else {
          const errorLine = 0;
          errors.push({ path: newPath, message: data[key], line: errorLine });
        }
      });
    }
    return errors;
  }, []);
  
  useEffect(() => {
    if (nestedError?.response?.data) {
      const editorValue = editorRef.current.getValue();
      const errors = flattenErrors(nestedError.response.data, '', editorValue);

      const monaco = monacoRef.current;
      const editor = editorRef.current;
      const model = editor.getModel();

      const markers = errors.map(error => ({
        startLineNumber: error.line || 1,
        startColumn: 1,
        endLineNumber: error.line || 1,
        endColumn: 1,
        message: `${error.path}: ${error.message}`,
        severity: monaco.MarkerSeverity.Error,
      }));

      monaco.editor.setModelMarkers(model, 'json', markers);
    }
  }, [nestedError, flattenErrors]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Editor
        height="calc(100vh - 200px)" // Adjust height to account for error display
        defaultLanguage="json"
        defaultValue={defaultValue}
        onMount={handleEditorDidMount}
      />
      <Box sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default JsonEditor;