import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Paper, Typography, Drawer, IconButton, Toolbar, Stack, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RenderField from './RenderField'; // Import the RenderField component
import useRequestCompliance from '../../hooks/useRequestCompliance';
import pluralize from 'pluralize';

function ObjectDetailTabs({ id, endpoint, open, setOpen }) {
  const [currentTab, setCurrentTab] = useState('basic');
  const [loading, setLoading] = useState(false); // State to manage loading indicator
  const { compliance: data = {}, getCompliance: getData } = useRequestCompliance({ endpoint, resourceLabel: endpoint });
  const [flatData, setFlatData] = useState({});
  const [nestedData, setNestedData] = useState({});

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Fetch data when the drawer is open
  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        setLoading(true);
        try {
          await getData(id);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [open, id, getData]);

  // Process data after fetching it to avoid blocking the main thread
  useEffect(() => {
    if (data && typeof data === 'object') {  // Check if data is defined and is an object
      setLoading(true);
      setTimeout(() => {
        const newFlatData = Object.entries(data || {}) // Use empty object if data is undefined
          .filter(([key, value]) => typeof value !== 'object')
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});

        const newNestedData = Object.entries(data || {}) // Use empty object if data is undefined
          .filter(([key, value]) => typeof value === 'object' && value !== null) // Ensure value is not null
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});

        setFlatData(newFlatData);
        setNestedData(newNestedData);
        setLoading(false);
      }, 0); // Use setTimeout to yield to the browser and allow UI updates
    }
  }, [data]);

  return (
    <Drawer
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'block', md: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: '40%',
        },
      }}
      anchor="right"
      variant="temporary"
      open={open}
      onClose={handleDrawerClose}
    >
      <Toolbar />
      <Box>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Paper elevation={3}>
        <Tabs value={currentTab} onChange={handleChange}>
          <Tab label="Basic" value="basic" />
          {Object.keys(nestedData)
            .filter((key) => nestedData[key] !== null)
            .map((key) => (
              <Tab label={key} value={key} key={key} />
            ))}
        </Tabs>
        <Box p={3}>
          {loading ? ( // Show loading indicator while data is being fetched or processed
            <Stack justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
              <Typography variant="body2" sx={{ mt: 2 }}>
                Loading...
              </Typography>
            </Stack>
          ) : (
            <Stack spacing={2}>
              {currentTab === 'basic' ? (
                Object.keys(flatData).length > 0 ? (
                  Object.entries(flatData).map(([key, value]) => (
                    <RenderField key={key} field={key} value={value} />
                  ))
                ) : (
                  <Typography variant="body2">No basic data available.</Typography>
                )
              ) : (
                Object.keys(nestedData[currentTab] || {}).length > 0 ? (
                  Object.entries(nestedData[currentTab] || {}).map(([key, value]) => (
                    <RenderField key={key} field={`${pluralize.singular(currentTab)} - ${key}`} value={value} />
                  ))
                ) : (
                  <Typography variant="body2">No data available.</Typography>
                )
              )}
            </Stack>
          )}
        </Box>
      </Paper>
    </Drawer>
  );
}

export default ObjectDetailTabs;
