import React, { useState, useEffect } from 'react'
import useRequestCompliance from '../../hooks/useRequestCompliance'
import DynamicTable from './DynamicTable'
import { useNavigate } from 'react-router-dom'
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Modal,
  TextField,
  Button,
} from '@mui/material';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};
export default function DynamicTabsAndTables ({ tabEndpoints, onDataLoaded, refreshKey, hideActionsColumn}) {
  const [currentTab, setCurrentTab] = useState(null)
  const [parentSelectedRows, setParentSelectedRows] = useState([])
  const { getComplianceList, complianceList, deleteResource } = useRequestCompliance({ endpoint: currentTab ? currentTab.endpoint : '', resourceLabel: currentTab ? currentTab.endpoint : '' })
  const navigate = useNavigate()
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [userId, setUserId] = useState('');
  const { updateCompliance: updatePassword} = useRequestCompliance({ endpoint:'admin/users/reset_password' , resourceLabel: 'Password'})

  useEffect(() => {
    if (tabEndpoints && tabEndpoints.length > 0) {
      setCurrentTab(tabEndpoints[0])
    }
  }, [tabEndpoints])

  useEffect(() => {
    if (currentTab) {
      getComplianceList() // Ensure getComplianceList returns a Promise or make it async
    }
  }, [currentTab, getComplianceList, refreshKey]) // Removed onDataLoaded and complianceList to prevent infinite loop

  useEffect(() => {
    if (onDataLoaded) {
      onDataLoaded(complianceList, `${currentTab ? currentTab.name : 'default'}`)
    };
  }, [complianceList])

  const handleChange = (event, newValue) => {
    setCurrentTab(tabEndpoints.find(tab => tab.name === newValue))
  }
  const handlePasswordResetModalOpen = () => {
    setPasswordResetModalOpen(true);
  };

  const handlePasswordResetModalClose = () => {
    setPasswordResetModalOpen(false);
  };

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };


    const handleSubmit = (e) => {
      e.preventDefault();
      updatePassword(userId, {'new_password': newPassword});
    };

  const handleEdit = (id) => {
    switch (currentTab.name) {
      case 'Role assignments':
        navigate(`/manage/rbac/role-assignments/edit/${id}`);
        break;
      case 'Role permissions':
        navigate(`/manage/rbac/role-permissions/edit/${id}`);
        break;
      case 'Role definitions':
        navigate(`/manage/rbac/role-definitions/edit/${id}`);
        break;
      case 'Organizations':
        navigate(`/governance/organizations/edit/${id}`);
        break;
      case 'Missions':
        navigate(`/governance/organization/missions/edit/${id}`);
        break;
      case 'Information system':
        navigate(`/manage/assets/information-systems/edit/${id}`);
        break;
      case 'Primary assets':
        navigate(`/manage/assets/primary-assets/edit/${id}`);
        break;
      case 'Supporting assets':
        navigate(`/manage/assets/supporting-assets/edit/${id}`);
        break;
      case 'Strategies':
        navigate(`/governance/strategies/definitions/edit/${id}`);
        break;
      case 'Strategy assignments':
        navigate(`/governance/strategies/assignments/edit/${id}`);
        break;
      case 'Processes':
        navigate(`/governance/processes/models/edit/${id}`);
        break;
      case 'Responsibility models':
        navigate(`/governance/responsibilities/responsibility-models/edit/${id}`);
        break;
      case 'Activities':
        navigate(`/governance/responsibilities/activities/edit/${id}`);
        break;
      case 'Users':
        navigate(`/settings/iam/users/edit/${id}`);
        break;
      case 'Groups':
        navigate(`/settings/iam/groups/edit/${id}`);
        break;
      case 'Categorization Models':
        navigate(`/settings/models/categorization-models/edit/${id}`);
        break;
      case 'Grid Matrices':
        navigate(`/settings/models/grid-matrices/edit/${id}`);
        break;
        case 'Threat sources':
          navigate(`/risk/taxonomies/threatsources/edit/${id}`);
          break;
        case 'Threat events':
          navigate(`/risk/taxonomies/threatevents/edit/${id}`);
          break;
        case 'Vulnerabilities':
          navigate(`/risk/taxonomies/vulnerabilities/edit/${id}`);
          break;
        case 'Impacts':
          navigate(`/risk/taxonomies/impacts/edit/${id}`);
          break;
          case 'Taxonomies':
            navigate(`/risk/taxonomies/definitions/edit/${id}`);
            break;
      default:
        console.log('Invalid tab name', currentTab.name);
    }
  }
  
  const passwordResetModal = (
    <Modal open={passwordResetModalOpen} onClose={handlePasswordResetModalClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2">
          Reset Password 
        </Typography>
        <TextField
          id="new-password"
          type="password"
          label="New Password"
          value={newPassword}
          onChange={handlePasswordChange}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Reset Password
        </Button>
      </Box>
    </Modal>
  );

  const handleDetail = (id) => {
    console.log(`Action: edit on Row ID: ${id}`)
  }

  const handleDelete = (id) => {
    // Use deleteResource hook function to delete the resource
    deleteResource(id)
  }
  const handleResetPassword = (id) => {
    setUserId(id);
    handlePasswordResetModalOpen();

  }
  return (
    <>
      <Tabs value={currentTab ? currentTab.name : false} onChange={handleChange}>
        {tabEndpoints.map((tab) => (
          <Tab label={tab.name} value={tab.name} key={tab.name} />
        ))}
      </Tabs>
      <DynamicTable
        key={currentTab?.name}
        currentTab={currentTab} // <-- Pass currentTab here
        complianceList={complianceList.results}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleDetail={handleDetail}
        handleResetPassword={currentTab?.name === 'Users' ? handleResetPassword : null} // Only show for users tab
        setParentSelectedRows={setParentSelectedRows}
        hideActionsColumn={currentTab?.name === 'Missions'}
      />
            {passwordResetModal}
    </>
  )
}
