import React from 'react';
import { Grid, Typography, Box, Card, CardContent, CardActionArea, Container } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SupportIcon from '@mui/icons-material/Support';

export default function Welcome() {
  return (
    <>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <Typography variant="h2" component="h1" gutterBottom>
              Welcome to Eunoia Security Hub
            </Typography>
            <Typography  component="p" gutterBottom>
              Your enterprise-wide solution for managing security and compliance with ease.
            </Typography>
            <Typography variant="h3" component="h2" gutterBottom>
              Getting Started with Eunoia Security Hub
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              Follow these steps to get the most out of our platform:
            </Typography>
          </Grid>

          {/* Governance Section */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <CorporateFareIcon sx={{ mr: 1 }} />
              <Typography variant="h2">Organize Your Governance</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/governance/organizations">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Define and Model Your Organization
                  </Typography>
                  <Typography variant="body2" component="p">
                    Start by defining your organizational structure and associated missions.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/governance/strategies">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Define Strategies
                  </Typography>
                  <Typography variant="body2" component="p">
                    Establish strategies aligned with your organizational goals.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/governance/processes">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Manage Processes
                  </Typography>
                  <Typography variant="body2" component="p">
                    View and define your security processes.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/governance/responsibilities">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Define Responsibilities
                  </Typography>
                  <Typography variant="body2" component="p">
                    Define and manage roles and responsibilities within your organization.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          {/* Manage Section */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
              <InventoryIcon sx={{ mr: 1 }} />
              <Typography variant="h2">Manage Your organization assets</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/manage/assets">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Manage information systems, primary and supporting assets
                  </Typography>
                  <Typography variant="body2" component="p">
                    Model, identify and categorize your assets.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/manage/teams">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Manage Teams
                  </Typography>
                  <Typography variant="body2" component="p">
                    Collaborate and manage your teams efficiently.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/manage/rbac">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Manage RBAC
                  </Typography>
                  <Typography variant="body2" component="p">
                    Oversee role-based access control settings.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          {/* Compliance Section */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
              <FactCheckIcon sx={{ mr: 1 }} />
              <Typography variant="h2">Ensure Compliance</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/compliance/standards">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Define Standards
                  </Typography>
                  <Typography variant="body2" component="p">
                    View and tailor compliance standards for your organization.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/compliance/programs">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Manage Programs
                  </Typography>
                  <Typography variant="body2" component="p">
                    Oversee compliance programs effectively.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/compliance/assessments">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Conduct Assessments
                  </Typography>
                  <Typography variant="body2" component="p">
                    Perform compliance assessments to meet regulatory requirements.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/compliance/monitoring">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Monitor Activities
                  </Typography>
                  <Typography variant="body2" component="p">
                    Keep track of compliance activities within your organization.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>

          {/* Risk Section */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
              <GppMaybeIcon sx={{ mr: 1 }} />
              <Typography variant="h2">Manage Risks</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/risk/methodologies">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Define Methodologies
                  </Typography>
                  <Typography variant="body2" component="p">
                    Set up methodologies for assessing and responding to risks.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/risk/assessments">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Conduct Risk Assessments
                  </Typography>
                  <Typography variant="body2" component="p">
                    Evaluate risks and their impact on your organization.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/risk/monitoring">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Monitor Risks
                  </Typography>
                  <Typography variant="body2" component="p">
                    Monitor and manage risks effectively.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>


          {/* Settings Section */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
              <PermIdentityIcon sx={{ mr: 1 }} />
              <Typography variant="h2">Configure Settings</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/settings/IAM">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Manage IAM
                  </Typography>
                  <Typography variant="body2" component="p">
                    Handle identity and access management.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardActionArea component={RouterLink} to="/settings/models">
              <Card>
                <CardContent>
                  <Typography variant="h3" component="h2">
                    Manage Models
                  </Typography>
                  <Typography variant="body2" component="p">
                    Oversee data models and schemas.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>

          {/* Additional Resources */}
          <Grid item xs={12}>
            <Typography variant="h3" component="h2" gutterBottom>
              Additional Resources
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardActionArea component={RouterLink} to="/documentation">
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MenuBookIcon sx={{ mr: 1 }} />
                    <Typography variant="h3" component="h2">
                      Access Documentation
                    </Typography>
                  </Box>
                  <Typography variant="body2" component="p">
                    Get detailed documentation to understand functionalities, API references, and examples.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardActionArea component={RouterLink} to="/support">
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SupportIcon sx={{ mr: 1 }} />
                    <Typography variant="h3" component="h2">
                      Contact Support
                    </Typography>
                  </Box>
                  <Typography variant="body2" component="p">
                    Reach out to our support team or join our community forum for assistance.
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
