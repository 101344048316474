import React, { useState, useEffect } from 'react'
import GlobalNavMenu from 'src/components/General/GlobalNavMenu'
import StatCard from 'src/components/StatCard'
import { Grid, Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import DynamicTabsAndTables from 'src/components/General/DynamicTabsAndTables'
import { downloadCSV, downloadJSON } from 'src/components/General/Utils'
import { useNavigate } from 'react-router-dom'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

const tabEndpoints = [
  { name: 'Taxonomies', endpoint: 'taxonomies' },
  { name: 'Threat sources', endpoint: 'threatsources' },
  { name: 'Threat events', endpoint: 'threatevents' },
  { name: 'Vulnerabilities', endpoint: 'vulnerabilities' },
  { name: 'Impacts', endpoint: 'impacts' },
]

export default function TaxonomyView () {
  const navigate = useNavigate()
  const getActionList = (complianceList, fileName) => [
    {
      name: 'Add',
      icon: 'AddIcon',
      items: [
        {
          name: 'Add taxonomy',
          action: () => navigate('/risk/taxonomies/definitions/add')
        },
        {
          name: 'Add threat source',
          action: () => navigate('/risk/taxonomies/threatsources/add')
        },
        {
          name: 'Add threat event',
          action: () => navigate('/risk/taxonomies/threatevents/add')
        },
        {
          name: 'Add vulnerability',
          action: () => navigate('/risk/taxonomies/vulnerabilities/add')
        },
        {
          name: 'Add impact',
          action: () => navigate('/risk/taxonomies/impacts/add')
        },
        {
          name: 'Bulk add threat sources',
          action: () => navigate('/risk/taxonomies/threatsources/json')
        },
        {
          name: 'Bulk add threat events',
          action: () => navigate('/risk/taxonomies/threatevents/json')
        },
        {
          name: 'Bulk add vulnerabilities',
          action: () => navigate('/risk/taxonomies/vulnerabilities/json')
        },
        {
          name: 'Bulk add impacts',
          action: () => navigate('/risk/taxonomies/impacts/json')
        },
      ]
    },
    {
      name: 'Refresh',
      icon: 'RefreshIcon',
      action: () => setRefreshKey(prevKey => prevKey + 1)
    },
    {
      name: 'Download',
      icon: 'DownloadIcon',
      items: [
        {
          name: 'Download JSON',
          action: () => downloadJSON(complianceList.results, `${fileName}.json`)
        },
        {
          name: 'Download CSV',
          action: () => downloadCSV(complianceList.results, `${fileName}.csv`)
        }
      ]
    },
    {
      name: 'Learn More',
      icon: 'ReadMoreIcon'
    }
  ]

  const [complianceList, setComplianceList] = useState([])
  const [fileName, setFileName] = useState()
  const [refreshKey, setRefreshKey] = useState(0)
  const { getComplianceList: getKpi, complianceList: kpi } =
    useRequestCompliance({ endpoint: 'kpi/taxonomies' })
  useEffect(() => {
    getKpi()
  }, [getKpi, refreshKey])

  // Use the function here to get the action list
  const actionList = getActionList(complianceList, fileName)

  return (
    <>
          <Grid container spacing={0} justifyContent="space-between">
      <Grid >
      <Typography variant="h5" gutterBottom>
        Risk taxonomies
      </Typography>
      </Grid>
      </Grid>
      <GlobalNavMenu actionList={actionList}>
    <Box m={0} pt={3} sx={{
      flexGrow: 1,
      mb: (theme) => theme.spacing(2)
    }}>
            <Grid container spacing={2}>
                <StatCard
                    title="Total taxonomies"
                    value={kpi ? kpi.results.total_taxonomies: 'Loading...'}
                    loading={false}
                    icon={<ViewQuiltIcon fontSize="small" />}
                />
            </Grid>
        </Box>
            <DynamicTabsAndTables
        tabEndpoints={tabEndpoints}
        onDataLoaded={(data, filename) => {
          setComplianceList(data)
          setFileName(filename)
        }}
        refreshKey={refreshKey}
    />
    </GlobalNavMenu>
    </>
  )
}
