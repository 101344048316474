import React, {useEffect} from 'react';
import StandardsGraph from 'src/components/complianceAssessment/StandardsGraph';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import {
  Typography,
  Box,
  Divider,

} from '@mui/material'

const standards = [
  { id: 's1', title: 'Standard 1' },
  { id: 's2', title: 'Standard 2' },
];

const controls = [
  { id: 'c1', title: 'Control 1', standards: ['s1', 's2'] },
  { id: 'c2', title: 'Control 2', standards: ['s1'] },
];

const subControls = [
  { id: 'sc1', title: 'SubControl 1', controls: ['c1'] },
  { id: 'sc2', title: 'SubControl 2', controls: ['c1', 'c2'] },
];

export default function StandardsGraphPage() {
  const { getComplianceList, complianceList: standards } = useRequestCompliance({ endpoint: 'standards' });
  const { getComplianceList: getControls, complianceList: controls } = useRequestCompliance({ endpoint: 'controls' });
  const { getComplianceList: getSubControls, complianceList: subControls } = useRequestCompliance({ endpoint: 'sub-controls' });

  useEffect(() => {
    getComplianceList();
    getControls()
    getSubControls()
  }, [ getComplianceList,getControls,getSubControls]);

  return (
    <div>
                <Box p={2} sx={{ flexGrow: 1 }}>
            <Typography variant="h5">
              Eunoia Standard Explorer
            </Typography>
            <Divider/>
          </Box>
      <StandardsGraph
        standards={standards?.results}
        controls={controls?.results}
        subControls={subControls?.results}
      />
    </div>
  );
}
