import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip'; // For showing dependencies on hover
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const ProcessNavigation = ({ step_instances, onItemSelect, completedSteps, completedTasks }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const theme = useTheme();

  // Helper function to map titles to IDs for steps and tasks
  const mapTitlesToIds = (instances) => {
    const titleToIdMap = { steps: {}, tasks: {} };

    instances.forEach((stepInstance) => {
      titleToIdMap.steps[stepInstance.step.title] = stepInstance.step.id; // Map step titles to IDs
      stepInstance.task_instances.forEach((taskInstance) => {
        titleToIdMap.tasks[taskInstance.task.title] = taskInstance.task.id; // Map task titles to IDs
      });
    });

    return titleToIdMap;
  };

  // Generate the title-to-ID maps for steps and tasks
  const titleToIdMap = mapTitlesToIds(step_instances);

  // Helper function to check if dependencies are completed
  const areDependenciesCompleted = (dependencies = [], titleToIdMap, completedItems) => {
    if (!dependencies || dependencies.length === 0) {
      return true;
    }

    const idsForDependencies = dependencies
      .map(dep => titleToIdMap[dep])
      .filter(id => id !== undefined);

    if (idsForDependencies.length === 0) {
      return true;
    }

    return idsForDependencies.every(id => completedItems[id]);
  };

  useEffect(() => {
    const selectFirstNonCompletedTask = () => {
      for (const stepInstance of step_instances) {
        for (const taskInstance of stepInstance.task_instances) {
          if (!completedTasks[taskInstance.task.id]) {
            setSelectedItem({ type: 'task', item: taskInstance });
            onItemSelect('task', taskInstance);
            return;
          }
        }
      }
    };

    if (!selectedItem) {
      selectFirstNonCompletedTask();
    }
  }, [step_instances, completedTasks, onItemSelect, selectedItem]);

  const handleItemSelect = (type, item, isDisabled) => {
    if (!isDisabled) {
      setSelectedItem({ type, item });
      onItemSelect(type, item);
    }
  };

  const isSelected = (type, item) => {
    return (
      selectedItem &&
      selectedItem.type === type &&
      selectedItem.item.id === item.id
    );
  };

  const selectedStyle = {
    backgroundColor: theme.palette.selection.main,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    fontWeight: '600',
  };

  const disabledStyle = {
    opacity: 0.5, // Make the disabled item look faded
    pointerEvents: 'none', // Prevent interaction with the item
  };

  return (
    <Box borderRight={1} borderColor="divider" width={240}>
      <List>
        {step_instances
          .sort((a, b) => a.step.order - b.step.order) // Sort steps by order
          .map((stepInstance) => {
            const isStepDisabled = stepInstance.step.dependencies && !areDependenciesCompleted(stepInstance.step.dependencies, titleToIdMap.steps, completedSteps);
            return (
              <div key={stepInstance.step.id}>
                <ListItem
                  button
                  onClick={() => handleItemSelect('step', stepInstance, isStepDisabled)}
                  style={isSelected('step', stepInstance) ? selectedStyle : { paddingLeft: 0, ...(isStepDisabled && disabledStyle) }}
                  disabled={isStepDisabled}
                >
                  {/* Add 1 to step order and make title bold */}
                  <ListItemText
                    primary={`${stepInstance.step.order + 1}. ${stepInstance.step.title}`}
                    primaryTypographyProps={{ fontWeight: 'medium' }}
                  />
                  <ListItemSecondaryAction>
                    {completedSteps[stepInstance.step.id] ? (
                      <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
                    ) : (
                      <RemoveCircleOutlineIcon sx={{ color: theme.palette.error.main }} />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>

                {stepInstance.task_instances
                  .sort((a, b) => a.task.order - b.task.order) // Sort tasks by order
                  .map((taskInstance) => {
                    const isTaskDisabled = taskInstance.task.dependencies && !areDependenciesCompleted(taskInstance.task.dependencies, titleToIdMap.tasks, completedTasks);
                    const taskDependencies = taskInstance.task.dependencies || [];

                    return (
                      <Tooltip
                        key={taskInstance.task.id}
                        title={
                          isTaskDisabled && taskDependencies.length > 0
                            ? `Complete these dependencies: ${taskDependencies.join(', ')}`
                            : ''
                        }
                        arrow
                      >
                        <span>
                          <ListItem
                            button
                            onClick={() => handleItemSelect('task', taskInstance, isTaskDisabled)}
                            style={isSelected('task', taskInstance) ? selectedStyle : { paddingLeft: 30, ...(isTaskDisabled && disabledStyle) }}
                            disabled={isTaskDisabled}
                          >
                            <ListItemText primary={`${taskInstance.task.order + 1}. ${taskInstance.task.title}`} />
                            <ListItemSecondaryAction>
                              {completedTasks[taskInstance.task.id] ? (
                                <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
                              ) : (
                                <RemoveCircleOutlineIcon sx={{ color: theme.palette.error.main }} />
                              )}
                            </ListItemSecondaryAction>
                          </ListItem>
                        </span>
                      </Tooltip>
                    );
                  })}
              </div>
            );
          })}
      </List>
    </Box>
  );
};

export default ProcessNavigation;
