import React, { useState, useEffect, useMemo } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Drawer from '@mui/material/Drawer'
import MRTcustomized from 'src/components/General/MRTcustomized'
import { Box, IconButton, MenuItem } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DynamicLevelSelector from 'src/components/General/DynamicLevelSelector'

const pluralize = (word) => {
  if (word?.toLowerCase().endsWith('s')) {
    return word // already plural
  }
  if (word?.toLowerCase().endsWith('y')) {
    // Remove 'y' and add 'ies'
    return `${word.slice(0, -1)}ies`
  }
  return `${word}s` // simple pluralization
}

// Utility function to parse a filter string and prefix its keys
const parseAndPrefixFilter = (filterString, prefix) => {
  return filterString?.split('&').reduce((result, pair) => {
    const [key, value] = pair.split('=')
    result[`${prefix}_${key}`] = value
    return result
  }, {})
}

function Assess ({ model, instance_id, model1, model2, assessments, filter1, filter2, refreshKey, filters  }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const pluralModel2Name = pluralize(model2?.model)

  const { getChildrenByContentType, complianceList: listResources } = useRequestCompliance({ endpoint: model, resourceLabel: model })
  const { getCompliance: getCatInstance, compliance: catInstance } =
    useRequestCompliance({ endpoint: 'categorization_instances' })
  const endpointUrl = model === 'riskassessmentinstance' ? `risk-assessment-instances/${instance_id}/update-criteria-instance` : `update_criteria`;
  const [internalrefreshKey, setInsternalRefreshKey] = useState(0)

  useEffect(() => {
    // Convert filter1 and filter2 strings to objects with prefixed keys
    const filter1Obj = parseAndPrefixFilter(filter1, 'ct1')
    const filter2Obj = parseAndPrefixFilter(filter2, 'ct2')

    // Combine both filter objects
    const filterdata = { ...filter1Obj, ...filter2Obj }
    getChildrenByContentType(instance_id, model1.id, model2?.id, filterdata)
  }, [instance_id, refreshKey, getChildrenByContentType, internalrefreshKey])

  console.log(filters)
  const handleAssessment = (row, assessment) => {
    console.log(model)
    getCatInstance(row.original[`${assessment}_id`])
    setIsDrawerOpen(true)
  }

  const updateParentState = (newValue) => {
    setInsternalRefreshKey(newValue)
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }
  console.log(model)

  // Determine if criteria_titles exist and should be included in the column order
  const dynamicColumnOrder = useMemo(() => {
    // Start with your base column order
    let baseColumnOrder = [
      'mrt-row-expand',
      'mrt-row-actions',
      'title',
      'description',
      'group',
    ];

    // Check if there are results and criteria_titles is available
    if (listResources.results.length > 0 && listResources.results[0].criteria_titles) {
      // Assuming criteria_titles is an array of title strings you want to add as columns
      // Concatenate them with the baseColumnOrder
      return baseColumnOrder.concat(listResources.results[0].criteria_titles);
      
    }
    // If no criteria_titles or no results, return the base column order
    return baseColumnOrder;
  }, [listResources.results]);
    return (
        <>                   <Drawer ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '40%',
            marginTop: '64px'
          }
        }}
    anchor="right" variant="temporary" open={isDrawerOpen} onClose={closeDrawer}>
<div>
<IconButton onClick={closeDrawer}>
  <ChevronRightIcon />
</IconButton>
</div>
<Box p={2} sx={{ flexGrow: 1 }}>
{catInstance
  ? <DynamicLevelSelector data={catInstance.categorization_model} initialValues={catInstance} setRefreshKey={updateParentState} endpoint={endpointUrl} />
  : null}
  </Box>
  </Drawer>
            <MRTcustomized
  key={`MRT-${instance_id}-${model1?.id}-${model2?.id}-${assessments.join('-')}`}
  results={listResources.results}
    enableRowSelection={false}
    enableRowActions={true}
    enableDetailPanel={true}
    nestedObjectName ={pluralModel2Name}
    warnOnEmptyFields={(listResources?.results?.length > 0) ? listResources.results[0].criteria_titles : null} // Adjust based on your needs
    columnOrder={dynamicColumnOrder}
    extraFields={assessments}
    renderRowActionMenuItems={({ row }) => [
      assessments && assessments.length > 0
        ? (
            assessments.map((assessment, index) => (
                <MenuItem key={`assessment-${index}`} onClick={() => handleAssessment(row, assessment)}>
                    Assess {assessment}
                </MenuItem>
            ))
          )
        : (
            <MenuItem key="default-menu-item" disabled>
                No Assessments Available
            </MenuItem>
          )
    ]}
/>

        </>
  )
}

export default Assess
