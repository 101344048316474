import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import 'typeface-poppins'

export const ThemeModeContext = React.createContext({
  toggleThemeMode: () => {}
})

const getDesignTokens = (mode) => {
  const palette = {
    header: {
      main: '#002F70'
    },
    primary: {
      main: '#003ABA'
    },
    secondary: {
      main: '#009BDF'
    },
    background: {
      default: mode === 'dark' ? '#050926' : '#FFFFFF',
      paper: mode === 'dark' ? '#101B32' : '#FFFFFF'
    },
    selection: {
      main: mode === 'dark' ? '#0A0F1A' : '#D9D9D9' // I've added a lighter gray for light mode for consistency
    },
    accent: {
      main: '#64ABFF'
    },
    text: {
      primary: mode === 'dark' ? '#FFFFFF' : '#050926'
    },
    error: { // Customized error color
      main: '#8B0000'
    },
    warning: { // Customized warning color
      main: '#FFA500'
    },
    info: { // Customized info color
      main: '#64ABFF'
    },
    success: { // Customized success color
      main: '#4CAF50'
    },
    riskLevels: {
      veryLow: '#66BB6A', // Green
      low: '#D4E157', // Light Green
      medium: '#FFD54F', // Muted Yellow
      high: '#FFA726', // Orange
      veryHigh: '#EF5350', // Red
    },
    mode
  }

  return {
    palette,
    typography: {
      fontFamily: 'Poppins, sans-serif',
      h1: {
        fontSize: '1.5rem', // was 2.5rem
        fontWeight: 600
      },
      h2: {
        fontSize: '1.25rem', // was 2rem
        fontWeight: 500
      },
      h3: {
        fontSize: '1rem',
        fontWeight: 500
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 400
      },
      body1: {
        fontSize: '0.875rem', // was 1rem
        fontWeight: 400
      },
      body2: {
        fontSize: '0.75rem',
        fontWeight: 400
      }
    },
    zIndex: {
      appBar: 1200, // Default zIndex for AppBar
      drawer: 1300, // Ensures Drawer is above AppBar and other elements
      modal: 1400, // For modal components
      tooltip: 1500 // For Tooltip components
      // Add other zIndex values as needed
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          color: '#004CBA' // Setting the default color for all SVG icons
        }
      },
      MuiMenu: {
        paper: {
          backgroundColor: mode === 'dark' ? '#101B32' : '#FFFFFF',
          boxShadow: 'none'
        }
      },
      MuiCard: {
        root: {
          backgroundColor: mode === 'dark' ? '#101B32' : '#FFFFFF'
        }
      }
    }
  }
}

export default function ThemeModeProvider ({ children }) {
  const [mode, setMode] = useState('light')

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode') || 'light'
    setMode(savedMode)
  }, [])

  useEffect(() => {
    localStorage.setItem('themeMode', mode)
  }, [mode])

  const themeMode = useMemo(() => {
    return {
      toggleThemeMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      }
    }
  }, [])

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  return (
    <ThemeModeContext.Provider value={themeMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeModeContext.Provider>
  )
}

ThemeModeProvider.propTypes = {
  children: PropTypes.node
}
