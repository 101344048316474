import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  CardActionArea,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Avatar,
  IconButton,
  Tooltip,
  DialogContent,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Divider,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SyncIcon from '@mui/icons-material/Sync';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import ButtonMenu from '../../components/buttonMenu';
import useRequestCompliance from 'src/hooks/useRequestCompliance';


const providerLogos = {
  github: 'https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png', // Official GitHub logo
  kubernetes: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Kubernetes_logo_without_workmark.svg', // Official Kubernetes logo
  dockerhub: 'https://www.docker.com/wp-content/uploads/2022/03/vertical-logo-monochromatic.png', // Official Docker logo
  aws: 'https://a0.awsstatic.com/libra-css/images/logos/aws_logo_smile_1200x630.png' // Official AWS logo
};

export default function IntegrationsView() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [providerFilter, setProviderFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const { getComplianceList: getIntegrations, complianceList, deleteResource, actionResource } =
    useRequestCompliance({ endpoint: 'integrations', resourceLabel: 'Integration' })

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  const handleConfirmDelete = (id) => {
    setIdToDelete(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    setDeleteOpen(false);
    deleteResource(idToDelete)
  };

  const handleClickOpen = (id) => {
    if (id) {
      navigate(`/manage/integrations/edit/${id}`);
    } else {
      navigate('/manage/integrations/add');
    }
  };

  const handleDetail = (id) => {
    navigate(`/manage/integrations/detail/${id}`);
  };

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleProviderFilterChange = (event) => {
    setProviderFilter(event.target.value);
  };

  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const getStatusChipColor = (status) => {
    switch (status) {
      case 'active':
        return theme.palette.success.main;
      case 'failed':
        return theme.palette.error.main;
      default:
        return theme.palette.text.primary;
    }
  };

  const filteredIntegrations = complianceList.results?.filter(integration => {
    return (
      (integration.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      integration.description.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (statusFilter === '' || integration.status === statusFilter) &&
      (providerFilter === '' || integration.provider === providerFilter) &&
      (categoryFilter.length === 0 || categoryFilter.every(category => integration.categories.includes(category)))
    );
  });

  const cardList = filteredIntegrations?.map(integration => (
    <Grid item xs={12} sm={6} md={4} key={integration.id}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: 2,
          boxShadow: 3
        }}
      >
        <CardActionArea onClick={() => handleDetail(integration.id)}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <Avatar
              src={providerLogos[integration.provider] || '/static/images/default-logo.png'}
              alt="Logo"
              sx={{ width: 60, height: 60 }}
            />
            <Typography variant="h6" component="div" fontWeight="bold" mt={2}>
              {integration.title}
            </Typography>
          </Box>
          <CardContent>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography sx={{ color: 'text.secondary' }}>
                {integration.description}
              </Typography>
            </Box>
            <Divider />
            <Box display="flex" alignItems="center" mb={1} mt={1}>
              <CalendarTodayIcon sx={{ mr: 1 }} />
              <Typography sx={{ color: 'text.secondary' }}>
                Created: {new Date(integration.created_at).toLocaleDateString()}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <SyncIcon sx={{ mr: 1 }} />
              <Typography sx={{ color: 'text.secondary' }}>
                Last Synced: {new Date(integration.updated_at).toLocaleDateString()}
              </Typography>
            </Box>
            <Divider />
            <Box display="flex" flexWrap="wrap" mt={1} mb={1}>
              {integration.categories?.map(category => (
                <Chip key={category} label={category} sx={{ mr: 0.5, mb: 0.5 }} />
              ))}
            </Box>
            <Box display="flex" alignItems="center">
              {integration.status === 'syncing' ? (
                <CircularProgress size={12} sx={{ mr: 1 }} />
              ) : (
                <FiberManualRecordIcon sx={{ color: getStatusChipColor(integration.status), mr: 1 }} />
              )}
              <Typography sx={{ color: 'text.secondary' }}>
                {integration.status}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <ButtonMenu
                editClick={() => handleClickOpen(integration.id)}
                handleDetail={() => handleDetail(integration.id)}
                deleteClick={() => handleConfirmDelete(integration.id)}
                rescan={() => actionResource(integration.id,'','force-rescan/') }
                id={integration.id}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <div>
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>
          Are you sure you want to delete this integration?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>
            Yes
          </Button>
          <Button onClick={handleDeleteClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={infoOpen} onClose={handleInfoClose}>
        <DialogTitle>Integrations</DialogTitle>
        <DialogContent>
          <Typography paragraph>
          The Integrations feature helps automate various processes by connecting with external tools and platforms. It streamlines tasks like managing resources, detecting vulnerabilities, ensuring compliance, and more. With this feature, you can easily monitor and interact with your integrations, improving overall system efficiency and security..
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container direction="column" spacing={2} mb={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" gutterBottom display="inline">
              Integrations
            </Typography>
            <Tooltip title="What is an integration?">
              <IconButton onClick={handleInfoOpen} size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => handleClickOpen(null)} startIcon={<AddIcon />}>
              Add Integration
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              type="search"
              id="search"
              label="Search"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth margin="dense" size="small" variant="filled">
                <InputLabel id="status-select-label">By status</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={statusFilter}
                  label="By status"
                  onChange={handleStatusFilterChange}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="failed">Failed</MenuItem>
                  <MenuItem value="syncing">Syncing</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth margin="dense" size="small" variant="filled">
  <InputLabel id="provider-select-label">By provider</InputLabel>
  <Select
    labelId="provider-select-label"
    id="provider-select"
    value={providerFilter}
    label="By provider"
    onChange={handleProviderFilterChange}
  >
    <MenuItem value="">All</MenuItem>
    <MenuItem value="github">GitHub</MenuItem>
    <MenuItem value="kubernetes">Kubernetes</MenuItem>
    <MenuItem value="dockerhub" disabled>
      DockerHub (Coming Soon)
    </MenuItem>
    <MenuItem value="aws" disabled>
      AWS (Coming Soon)
    </MenuItem>
  </Select>
</FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth margin="dense" size="small" variant="filled">
                <InputLabel id="category-select-label">By category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  multiple
                  value={categoryFilter}
                  label="By category"
                  onChange={handleCategoryFilterChange}
                  renderValue={(selected) => (
                    <Box display="flex" flexWrap="wrap">
                      {selected.map((value) => (
                        <Chip key={value} label={value} sx={{ mr: 0.5, mb: 0.5 }} />
                      ))}
                    </Box>
                  )}
                >
                  <MenuItem value="inventory">Inventory</MenuItem>
                  <MenuItem value="compliance">Compliance</MenuItem>
                  <MenuItem value="vulnerabilities">Vulnerabilities</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          {cardList}
        </Grid>
      </Grid>
    </div>
  );
}
