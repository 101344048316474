import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
  useMaterialReactTable,
  MaterialReactTable
} from 'material-react-table';
import {
  Stack,
  Tooltip,
  Box,
} from '@mui/material';
import KeyValueFilter from '../General/KeyValueFilter';

const MRTcustomizedOriginal = ({
  results = [],
  initialSelectedIds = [],
  enableRowActions,
  enableRowSelection = true,
  renderRowActions,
  onSelectedIdsChange,
  enableMultiRowSelection,
  firstColumnAccessor,
  columnOrder,
  filterBycolumnOrder = false,
  renderRowActionMenuItems,
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const isInitialSelectionSet = useRef(false);
  const [pinnedRows, setPinnedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // State for tag filtering
  const [tagFilters, setTagFilters] = useState([]);
  const [filterOperators, setFilterOperators] = useState([]); // AND/OR between filters

  // Set the initial row selection state and pinned rows
  useEffect(() => {
    if (!isInitialSelectionSet.current && results.length > 0 && initialSelectedIds) {
      let initialRowSelectionState = Array.isArray(initialSelectedIds)
        ? initialSelectedIds.reduce((acc, currId) => {
          acc[currId] = true;
          return acc;
        }, {})
        : { [initialSelectedIds]: true };

      setRowSelection(initialRowSelectionState);

      if (enableMultiRowSelection === false) {
        if (initialSelectedIds && initialSelectedIds.length !== 0) {
          setPinnedRows(Array.isArray(initialSelectedIds) ? initialSelectedIds : [initialSelectedIds]);
        }
      } else {
        setPinnedRows(initialSelectedIds);
      }

      isInitialSelectionSet.current = true;
    }
  }, [initialSelectedIds, results, enableMultiRowSelection]);

  const handleRowSelectionChange = (newRowSelection) => {
    setRowSelection(prev => {
      const updatedSelection = typeof newRowSelection === 'function' ? newRowSelection(prev) : newRowSelection;

      const isEmptySelection = Object.keys(updatedSelection).length === 0;
  
      const selectedIdsArray = isEmptySelection
        ? []
        : Object.entries(updatedSelection)
            .filter(([key, value]) => value)
            .map(([key]) => Number(key))
            .filter(id => !isNaN(id));

      setPinnedRows(selectedIdsArray);
  
      if (enableRowSelection && onSelectedIdsChange) {
        if (enableMultiRowSelection === false) {
          onSelectedIdsChange(selectedIdsArray[0] || null);
        } else {
          onSelectedIdsChange(selectedIdsArray);
        }
      }
  
      return isEmptySelection ? {} : updatedSelection;
    });
  };

  // Filtering logic for tag filters
  const filteredData = useMemo(() => {
    setIsLoading(true); // Start loading before processing data
    const activeFilters = tagFilters.filter(({ key, value }) => key && value);
    if (activeFilters.length === 0) {
      return results; // No filters applied, return all data
    }

    return results.filter((row) => {
      let result = null;

      activeFilters.forEach(({ key, value }, index) => {
        if (!key || !value) return;

        const matches = row.tags?.some((tag) => tag.key === key && tag.value.toLowerCase().includes(value.toLowerCase()));

        if (result === null) {
          result = matches;
        } else if (filterOperators[index - 1] === 'AND') {
          result = result && matches;
        } else if (filterOperators[index - 1] === 'OR') {
          result = result || matches;
        }
      });
      return result;
    });
  }, [results, tagFilters, filterOperators]);

  // Ensure pinned rows are valid after filtering
  useEffect(() => {
    const validPinnedRows = pinnedRows.filter((rowId) =>
      filteredData.some((row) => row.id === rowId)
    );
    setPinnedRows(validPinnedRows);
  }, [filteredData, pinnedRows]);

  const columns = useMemo(() => {
    if (!Array.isArray(results) || results.length === 0) return [];
    const sample = results[0];

    const columnList = Object.keys(sample).map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1),
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span style={{ display: 'inline-block', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        </Tooltip>
      )
    }));

    if (firstColumnAccessor) {
      const firstColumnIndex = columnList.findIndex(
        (column) => column.accessorKey === firstColumnAccessor
      );
      if (firstColumnIndex !== -1) {
        const firstColumn = columnList.splice(firstColumnIndex, 1);
        columnList.unshift(...firstColumn);
      }
    }

    const filteredColumns = columnList.filter((column) => {
      const value = sample[column.accessorKey];
      return !Array.isArray(value) && typeof value !== 'object';
    });

    // Add Tags Column
    filteredColumns.push({
      accessorKey: 'tags',
      header: 'Tags',
      enableColumnFilter: false, // Disable built-in filtering
      Cell: ({ cell }) => (
        <Box>
          {cell.getValue()?.map((tag, index) => (
            <Tooltip key={index} title={`${tag.key}: ${tag.value}`} arrow>
              <span style={{ marginRight: '8px', backgroundColor: '#f0f0f0', padding: '4px', borderRadius: '4px' }}>
                {tag.key}: {tag.value}
              </span>
            </Tooltip>
          ))}
        </Box>
      ),
    });

    return filteredColumns;
  }, [results, firstColumnAccessor]);

  const tableColumns = useMemo(() => {
    if (!Array.isArray(results) || results.length === 0) return [];

    if (columnOrder && filterBycolumnOrder && columnOrder.length > 0) {
      const columnKeysSet = new Set(columnOrder);

      const filteredColumns = columns.filter((column) =>
        columnKeysSet.has(column.accessorKey)
      );

      return filteredColumns;
    } else {
      setIsLoading(false); // Start loading before processing data
      return columns;
    }
  }, [results, columns, columnOrder, filterBycolumnOrder]);

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: filteredData,
    enablePagination: true,
    enableRowPinning: true,
    rowPinningDisplayMode: 'select-sticky',
    getRowId: (row, index) => row.id ?? `group-${index}`, // Provide a fallback ID for grouped rows
    enableGrouping: true,
    initialState: {
      columnOrder,
      columnVisibility: {
        id: false,
        created_by: false,
        created_at: false,
        updated_at: false
      },
      rowPinning: {
        top: pinnedRows,
      },
      pagination: {
        pageSize: 30,
      },
    },
    
    enableRowSelection,
    enableStickyHeader: true,
    enableFullScreenToggle: false,
    onRowSelectionChange: handleRowSelectionChange,
    state: {
      rowSelection,
      rowPinning: {
        top: pinnedRows,
      },
      isLoading,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '2000px'
      }
    },
    defaultColumn: {
      maxSize: 400,
      minSize: 20,
      size: 400
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowActions,
    renderRowActions,
    enableMultiRowSelection,
    renderRowActionMenuItems,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <KeyValueFilter
          tagFilters={tagFilters}
          filterOperators={filterOperators}
          uniqueTagKeys={results.reduce((acc, row) => {
            row.tags?.forEach((tag) => {
              if (!acc.includes(tag.key)) acc.push(tag.key);
            });
            return acc;
          }, [])}
          handleTagFilterChange={(index, field, value) => {
            const newFilters = tagFilters.map((filter, i) =>
              i === index ? { ...filter, [field]: value } : filter
            );
            setTagFilters(newFilters);
          }}
          handleOperatorChange={(index, value) => {
            const newOperators = [...filterOperators];
            newOperators[index] = value;
            setFilterOperators(newOperators);
          }}
          handleAddTagFilter={() => {
            setTagFilters([...tagFilters, { key: '', value: '' }]);
            setFilterOperators([...filterOperators, 'AND']);
          }}
          handleRemoveTagFilter={(index) => {
            const newFilters = tagFilters.filter((_, i) => i !== index);
            const newOperators = filterOperators.filter((_, i) => i !== index);
            setTagFilters(newFilters);
            setFilterOperators(newOperators);
          }}
        />
      </Box>
    )
  });

  return (
    <Stack sx={{ m: '2rem 0' }}>
      <MaterialReactTable table={table} />
    </Stack>
  );
};

export default MRTcustomizedOriginal;
