import React, { useState, useMemo } from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import 'src/static/css/EunoiaFlow.css'; // Import the CSS file
import KeyValueFilter from './KeyValueFilter'; // Import the reusable filter component

const MRTNested = ({ data, renderActionCell, renderTopToolbarCustomActions, onDeleteStandard }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [tagFilters, setTagFilters] = useState([]); // State for multiple key-value tag filters
  const [filterOperators, setFilterOperators] = useState([]); // AND/OR between filters

  const handleDeleteConfirm = () => {
    setOpenDialog(false);
    onDeleteStandard(); // Call the prop function to handle deletion
  };

  const handleAddTagFilter = () => {
    setTagFilters([...tagFilters, { key: '', value: '' }]);
    setFilterOperators([...filterOperators, 'AND']);
  };

  const handleRemoveTagFilter = (index) => {
    const newFilters = tagFilters.filter((_, i) => i !== index);
    const newOperators = filterOperators.filter((_, i) => i !== index - 1);
    setTagFilters(newFilters);
    setFilterOperators(newOperators);
  };

  const handleTagFilterChange = (index, field, value) => {
    const newFilters = tagFilters.map((filter, i) =>
      i === index ? { ...filter, [field]: value } : filter
    );
    setTagFilters(newFilters);
  };

  const handleOperatorChange = (index, value) => {
    const newOperators = [...filterOperators];
    newOperators[index] = value;
    setFilterOperators(newOperators);
  };

  const columns = useMemo(() => [
    {
      id: 'actions',
      header: 'Actions',
      Cell: renderActionCell,
    },
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ row, cell }) => (
        <Box className={`tree-branch ${row.depth > 0 ? 'nested' : ''}`} sx={{ display: 'flex', alignItems: 'center', pl: row.depth * 2 }}>
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            maxWidth: '300px' // Adjust the max width as needed
          }}>
            {cell.getValue()}
          </span>
        </Tooltip>
      ),
    },
    {
      accessorKey: 'tags',
      header: 'Tags',
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <Box>
          {cell.getValue().map((tag, index) => (
            <Tooltip key={index} title={`${tag.key}: ${tag.value}`} arrow>
              <span style={{ marginRight: '8px', backgroundColor: '#f0f0f0', padding: '4px', borderRadius: '4px' }}>
                {tag.key}: {tag.value}
              </span>
            </Tooltip>
          ))}
        </Box>
      ),
    },
  ], [renderActionCell]);

  const uniqueTagKeys = useMemo(() => {
    const keys = new Set();
    data.forEach((row) => {
      row.tags.forEach((tag) => {
        keys.add(tag.key);
      });
    });
    return Array.from(keys);
  }, [data]);

  const filteredData = useMemo(() => {
    const activeFilters = tagFilters.filter(({ key, value }) => key && value);
    if (activeFilters.length === 0) {
      return data;
    }

    return data.filter((row) => {
      let result = null;

      activeFilters.forEach(({ key, value }, index) => {
        if (!key || !value) return;

        const matches = row.tags.some((tag) => tag.key === key && tag.value.toLowerCase().includes(value.toLowerCase()));

        if (result === null) {
          result = matches;
        } else if (filterOperators[index - 1] === 'AND') {
          result = result && matches;
        } else if (filterOperators[index - 1] === 'OR') {
          result = result || matches;
        }
      });

      return result;
    });
  }, [data, tagFilters, filterOperators]);

  return (
    <Box sx={{ maxWidth: '100%' }}>
      {/* MaterialReactTable with custom toolbar actions */}
      <MaterialReactTable
        columns={columns}
        data={filteredData}
        enableExpanding={true}
        getSubRows={(row) => row.subControls || []}
        renderTopToolbarCustomActions={() => (
          <Box>
            {/* First Row: Render Top Toolbar Actions (Edit and Delete) */}
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              {renderTopToolbarCustomActions && renderTopToolbarCustomActions()}
            </Box>

            {/* Second Row: Add KeyValueFilter below the actions */}
            <Box sx={{ mt: 2 }}> {/* mt: 2 adds some margin to separate from the toolbar actions */}
              <KeyValueFilter
                tagFilters={tagFilters}
                filterOperators={filterOperators}
                uniqueTagKeys={uniqueTagKeys}
                handleTagFilterChange={handleTagFilterChange}
                handleOperatorChange={handleOperatorChange}
                handleAddTagFilter={handleAddTagFilter}
                handleRemoveTagFilter={handleRemoveTagFilter}
              />
            </Box>
          </Box>
        )}
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this standard? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MRTNested;
