import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Typography,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import DynamicSelectWidget from 'src/components/General/DynamicSelectWidget';

const providerOptions = [
  { value: 'github', label: 'GitHub' },
  { value: 'kubernetes', label: 'Kubernetes' },
  { value: 'dockerhub', label: 'DockerHub (Coming Soon)', isDisabled: true },
  { value: 'aws', label: 'AWS (Coming Soon)', isDisabled: true },
];


const steps = [
  {
    label: 'Choose Provider',
    description: 'Select the provider you want to integrate with.',
  },
  {
    label: 'Create Personal Access Token',
    description: 'Instructions to create a personal access token for the selected provider.',
  },
  {
    label: 'Fill Integration Information',
    description: 'Enter the title, description, and information system to which the supporting assets and primary assets will belong.',
  },
  {
    label: 'Provider and Common Details',
    description: 'Enter the provider-specific details and common details required for the integration.',
  },
];

export default function IntegrationsMultiStepForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    provider: '',
    access_token: '',
    github_organization: '',
    branch: '',
    tag: '',
    commit: '',
    selectedOption: '',
    title: '',
    description: '',
    kubernetes_cluster_url: '',
    kubernetes_cluster_token: '',
    kubernetes_certificate_authority_data: '',
    kubernetes_service_account: '',
    dockerhub_username: '',
    dockerhub_password: '',
    aws_access_key: '',
    aws_secret_key: '',
    information_system: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { getCompliance: getIntegration, compliance: integration, updateCompliance: updateIntegration, addCompliance: addIntegration } =
    useRequestCompliance({ endpoint: 'integrations', resourceLabel: 'Integration' });

  useEffect(() => {
    if (id) {
      getIntegration(id);
    }
  }, [id, getIntegration]);

  useEffect(() => {
    if (integration) {
      const { branch, tag, commit, information_system } = integration;
      let selectedOption = '';
      if (branch) selectedOption = 'branch';
      if (tag) selectedOption = 'tag';
      if (commit) selectedOption = 'commit';
      setFormValues({ ...integration, selectedOption, information_system });
    }
  }, [integration]);

  const handleNext = () => {
    if (!validateStep()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepClick = (step) => {
    if (step < activeStep && validateStep(step)) {
      setActiveStep(step);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleProviderChange = (selectedOption) => {
    setFormValues({ ...formValues, provider: selectedOption.value });
  };

  const handleOptionChange = (e) => {
    setFormValues({
      ...formValues,
      selectedOption: e.target.value,
      branch: '',
      tag: '',
      commit: '',
      [e.target.value]: formValues[e.target.value] || '',
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInformationSystemChange = (selectedIds) => {
    setFormValues({ ...formValues, information_system: selectedIds });
  };

  const handleSubmit = async () => {
    if (!validateStep()) {
      return;
    }

    try {
      if (id) {
        await updateIntegration(id, formValues);
      } else {
        await addIntegration(formValues);
      }
      navigate('/manage/integrations'); // Redirect to the integrations list view after submission
    } catch (error) {
      console.error('Failed to submit:', error);
    }
  };

  const validateStep = (step = activeStep) => {
    const { title, description, provider, access_token, github_organization, selectedOption, branch, tag, commit } = formValues;

    if (step === 0 && !provider) {
      setErrorMessage('Please select a provider.');
      return false;
    }

    if (step === 2) {
      if (!title || !description || !formValues.information_system) {
        setErrorMessage('Please fill out all required fields.');
        return false;
      }
    }

    if (step === 3) {
      if (provider === 'github' && (!title || !description || !access_token || !github_organization)) {
        setErrorMessage('Please fill out all required fields.');
        return false;
      }

      if (provider === 'github' && selectedOption === 'branch' && !branch) {
        setErrorMessage('Branch cannot be empty.');
        return false;
      } else if (provider === 'github' && selectedOption === 'tag' && !tag) {
        setErrorMessage('Tag cannot be empty.');
        return false;
      } else if (provider === 'github' && selectedOption === 'commit' && !commit) {
        setErrorMessage('Commit cannot be empty.');
        return false;
      }

      if (provider === 'kubernetes' && (!formValues.kubernetes_cluster_url || !formValues.kubernetes_cluster_token || !formValues.kubernetes_certificate_authority_data || !formValues.kubernetes_service_account)) {
        setErrorMessage('Please fill out all required Kubernetes fields.');
        return false;
      }
    }

    setErrorMessage('');
    return true;
  };

  const renderProviderInstructions = () => {
    if (formValues.provider === 'github') {
      return (
        <Box>
          <Typography variant="h6" gutterBottom>
            Creating a Fine-Grained Personal Access Token for GitHub
          </Typography>
          <Typography variant="body2" gutterBottom>
            1. Verify your email address, if it hasn't been verified yet.
          </Typography>
          <Typography variant="body2" gutterBottom>
            2. In the upper-right corner of any page on GitHub, click your profile photo, then click Settings.
          </Typography>
          <Typography variant="body2" gutterBottom>
            3. In the left sidebar, click Developer settings.
          </Typography>
          <Typography variant="body2" gutterBottom>
            4. In the left sidebar, under Personal access tokens, click Fine-grained tokens.
          </Typography>
          <Typography variant="body2" gutterBottom>
            5. Click Generate new token.
          </Typography>
          <Typography variant="body2" gutterBottom>
            6. Under Token name, enter a name for the token.
          </Typography>
          <Typography variant="body2" gutterBottom>
            7. Under Expiration, select an expiration for the token.
          </Typography>
          <Typography variant="body2" gutterBottom>
            8. Optionally, under Description, add a note to describe the purpose of the token.
          </Typography>
          <Typography variant="body2" gutterBottom>
            9. Under Resource owner, select a resource owner.
          </Typography>
          <Typography variant="body2" gutterBottom>
            10. Under Repository access, select which repositories you want the token to access.
          </Typography>
          <Typography variant="body2" gutterBottom>
            11. Under Permissions, select Read access to code, secrets and metadata.
          </Typography>
          <Typography variant="body2" gutterBottom>
            12. Click Generate token.
          </Typography>
        </Box>
      );
    } else if (formValues.provider === 'kubernetes') {
      return (
        <Box>
          <Typography variant="h6" gutterBottom>
            Creating a Service Account and Token for Kubernetes
          </Typography>
          <Typography variant="body2" gutterBottom>
            1. Create a Service Account in your Kubernetes cluster.
          </Typography>
          <Typography variant="body2" gutterBottom>
            2. Bind the Service Account to a ClusterRole with the necessary permissions.
          </Typography>
          <Typography variant="body2" gutterBottom>
            3. Generate a token for the Service Account.
          </Typography>
          <Typography variant="body2" gutterBottom>
            4. Use the token to authenticate with the Kubernetes API.
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <FormControl fullWidth margin="normal">
          <Select
            id="provider"
            name="provider"
            value={providerOptions.find(option => option.value === formValues.provider)}
            onChange={handleProviderChange}
            options={providerOptions}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
              option: (provided, state) => ({
                ...provided,
                color: state.isDisabled ? '#aaa' : '#000',
                cursor: state.isDisabled ? 'not-allowed' : 'pointer',
              }),
            }}
            required
          />

          </FormControl>
        );
      case 1:
        return renderProviderInstructions();
      case 2:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Common Details
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Title"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              name="description"
              value={formValues.description}
              onChange={handleInputChange}
              required
            />
            <Typography variant="h6" gutterBottom>
              Related Information System
            </Typography>
            <Typography variant="body1" gutterBottom>
              Information system to which the supporting assets and primary assets will belong.
            </Typography>
            <FormControl fullWidth margin="normal">
              <DynamicSelectWidget
                id="information-system"
                label="Information System"
                schema={{ endpoint: 'information_systems', relationType: 'ManyToOne' }}
                value={formValues.information_system}
                onChange={handleInformationSystemChange}
              />
            </FormControl>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Provider Details
            </Typography>
            {formValues.provider === 'github' && (
              <>
                <Typography variant="h6" gutterBottom>
                  GitHub Details
                </Typography>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-access-token">Access Token</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-access-token"
                    name="access_token"
                    type={showPassword ? 'text' : 'password'}
                    value={formValues.access_token}
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle access token visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Access Token"
                    required
                  />
                </FormControl>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Organization"
                  name="github_organization"
                  value={formValues.github_organization}
                  onChange={handleInputChange}
                  required
                />
                <FormControl component="fieldset" margin="normal" required>
                  <FormLabel component="legend">Reference</FormLabel>
                  <RadioGroup
                    aria-label="reference"
                    name="selectedOption"
                    value={formValues.selectedOption}
                    onChange={handleOptionChange}
                  >
                    <FormControlLabel value="branch" control={<Radio />} label="Branch" />
                    <FormControlLabel value="tag" control={<Radio />} label="Tag" />
                    <FormControlLabel value="commit" control={<Radio />} label="Commit" />
                  </RadioGroup>
                </FormControl>
                {formValues.selectedOption && (
                  <TextField
                    fullWidth
                    margin="normal"
                    label={formValues.selectedOption.charAt(0).toUpperCase() + formValues.selectedOption.slice(1)}
                    name={formValues.selectedOption}
                    value={formValues[formValues.selectedOption] || ''}
                    onChange={handleInputChange}
                    required
                  />
                )}
              </>
            )}
            {formValues.provider === 'kubernetes' && (
              <>
                <Typography variant="h6" gutterBottom>
                  Kubernetes Details
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Kubernetes Cluster URL"
                  name="kubernetes_cluster_url"
                  value={formValues.kubernetes_cluster_url}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Kubernetes Cluster Token"
                  name="kubernetes_cluster_token"
                  value={formValues.kubernetes_cluster_token}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Kubernetes Certificate Authority Data"
                  name="kubernetes_certificate_authority_data"
                  value={formValues.kubernetes_certificate_authority_data}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Kubernetes Service Account"
                  name="kubernetes_service_account"
                  value={formValues.kubernetes_service_account}
                  onChange={handleInputChange}
                  required
                />
              </>
            )}
            {formValues.provider === 'dockerhub' && (
              <>
                <Typography variant="h6" gutterBottom>
                  DockerHub Details
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  label="DockerHub Username"
                  name="dockerhub_username"
                  value={formValues.dockerhub_username}
                  onChange={handleInputChange}
                  required
                />
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-dockerhub-password">DockerHub Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-dockerhub-password"
                    name="dockerhub_password"
                    type={showPassword ? 'text' : 'password'}
                    value={formValues.dockerhub_password}
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle dockerhub password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="DockerHub Password"
                    required
                  />
                </FormControl>
              </>
            )}
            {formValues.provider === 'aws' && (
              <>
                <Typography variant="h6" gutterBottom>
                  AWS Details
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  label="AWS Access Key"
                  name="aws_access_key"
                  value={formValues.aws_access_key}
                  onChange={handleInputChange}
                  required
                />
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-aws-secret-key">AWS Secret Key</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-aws-secret-key"
                    name="aws_secret_key"
                    type={showPassword ? 'text' : 'password'}
                    value={formValues.aws_secret_key}
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle aws secret key visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="AWS Secret Key"
                    required
                  />
                </FormControl>
              </>
            )}
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <List component="nav">
          {steps.map((step, index) => (
            <ListItem
              button
              key={step.label}
              selected={activeStep === index}
              onClick={() => handleStepClick(index)}
            >
              <ListItemText primary={step.label} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={9}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">{steps[activeStep].label}</Typography>
          <Typography variant="body1">{steps[activeStep].description}</Typography>
          <Divider sx={{ my: 2 }} />
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          {renderStepContent(activeStep)}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3, mt: 2 }}>
            <Typography>All steps completed - you're finished</Typography>
            <Button onClick={() => setActiveStep(0)}>Reset</Button>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
